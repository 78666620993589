import React, { useCallback } from 'react';
import { Button, Checkbox } from '@cognite/cogs.js';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StartPageCard from '../StartPageCard';
import { CardLine } from '../../elements';
import { Project } from '../../../../types';
import { useStartPageActions } from '../../hooks/StartPageActions';
import { RootState } from '../../../../redux/store';
import { AuthState } from '../../../../redux/reducers/auth';
import {
  Filter,
  FilterActions,
  FilterBar,
  FilterInputs,
} from '../../../../containers/FilterBar/FilterBar';

type PlanningViewProps = {
  setClickedProcedure: Function;
  setDashboardModalVisible: Function;
  setIsOnlyMyProjects: Function;
  projects: Project[];
  isOnlyMyProjects: boolean;
  selectedRootAsset: string;
};

const PlanningView = ({
  setClickedProcedure,
  setDashboardModalVisible,
  setIsOnlyMyProjects,
  projects,
  isOnlyMyProjects,
}: PlanningViewProps) => {
  const history = useHistory();

  const { user: userEmail } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const newSearch = useCallback(() => {
    history.push(`/search`);
    // eslint-disable-next-line
  }, []);

  const { filterProjectsAndGroupByStatus } = useStartPageActions();

  const dashboardVM = filterProjectsAndGroupByStatus(
    projects,
    isOnlyMyProjects,
    userEmail
  );
  // eslint-disable-next-line no-console
  console.log('test pipeline');
  return (
    <>
      <FilterBar>
        <FilterInputs>
          <Filter style={{ minWidth: '200px', maxWidth: '200px' }}>
            <Checkbox
              name="onlyMyProcedures"
              data-testid="archive-search-toggle"
              onChange={(value) => setIsOnlyMyProjects(value)}
            >
              Show only my procedures
            </Checkbox>
          </Filter>
        </FilterInputs>
        <FilterActions>
          <Button
            onClick={newSearch}
            type="primary"
            data-testid="create-project"
          >
            New procedure
          </Button>
        </FilterActions>
      </FilterBar>
      <CardLine>
        <StartPageCard
          title="IN PLANNING"
          textColor="#000000"
          borderColor="#FFDC7F"
          backgroundColor="#FFDC7F"
          projects={dashboardVM.projectsUnderCompilation}
          selectProject={setClickedProcedure}
          setModalVisible={setDashboardModalVisible}
          data-testid="under-compilation"
        />
        <StartPageCard
          title="READY FOR REVIEW"
          textColor="#00665C"
          borderColor="#6ED8BE"
          backgroundColor="#FFFFFF"
          projects={dashboardVM.projectsUnderReviewReady}
          selectProject={setClickedProcedure}
          setModalVisible={setDashboardModalVisible}
          data-testid="review-ready"
        />
        <StartPageCard
          title="IN REVIEW"
          textColor="#333333"
          borderColor="#FFDC7F"
          backgroundColor="#FFFFFF"
          projects={dashboardVM.projectsUnderReview}
          selectProject={setClickedProcedure}
          setModalVisible={setDashboardModalVisible}
          data-testid="under-review"
        />
        <StartPageCard
          title="READY FOR EXECUTION"
          textColor="#00665C"
          borderColor="#C8F4E7"
          backgroundColor="#C8F4E7"
          projects={dashboardVM.projectsUnderExecutionReady}
          selectProject={setClickedProcedure}
          setModalVisible={setDashboardModalVisible}
          data-testid="execution-ready"
        />
        <StartPageCard
          title="IN EXECUTION"
          textColor="#D51A46"
          borderColor="#D51A46"
          backgroundColor="#FFFFFF"
          projects={dashboardVM.projectsUnderExecution}
          selectProject={setClickedProcedure}
          setModalVisible={setDashboardModalVisible}
          data-testid="in-progress"
        />
      </CardLine>
    </>
  );
};

export default PlanningView;
