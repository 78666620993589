import React from 'react';
import Modal from 'react-modal';
import { Button, ButtonType, Icon, Title } from '@cognite/cogs.js';

// taken from cogs and modified

type DahboardModalProps = {
  visible: boolean;
  setVisible: Function;
  title?: string;
  okText?: string;
  okButtonType?: ButtonType;
  cancelText?: string;
  okHandler: Function;
  isOkDisabled?: boolean;
  children?: React.ReactNode;
  width?: string;
  extraActionButtons?: React.ReactNode;
};

const DashboardModal = ({
  visible,
  setVisible,
  title = '',
  okText = 'OK',
  okButtonType = 'primary',
  cancelText = 'Cancel',
  okHandler,
  isOkDisabled = false,
  children,
  width = '550px',
  extraActionButtons,
}: DahboardModalProps) => {
  const FooterDefault = (
    <div className="cogs-modal-footer-buttons">
      {extraActionButtons}

      <Button onClick={() => setVisible(false)}>{cancelText}</Button>
      <Button
        data-testid="ok-button-modal"
        type={okButtonType}
        disabled={isOkDisabled}
        onClick={() => okHandler()}
      >
        {okText}
      </Button>
    </div>
  );

  return (
    <Modal
      closeTimeoutMS={50}
      className="cogs-modal"
      overlayClassName="cogs-modal-overlay"
      isOpen={!!visible}
      ariaHideApp={false}
      onRequestClose={() => setVisible(false)}
      style={{
        content: {
          width,
          outline: 'none',
          top: '50%',
          transform: 'translateY(-50%)',
          margin: '0 auto',
        },
        overlay: {
          transition: 'opacity 50ms ease-in-out',
        },
      }}
    >
      <Button
        key="modal-close"
        unstyled
        className="cogs-modal-close"
        onClick={() => setVisible(false)}
      >
        <Icon type="Close" />
      </Button>
      <div style={{ margin: '77px 70px 30px' }}>
        <Title level={5}>{title}</Title>
      </div>
      <div style={{ margin: '0px 70px 30px', fontSize: '14px' }}>
        {children}
      </div>
      <div key="modal-footer" className="cogs-modal-footer">
        {FooterDefault}
      </div>
    </Modal>
  );
};

export default DashboardModal;
