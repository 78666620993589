import React, { useCallback, useEffect, useState } from 'react';
import projectSlice from 'redux/reducers/project';
import { PassValve, Project, WorkStep } from 'types';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { useSave } from 'hooks/useSave';
import { toast } from '@cognite/cogs.js';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { useProcedureFilesLoad } from 'hooks/useProcedureFilesLoad';
import { ExecutionList } from './ExecutionList';
import { ExecutionMenuBar } from './ProjectInfoModal/ExecutionMenuBar';
import { ProjectInfoModal } from './ProjectInfoModal';

export interface ExecuteProcedureProps {
  project: Project;
  passValves: PassValve[];
  viewOnly: boolean;
}

export const ExecuteProcedure = ({
  project,
  passValves,
  viewOnly,
}: ExecuteProcedureProps) => {
  const {
    active,
    phases,
    setWorkstepAsDone,
    setWorkstepAsChecked,
    undoWorkstep,
    setWorkstepStoppedExecution,
    approvalPhase,
    undoCheckedWorkstep,
    unApprovalPhase,
  } = useWorkSteps();

  const {
    saveProjectChanges,
    stopProjectExecution,
    completeProjectExecution,
  } = useSave();
  const dispatch = useDispatch();

  const metrics = useMetrics(METRICS_AREA.PROJECT_SAVE);

  const [currentPhaseId, setCurrentPhaseId] = useState('');
  const [projectInfoModalVisible, setProjectInfoModalVisible] = useState(false);
  const [startSaving, setStartSaving] = useState(false);
  const [updateProjectStatus, setUpdateProjectStatus] = useState(false);
  const history = useHistory();
  const [isInitialized, setIsInitialized] = useState(false);
  const { clearConnectedFiles } = useProcedureFilesLoad();

  const showErrorMessage = useCallback(() => {
    setStartSaving(false);
    setUpdateProjectStatus(false);
    toast.error(
      <div>
        <h3>Oops</h3> We’re sorry, we weren’t able to do that. Try again.
      </div>
    );
    // eslint-disable-next-line
  }, []);

  const onStopExecution = useCallback(() => {
    setStartSaving(true);
    setUpdateProjectStatus(true);
    let phaseId = currentPhaseId;
    if (!phaseId) {
      phases.forEach((phase) => {
        if (
          // eslint-disable-next-line
          phase.workSteps.findIndex(
            (workStep) => workStep.id === active.workStep?.id
          ) !== -1
        ) {
          phaseId = phase.id;
          setCurrentPhaseId(phaseId);
        }
      });
    }
    setWorkstepStoppedExecution(active.workStep as WorkStep, phaseId);
    // eslint-disable-next-line
  }, [active.workStep, currentPhaseId]);

  const onStopProjectExecution = useCallback(() => {
    setUpdateProjectStatus(false);
    stopProjectExecution().then(
      () => {
        toast.error(
          <div data-testid="execute-procedure-stopped-success-msg">
            Execution of {project.name} aborted.
          </div>
        );
        history.push(`/`);
        dispatch(projectSlice.actions.clearProject());
        metrics.track(METRICS.STOP_EXECUTION, {
          site: project.rootAsset,
          status: 'execution',
        });
      },
      () => showErrorMessage()
    );
    // eslint-disable-next-line
  }, [active.workStep, phases, currentPhaseId]);

  const onCompleteProcedure = useCallback(() => {
    completeProjectExecution().then(
      () => {
        toast.success(
          <div data-testid="execute-procedure-completed-success-msg">
            {project.name} completed.
          </div>
        );
        history.push(`/`);
        dispatch(projectSlice.actions.clearProject());
      },
      () => showErrorMessage()
    );
    // eslint-disable-next-line
  }, [active.workStep, phases, currentPhaseId]);

  const onUndoStep = useCallback(
    (phaseId: string) => {
      setStartSaving(true);
      undoWorkstep(active.workStep as WorkStep, phaseId);
    },
    [active.workStep, undoWorkstep]
  );

  const onUncheckStep = useCallback(
    (phaseId: string) => {
      setStartSaving(true);
      undoCheckedWorkstep(active.workStep as WorkStep, phaseId);
    },
    [active.workStep, undoCheckedWorkstep]
  );

  const onSetWorkstepAsChecked = useCallback(
    (phaseId: string, email: string) => {
      setCurrentPhaseId(phaseId);
      setStartSaving(true);
      setWorkstepAsChecked(active.workStep as WorkStep, phaseId, email);
    },
    [active.workStep, setWorkstepAsChecked]
  );
  const onSetWorkstepAsDone = useCallback(
    (phaseId: string, email: string) => {
      setCurrentPhaseId(phaseId);
      setStartSaving(true);
      setWorkstepAsDone(active.workStep as WorkStep, phaseId, email);
    },
    [active.workStep, setWorkstepAsDone]
  );

  const onSetPhaseApproval = useCallback(
    (phaseId: string, email: string) => {
      setCurrentPhaseId(phaseId);
      setStartSaving(true);
      approvalPhase(phaseId, email);
    },
    [approvalPhase]
  );

  const onSetPhaseUnApproval = useCallback(
    (phaseId: string, email: string) => {
      setCurrentPhaseId(phaseId);
      setStartSaving(true);
      unApprovalPhase(phaseId, email);
    },
    [unApprovalPhase]
  );

  // Init component hook, use it if you want to
  // run something, before anything is shown
  useEffect(() => {
    if (!isInitialized) {
      clearConnectedFiles();
      setIsInitialized(true);
    }
    // eslint-disable-next-line
  }, [isInitialized]);

  useEffect(() => {
    // Save state of the project/phases if any change in redux occur
    if (!viewOnly) {
      saveProjectChanges({ action: 'start execution' }).then(
        () => {
          setStartSaving(false);
          if (updateProjectStatus) {
            onStopProjectExecution();
          }
        },
        () => {
          onUndoStep(currentPhaseId);
          showErrorMessage();
        }
      );
    }
    // eslint-disable-next-line
  }, [phases]);

  return (
    <>
      <ExecutionMenuBar
        viewOnly={viewOnly}
        onMenuBarClick={() => setProjectInfoModalVisible(true)}
      />
      <ProjectInfoModal
        project={project}
        passValves={passValves}
        visible={projectInfoModalVisible}
        setVisible={setProjectInfoModalVisible}
        stopExecution={onStopExecution}
        viewOnly={viewOnly}
      />
      <ExecutionList
        setPhaseUnApproved={onSetPhaseUnApproval}
        projectId={project.id || ''}
        projectStatus={project.status}
        executorsList={project.executorsList || []}
        supervisorsList={project.supervisorsList || []}
        phases={phases}
        buttonsDisabled={startSaving}
        uncheckWorkstep={onUncheckStep}
        undoWorkstep={onUndoStep}
        setWorkstepAsChecked={onSetWorkstepAsChecked}
        setWorkstepAsDone={onSetWorkstepAsDone}
        setPhaseApproved={onSetPhaseApproval}
        completeProcedure={onCompleteProcedure}
        viewOnly={viewOnly}
      />
    </>
  );
};
