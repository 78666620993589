import { CogniteAnnotation } from '@cognite/annotations-experimental';
import isMatch from 'lodash/isMatch';
import { uniqBy } from 'utils/collection';

export class AnnotationDuplicatesService {
  filterOutDuplicatePipelineAnnotations(fileAnnotations: CogniteAnnotation[]) {
    const unique = fileAnnotations.reduce(
      (
        newArray: CogniteAnnotation[],
        current: CogniteAnnotation,
        _index: number,
        array: CogniteAnnotation[]
      ) => {
        const duplicates = array
          .filter(
            (it) =>
              isMatch(it.region, current.region) &&
              it.data.sourceType === 'user'
          )
          .sort((a, b) => {
            // @ts-ignore
            return b.lastUpdatedTime - a.lastUpdatedTime;
          });
        if (duplicates.length > 0) {
          if (
            newArray.filter((it) => it.id === duplicates[0].id).length === 0
          ) {
            newArray.push(duplicates[0]);
          }
        } else {
          newArray.push(current);
        }
        return newArray;
      },
      []
    );
    return unique;
  }

  filterOutDuplicateProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    const diff = fileAnnotations.filter(
      (fileAnnotation) =>
        !projectAnnotations.find((projectAnnotation: any) => {
          return isMatch(projectAnnotation.region, fileAnnotation.region);
        })
    );
    return projectAnnotations.concat(diff);
  }

  mergeWithProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    const uniqueProjectAnnotations = uniqBy(projectAnnotations, 'id');
    return fileAnnotations.concat(uniqueProjectAnnotations);
  }
}
