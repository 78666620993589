import React, { useCallback } from 'react';
import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from '@cognite/annotations-experimental';
import { Colors, toast } from '@cognite/cogs.js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PendingCogniteAnnotation } from '@cognite/annotations';
import { FileInfo } from '@cognite/sdk';
import {
  FetchAnnotationsForProcedureDTO,
  Phase,
  Reference,
  StepItem,
} from 'types';
import { typedKeys } from 'utils/tsUtils';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { annotationsService } from 'services';
import { annotationsResourceService } from 'resources';
import { useActiveWorkStep } from '../activeWorkStep/hooks';
import {
  actions,
  isNotAnnotationSpec,
  AnnotationsState,
  fetchAnnotationsForFileId,
  saveFileColor,
  fetchAnnotationsForProcedure,
} from './annotationsReducer';
import { AuthState } from '../auth';
import { useHotSpot } from '../hotSpot/hooks';
import { findClosest, findWithSameRegion } from './annotationDuplicates';

export const useAnnotations = () => {
  const dispatch = useDispatch();
  const { active } = useActiveWorkStep();
  const { activeHotSpot, setActiveHotSpot, clearActiveHotSpot } = useHotSpot();

  const { editMode, newAnnotation, annotations, fileColors } = useSelector<
    RootState,
    AnnotationsState
  >((state) => state.annotations);
  const { user } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const metrics = useMetrics(METRICS_AREA.ANNOTATIONS);

  const cancelNewAnnotation = () => {
    if (editMode || !!newAnnotation) {
      dispatch(actions.cleanUpNewAnnotation());
      clearActiveHotSpot();
    }
  };
  const clearAnnotations = () => {
    dispatch(actions.clearAnnotations());
  };

  const saveAnnotation = (
    annotation: CogniteAnnotationSpec,
    oldAnnotation?: CogniteAnnotation,
    updateAnnotationOfWorkStepsInvalid?: Function,
    oldAnnotationToBeMigrated?: CogniteAnnotation
  ) => {
    annotationsResourceService
      .createAnnotation(annotation)
      .then((response) => {
        const [savedAnnotation] = response;
        if (updateAnnotationOfWorkStepsInvalid && oldAnnotation) {
          dispatch(actions.removeAnnotation({ annotation: oldAnnotation }));
        }

        if (updateAnnotationOfWorkStepsInvalid && oldAnnotationToBeMigrated) {
          dispatch(
            actions.removeAnnotation({
              annotation: oldAnnotationToBeMigrated,
            })
          );
        }
        dispatch(actions.addAnnotation({ annotation: savedAnnotation }));
        dispatch(actions.cleanUpNewAnnotation());
        if (updateAnnotationOfWorkStepsInvalid && oldAnnotation) {
          updateAnnotationOfWorkStepsInvalid(savedAnnotation, oldAnnotation);
        }
        if (updateAnnotationOfWorkStepsInvalid && oldAnnotationToBeMigrated) {
          updateAnnotationOfWorkStepsInvalid(
            savedAnnotation,
            oldAnnotationToBeMigrated
          );
        }
        if (activeHotSpot.annotation) {
          setActiveHotSpot(savedAnnotation);
        }
        clearActiveHotSpot();
        metrics.track(METRICS.ANNOTATION_EDIT, {
          pnidId: savedAnnotation.annotatedResourceId,
        });
      })
      .catch((_error) => {
        toast.error(
          <div>
            <h3>Oh no</h3> We couldn’t create the hotspot. Try again.
          </div>
        );
      });
  };

  const copyAnnotation = (annotation: CogniteAnnotation) => {
    const {
      id: _id,
      createdTime: _createdTime,
      lastUpdatedTime: _lastUpdatedTime,
      ...annotationCopy
    } = annotation;
    // @ts-ignore
    delete annotationCopy.box;
    // @ts-ignore
    delete annotationCopy.mark;
    typedKeys(annotationCopy as CogniteAnnotationSpec).forEach((key) => {
      if (annotationCopy[key] === null) {
        delete annotationCopy[key];
      }
    });
    return annotationCopy;
  };

  const editAnnotation = (annotation: CogniteAnnotation) => {
    const copiedAnnotation = copyAnnotation(annotation);
    dispatch(
      actions.setNewAnnotation({
        newAnnotation: {
          ...copiedAnnotation,
          source: user,
          data: { ...copiedAnnotation.data, sourceType: 'user' },
        },
        oldAnnotation: annotation,
      })
    );
  };

  const createNewAnnotation = (
    annotation: PendingCogniteAnnotation,
    file?: FileInfo
  ) => {
    // @ts-ignore - types in annotations-experimental are wrong.
    // This is only temporarily til PnID viewer gets updated to reflect different regions
    const copiedAnnotation = {
      annotatedResourceId: annotation.fileId || file?.id,
      annotatedResourceExternalId:
        annotation.fileExternalId || file?.externalId,
      annotatedResourceType: 'file',
      annotationType: 'isolation_planner_hotspot',
      status: annotation.status,
      source: user,
      region: {
        type: 'boundingBox',
        ...annotation.box,
      },
    } as CogniteAnnotationSpec;
    dispatch(actions.setNewAnnotation({ newAnnotation: copiedAnnotation }));
  };

  const fetchAnnotations = useCallback(
    (fileId: number, phases: Phase[], procedureNeedsMigration = false) => {
      if (!annotations[fileId]) {
        const projectAnnotations = annotationsService.getAllAnnotationsForFile(
          fileId,
          phases
        );
        dispatch(
          fetchAnnotationsForFileId({
            fileId,
            projectAnnotations,
            procedureNeedsMigration,
          })
        );
      }
    },
    [dispatch, annotations]
  );

  const loadAnnotationsForProcedure = useCallback(
    (fileIds: number[], phases: Phase[], needMigration = false) => {
      const dto: FetchAnnotationsForProcedureDTO = {
        fileAnnotations: fileIds.map((fileId) => {
          return {
            fileId,
            projectAnnotations: annotationsService.getAllAnnotationsForFile(
              fileId,
              phases
            ),
            procedureNeedsMigration: needMigration,
          };
        }),
      };

      dispatch(fetchAnnotationsForProcedure(dto));
    },
    [dispatch]
  );

  const setEditMode = (mode: boolean) => {
    dispatch(actions.setEditMode({ editMode: mode }));
  };

  const setNewAnnotationNeedRelativeRef = (needRelativeRef: boolean) => {
    dispatch(actions.setNewAnnotationNeedRelativeRef({ needRelativeRef }));
  };

  const removeAnnotationFromState = useCallback(
    (annotation?: CogniteAnnotation) => {
      if (annotation) {
        dispatch(actions.removeAnnotation({ annotation }));
      }
    },
    [dispatch]
  );

  const selectRelativeRef = (item?: StepItem, relation?: Reference) => {
    dispatch(actions.setRelativeRef({ item, relation }));
  };

  const selectLine = (item?: StepItem) => {
    dispatch(actions.setLine({ item }));
  };

  const deleteAnnotation = async (
    annotation: CogniteAnnotation,
    needsMigration: boolean
  ) => {
    if (annotation.data.sourceType !== 'pipeline') {
      return annotationsResourceService
        .deleteAnnotation(annotation.id)
        .then((_response) => {
          removeAnnotationFromState(annotation);

          if (needsMigration) {
            removeOldAnnotationFromState(annotation);
          }
          metrics.track(METRICS.ANNOTATION_DELETE, {
            pnidId: annotation.annotatedResourceId,
          });
          return true;
        })
        .catch((_error) => {
          toast.error(<div>We couldn’t delete the hotspot. Try again.</div>);
          return false;
        });
    }
    toast.warning(
      <div>You can only delete manual hotspots and this isn’t one.</div>
    );
    return false;
  };

  const updateWithUserCreatedAnnotation = async ({
    userAnnotation,
    pipeLineAnnotation,
  }: {
    userAnnotation?: CogniteAnnotation;
    pipeLineAnnotation?: CogniteAnnotation;
  }) => {
    if (userAnnotation && pipeLineAnnotation) {
      dispatch(
        actions.updateWithUserCreatedAnnotation({
          userAnnotation,
          pipeLineAnnotation,
        })
      );
    }
  };
  function removeCustomOffset(annotation: CogniteAnnotation) {
    // Use type assertion to temporarily remove 'customOffset' for modification
    const annotationWithoutCustomOffset = annotation as any;
    delete annotationWithoutCustomOffset.customOffset;
    return annotationWithoutCustomOffset as CogniteAnnotation;
  }
  const createUserAnnotation = async (
    oldAnnotation?: CogniteAnnotation,
    annotation?: CogniteAnnotation
  ) => {
    if (
      annotation &&
      oldAnnotation &&
      oldAnnotation.data?.sourceType === 'pipeline'
    ) {
      const response = await createAnnotation(annotation);
      if (response) {
        const [updatedAnnotation] = response;
        return { annotation: updatedAnnotation, forRemoval: oldAnnotation };
      }
    } else if (
      annotation &&
      oldAnnotation &&
      oldAnnotation.data?.sourceType !== 'pipeline'
    ) {
      await createAnnotation(removeCustomOffset(annotation));
    }
    return { annotation: oldAnnotation };
  };

  const createAnnotation = async (annotation: CogniteAnnotation) => {
    const {
      id: _id,
      createdTime: _createdTime,
      lastUpdatedTime: _lastUpdatedTime,
      ...updatedAnnotation
    } = annotation;
    // @ts-ignore
    delete updatedAnnotation.box;
    // @ts-ignore
    delete updatedAnnotation.mark;
    typedKeys(updatedAnnotation as CogniteAnnotationSpec).forEach((key) => {
      if (updatedAnnotation[key] === null) {
        delete updatedAnnotation[key];
      }
    });
    return annotationsResourceService.createAnnotation({
      ...updatedAnnotation,
      data: { ...updatedAnnotation.data, sourceType: 'user' },
      source: user,
    });
  };

  const createHighlighted = ({
    annotation,
    strokeWidth,
    strokeColor = Colors.midblue.hex(),
  }: {
    annotation: CogniteAnnotation | any;
    strokeWidth: number;
    strokeColor?: string;
  }) => {
    const highlightedAnnotation = ({
      ...annotation,
      box: { ...annotation.region },
      mark: {
        strokeColor,
        strokeWidth,
        highlight: true,
      },
    } as unknown) as CogniteAnnotation & { mark: any };
    return highlightedAnnotation;
  };

  const annotationsWithCustomOffset = useCallback(
    (fileId) => {
      if (fileId && annotations[fileId]) {
        const customAnnotationsOffset = {};

        annotations[fileId].forEach((annotation: any) => {
          if (annotation.customOffset && annotation.id) {
            // @ts-ignore
            customAnnotationsOffset[annotation.id.toString()] =
              annotation.customOffset;
          }
        });

        return customAnnotationsOffset;
      }

      return {};
    },
    [annotations]
  );

  const highlightedAnnotations = useCallback(
    (fileId) => {
      if (fileId && annotations[fileId]) {
        const uniqueAnnotations = annotations[fileId].filter(
          (annotation, index) =>
            annotations[fileId].findIndex(
              // @ts-ignore
              (a) => a.id && a.id === annotation.id
            ) === index
        );
        const indirectRefs = uniqueAnnotations.filter(
          (annotation) =>
            isNotAnnotationSpec(annotation) &&
            annotation.linkedResourceExternalId &&
            annotation.linkedResourceExternalId ===
              active.workStep?.stepItem?.annotation?.data.indirectExternalId
        );

        const indirectLines = uniqueAnnotations.filter(
          (annotation) =>
            isNotAnnotationSpec(annotation) &&
            annotation.linkedResourceExternalId &&
            annotation.linkedResourceExternalId ===
              active.workStep?.stepItem?.annotation?.data.lineExternalId
        );

        const result: Array<
          CogniteAnnotation | CogniteAnnotationSpec
        > = uniqueAnnotations.map(
          (annotation: CogniteAnnotation | CogniteAnnotationSpec) => {
            if (
              isNotAnnotationSpec(annotation) &&
              active.workStep?.stepItem?.annotation?.id === annotation.id
            ) {
              return createHighlighted({ annotation, strokeWidth: 5 });
            }
            if (
              isNotAnnotationSpec(annotation) &&
              [
                active.workStep?.stepItem?.relativeRef?.annotation?.id,
                active.workStep?.stepItem?.line?.annotation?.id,
              ].includes(annotation.id)
            ) {
              return createHighlighted({ annotation, strokeWidth: 2 });
            }
            if (
              isNotAnnotationSpec(annotation) &&
              !active.workStep?.stepItem?.line?.annotation &&
              indirectLines?.length > 0 &&
              annotation.id &&
              annotation.id ===
                findClosest(
                  active.workStep?.stepItem?.annotation,
                  indirectLines // @ts-ignore
                )?.id
            ) {
              return createHighlighted({
                annotation,
                strokeWidth: 2,
                strokeColor: 'green',
              });
            }
            if (
              isNotAnnotationSpec(annotation) &&
              !active.workStep?.stepItem?.relativeRef?.annotation &&
              indirectRefs?.length > 0 &&
              annotation.id &&
              annotation.id ===
                findClosest(active.workStep?.stepItem?.annotation, indirectRefs) // @ts-ignore
                  ?.id
            ) {
              return createHighlighted({
                annotation,
                strokeWidth: 2,
                strokeColor: 'green',
              });
            }
            if (!isNotAnnotationSpec(annotation)) {
              return createHighlighted({
                annotation,
                strokeWidth: 4,
                strokeColor: 'red',
              });
            }

            return annotation;
          }
        );
        return result;
      }
      return [];
    },
    [active.workStep, annotations]
  );

  const saveAnnotationColors = (
    annotation: CogniteAnnotation | CogniteAnnotationSpec,
    color: string
  ) => {
    dispatch(saveFileColor(annotation, color));
  };

  const setAnnotationCustomOffset = useCallback(
    (
      fileId: number,
      annotationId: number,
      offsetX: number,
      offsetY: number
    ) => {
      dispatch(
        actions.setAnnotationCustomOffset({
          fileId,
          annotationId,
          offsetX,
          offsetY,
        })
      );
    },
    [dispatch]
  );

  const findClosestAnnotationForVerification = useCallback(
    (annotation: CogniteAnnotation, fileId: number) => {
      const fileAnnotations =
        annotations[fileId].filter(
          (curAnnotation: any) =>
            curAnnotation.id &&
            curAnnotation.id !== annotation.id &&
            curAnnotation.source === 'unverified'
        ) || [];
      const closestAnnotation = findWithSameRegion(
        annotation,
        fileAnnotations // @ts-ignore
      ) as CogniteAnnotation;

      return closestAnnotation &&
        closestAnnotation.source === 'unverified' &&
        closestAnnotation.id !== annotation.id
        ? closestAnnotation
        : undefined;
    },
    [annotations]
  );

  const removeOldAnnotationFromState = useCallback(
    (updatedAnnotation: CogniteAnnotation) => {
      if (updatedAnnotation) {
        const oldAnnotation = findClosestAnnotationForVerification(
          updatedAnnotation,
          updatedAnnotation.annotatedResourceId
        );

        const oldAnnotationId = oldAnnotation?.id;

        removeAnnotationFromState(oldAnnotation);
        return oldAnnotationId;
      }

      return undefined;
    },
    [findClosestAnnotationForVerification, removeAnnotationFromState]
  );

  return {
    editMode,
    newAnnotation,
    fileColors,
    saveAnnotation,
    cancelNewAnnotation,
    setEditMode,
    setNewAnnotationNeedRelativeRef,
    editAnnotation,
    createNewAnnotation,
    fetchAnnotations,
    createHighlighted,
    highlightedAnnotations,
    createUserAnnotation,
    updateWithUserCreatedAnnotation,
    deleteAnnotation,
    selectRelativeRef,
    selectLine,
    saveAnnotationColors,
    loadAnnotationsForProcedure,
    clearAnnotations,
    removeOldAnnotationFromState,
    findClosestAnnotationForVerification,
    setAnnotationCustomOffset,
    annotationsWithCustomOffset,
  };
};
