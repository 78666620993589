import React from 'react';
import {
  ExecutionWorkStepButton,
  ExecutionWorkStepItemActionsContainer,
} from './elements';

export interface ExecutionWorkStepItemActionsProps {
  phaseId: string;
  isWorkstepPendingExecution: boolean;
  isWorkstepDone: boolean;
  isWorkStepActive: boolean;
  isVisibleActionButtons: boolean;
  actionsButtonsDisabled: boolean;
  onStepDone: (phaseId: string) => void;
  onStepChecked: (phaseId: string) => void;
  onUndoStep: (phaseId: string) => void;
  onUncheckStep: (phaseId: string) => void;
}

export const ExecutionWorkStepItemActions: React.FC<ExecutionWorkStepItemActionsProps> = ({
  phaseId,
  actionsButtonsDisabled,
  isWorkstepPendingExecution,
  isWorkstepDone,
  onStepDone,
  onStepChecked,
  isVisibleActionButtons,
  onUndoStep,
  isWorkStepActive,
  onUncheckStep,
}: ExecutionWorkStepItemActionsProps) => {
  const stepDone = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onStepDone(phaseId);
  };

  const stepChecked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onStepChecked(phaseId);
  };

  const undoStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onUndoStep(phaseId);
  };

  const uncheckStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onUncheckStep(phaseId);
  };

  if (isVisibleActionButtons) {
    return (
      <ExecutionWorkStepItemActionsContainer>
        {isWorkstepDone ? (
          <ExecutionWorkStepButton
            type="secondary"
            position="left"
            onClick={undoStep}
            disabled={actionsButtonsDisabled}
            data-testid="undo-btn"
          >
            Undo
          </ExecutionWorkStepButton>
        ) : (
          <ExecutionWorkStepButton
            position="left"
            type="primary"
            disabled={actionsButtonsDisabled}
            onClick={stepDone}
            data-testid="done-btn"
          >
            Done
          </ExecutionWorkStepButton>
        )}

        <ExecutionWorkStepButton
          type="primary"
          position="right"
          disabled={isWorkstepPendingExecution || actionsButtonsDisabled}
          onClick={stepChecked}
          data-testid="checked-btn"
        >
          Checked
        </ExecutionWorkStepButton>
      </ExecutionWorkStepItemActionsContainer>
    );
  }

  if (!isWorkstepPendingExecution && isWorkStepActive) {
    return (
      <ExecutionWorkStepItemActionsContainer>
        <ExecutionWorkStepButton
          type="secondary"
          position="right"
          disabled={isWorkstepPendingExecution || actionsButtonsDisabled}
          onClick={uncheckStep}
          data-testid="uncheck-btn"
        >
          Uncheck
        </ExecutionWorkStepButton>
      </ExecutionWorkStepItemActionsContainer>
    );
  }

  return <></>;
};
