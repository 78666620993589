import isObject from 'lodash/isObject';

export const tryParseJSON = <T>(jsonString?: string, defaultVal: any = []) => {
  if (!jsonString) {
    return defaultVal as string[];
  }
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && isObject(o)) {
      return (o as unknown) as T;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to parse');
  }

  return defaultVal as string[];
};
