import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Tabs } from '@cognite/cogs.js';
import { Project } from 'types';
import { projectsResourceService } from 'resources';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchRootAssetsContent, SearchState } from 'redux/reducers/search';
import { ModalLoader } from 'shared/ModalLoader';
import { showErrorNotification } from 'utils/errors';
import { Wrapper, Grid } from './elements';
import { StartPageLoading } from './components/Loading/StartPageLoading';
import { cookieServices } from '../../services';
import PlanningView from './components/Views/PlanningView';
import ExecutionView from './components/Views/ExecutionView';

const StartPageModal = lazy(() => import('./components/StartPageModal'));
const AuthorizationModal = lazy(
  () => import('./components/AuthorizationModal')
);

const StartPage = () => {
  const dispatch = useDispatch();

  const { rootAssetsContent, selectedRootAsset } = useSelector<
    RootState,
    SearchState
  >((state) => {
    return state.search;
  });

  const [projects, setProjects] = useState<Project[]>([]);

  const [isOnlyMyProjects, setIsOnlyMyProjects] = useState<boolean>(false);

  const [isPageReady, setIsPageReady] = useState(false);
  const [dashboardModalVisible, setDashboardModalVisible] = useState<boolean>(
    false
  );
  const [authModalVisible, setAuthModalVisible] = useState<boolean>(false);
  const [clickedProcedure, setClickedProcedure] = useState<Project | undefined>(
    undefined
  );
  const [activeViewTab, setActiveViewTab] = useState<string>(
    cookieServices.getStartPageViewTab() || 'planning'
  );

  const showErrorMessage = useCallback((error) => {
    const message = (
      <div data-testid="procedures-fetch-error-msg">
        <h3>We’re sorry</h3> We couldn’t load the procedures. Try to refresh the
        page.
      </div>
    );
    showErrorNotification({ message, error });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // data is not ready in back after a save action
    setTimeout(() => {
      projectsResourceService
        .fetchProjects(selectedRootAsset)
        .then(
          (projectsList) => {
            setProjects(projectsList);
            setIsPageReady(true);
          },
          (err) => showErrorMessage(err)
        )
        .catch((err) => showErrorMessage(err));
    }, 500);
    // eslint-disable-next-line
  }, [selectedRootAsset]);

  useEffect(() => {
    if (!rootAssetsContent.length && !isPageReady) {
      dispatch(fetchRootAssetsContent());
    }
  }, [dispatch, rootAssetsContent, isPageReady]);

  const onTabChange = (tabName: string) => {
    setActiveViewTab(tabName);
    cookieServices.setStartPageViewTab(tabName);
  };

  return (
    <Wrapper>
      {clickedProcedure && authModalVisible && (
        <Suspense fallback={<ModalLoader />}>
          <AuthorizationModal
            procedure={clickedProcedure}
            visible={authModalVisible}
            setVisible={setAuthModalVisible}
          />
        </Suspense>
      )}

      {clickedProcedure && dashboardModalVisible && (
        <Suspense fallback={<ModalLoader />}>
          <StartPageModal
            procedure={clickedProcedure}
            visible={dashboardModalVisible}
            setVisible={setDashboardModalVisible}
            setAuthVisible={setAuthModalVisible}
          />
        </Suspense>
      )}

      {!isPageReady && <StartPageLoading />}

      {isPageReady && (
        <Grid>
          <Tabs
            defaultActiveKey={activeViewTab}
            className="start-page-tabs"
            onChange={onTabChange}
          >
            <Tabs.TabPane key="planning" tab="Planning view">
              <PlanningView
                setClickedProcedure={setClickedProcedure}
                setDashboardModalVisible={setDashboardModalVisible}
                setIsOnlyMyProjects={setIsOnlyMyProjects}
                projects={projects}
                isOnlyMyProjects={isOnlyMyProjects}
                selectedRootAsset={selectedRootAsset}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="execution" tab="Execution view">
              <ExecutionView
                setClickedProcedure={setClickedProcedure}
                setDashboardModalVisible={setDashboardModalVisible}
                setIsOnlyMyProjects={setIsOnlyMyProjects}
                projects={projects}
                isOnlyMyProjects={isOnlyMyProjects}
                selectedRootAsset={selectedRootAsset}
              />
            </Tabs.TabPane>
          </Tabs>
        </Grid>
      )}
    </Wrapper>
  );
};

export default StartPage;
