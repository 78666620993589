import React, { useEffect, useState } from 'react';
import { Avatar, Menu } from '@cognite/cogs.js';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AuthState } from 'redux/reducers/auth';
import { ProjectState } from 'redux/reducers/project';
import { AppMode, ProjectStatus } from 'types';
import { SearchState } from 'redux/reducers/search';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import config from 'config/config';

import SideBar from '../SideBar/SideBar';
import Badge from '../Badge';
import AssetSelectorButton from '../AssetSelectorButton/AssetSelectorButton';
import {
  IsoPlanIcon,
  CogsTopBar,
  NonClickableAsset,
  TitleText,
} from './elements';

export const TopBar = () => {
  const history = useHistory();
  const location = useLocation();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);

  const { user } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const { selectedRootAssetName } = useSelector<RootState, SearchState>(
    (state) => {
      return state.search;
    }
  );

  const { project, appMode } = useSelector<RootState, ProjectState>((state) => {
    return state.project;
  });

  const metrics = useMetrics(METRICS_AREA.TOPBAR);

  const isPath = (page: string) => location.pathname === page;

  const isRootOrSearch = () => isPath('/') || isPath('/search');

  const [buttonClickable, setButtonClickable] = useState(isRootOrSearch());

  const openDrawer = () => {
    setDrawerVisible(true);
    metrics.track(METRICS.TOPBAR_OPEN_SIDEBAR, { status: project.status });
  };

  useEffect(() => {
    setButtonClickable(isRootOrSearch());
    // eslint-disable-next-line
  }, [location.pathname]);

  const pickBadgeBackground = (projStatus?: ProjectStatus) => {
    switch (projStatus) {
      case 'compilation':
        return '#FFDC7F';
      case 'executionReady':
      case 'done':
        return '#C8F4E7';
      default:
        return 'white';
    }
  };

  const pickBadgeText = (projStatus?: ProjectStatus) => {
    switch (projStatus) {
      case 'reviewReady':
        return 'READY FOR REVIEW';
      case 'review':
        return 'IN REVIEW';
      case 'executionReady':
        return 'READY FOR EXECUTION';
      case 'execution':
        return 'IN EXECUTION';
      case 'done':
        return 'DONE';
      default:
        return 'IN PLANNING';
    }
  };

  const pickBadgeTextColor = (projStatus?: ProjectStatus) => {
    switch (projStatus) {
      case 'reviewReady':
        return '#00665C';
      case 'review':
        return '#333333';
      case 'executionReady':
      case 'done':
        return '#00665C';
      case 'execution':
        return '#D51A46';
      default:
        return '#000000';
    }
  };

  const pickBadgeBorder = (projStatus?: ProjectStatus) => {
    switch (projStatus) {
      case 'reviewReady':
      case 'done':
        return '#6ED8BE';
      case 'review':
        return '#FFDC7F';
      case 'executionReady':
        return '#C8F4E7';
      case 'execution':
        return '#D51A46';
      default:
        return '#FFDC7F';
    }
  };

  const badgeContent = (mode: AppMode, projStatus?: ProjectStatus) => {
    if (mode === 'view') {
      return (
        <Badge
          style={{ marginRight: '16px' }}
          size={14}
          text="VIEW ONLY"
          textColor="#00665C"
          borderColor="#6ED8BE"
        />
      );
    }
    if (projStatus) {
      return (
        <Badge
          data-testid="topbar-procedure-badge"
          style={{ marginRight: '16px' }}
          size={14}
          background={pickBadgeBackground(projStatus)}
          text={pickBadgeText(projStatus)}
          textColor={pickBadgeTextColor(projStatus)}
          borderColor={pickBadgeBorder(projStatus)}
        />
      );
    }
    return null;
  };

  const subtitleContent = buttonClickable ? (
    <AssetSelectorButton />
  ) : (
    <NonClickableAsset data-testid="asset-selector-drawer-button-non-clickable">
      {selectedRootAssetName}
    </NonClickableAsset>
  );

  const retractedFromExecutionBadge = project.executionStopped?.date &&
    project.status !== 'execution' && (
      <Badge
        data-testid="retracted-from-execution-badge"
        style={{ marginRight: '8px' }}
        size={14}
        text="RETRACTED FROM EXECUTION"
        textColor="#D51A46"
        borderColor="#D51A46"
      />
    );

  const pickPageTitle = (page?: string) => {
    switch (page) {
      case '/':
        return (
          <TitleText data-testid="topbar-text-start-page">Overview</TitleText>
        );
      case '/search':
        return (
          <TitleText data-testid="topbar-text-search-page">Search</TitleText>
        );
      default:
        return (
          <>
            <TitleText data-testid="topbar-text-procedure">
              {project.name}
            </TitleText>
            {retractedFromExecutionBadge}
            {badgeContent(appMode, project.status)}
          </>
        );
    }
  };

  useEffect(() => {
    setHeaderVisible(
      !(
        project.status === 'execution' &&
        location.pathname.match(/\/project\/edit/g)
      )
    );
  }, [location, project]);

  return (
    <div
      data-testid="topBar"
      key={location.key}
      style={{ display: headerVisible ? 'block' : 'none' }}
    >
      <CogsTopBar>
        <CogsTopBar.Left>
          <SideBar
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            project={project}
          />
          <CogsTopBar.Action icon="HamburgerMenu" onClick={openDrawer} />
          <CogsTopBar.Logo
            title="Cognite Isoplan"
            logo={
              <IsoPlanIcon
                onClick={() => {
                  history.push('/');
                }}
              />
            }
            subtitle={subtitleContent}
          />
          <CogsTopBar.Item>
            {pickPageTitle(history.location.pathname)}
          </CogsTopBar.Item>
          <CogsTopBar.Item />
        </CogsTopBar.Left>
        <CogsTopBar.Right>
          <CogsTopBar.Action
            icon="Documentation"
            text=""
            title="Documentation"
            onClick={() => window.open(config.DOCS_URL, '_blank')}
          />
          <CogsTopBar.Actions
            actions={[
              {
                key: 'action-user',
                component: <Avatar text={user} />,
                menu: (
                  <Menu>
                    <Menu.Item>{user}</Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        const { protocol, host } = window.location;
                        window.location.href = `${protocol}//${host}`;
                      }}
                    >
                      Switch project
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/logout')}>
                      Logout
                    </Menu.Item>
                  </Menu>
                ),
              },
            ]}
          />
        </CogsTopBar.Right>
      </CogsTopBar>
    </div>
  );
};
