import styled from 'styled-components/macro';
import { Colors } from '@cognite/cogs.js';

export const SubHeaderItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 15px 0px 15px;
  border-left: 1px solid ${Colors['greyscale-grey4'].hex()};
  &:first-child {
    border-left: none;
    padding: 0px 15px 0px 0px;
  }

  .cogs-switch {
    margin-left: 5px;
    .switch-ui {
      background: var(--cogs-midblue-3);
    }
  }
`;

export const SubHeaderAtom = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 8px;
`;

export const SubHeaderContainer = styled.div.attrs({ className: 'z-4' })`
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 43px;
  border: 1px solid var(--cogs-color-strokes-default);
`;

export const SubHeaderViewOnlyNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  background: var(--cogs-midblue-6);
  color: var(--cogs-greyscale-grey8);
  font-size: 11px;
`;
