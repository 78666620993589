import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { filesResourceService } from 'resources';
import { KeyValuePair } from 'types';
import { fetchActiveFileById, FilesState } from './filesReducer';

export const useFiles = () => {
  const dispatch = useDispatch();

  const { activeFile, connectedFiles, files } = useSelector<
    RootState,
    FilesState
  >((state: RootState) => state.files);

  const fetchFile = useCallback(
    (fileId: number) => {
      dispatch(fetchActiveFileById(fileId));
    },
    [dispatch]
  );

  const checkPnidsUpdates = useCallback((): Promise<KeyValuePair> => {
    if (files.length) {
      const currentIds = files.map((pnid) => pnid.id);

      const latestIdsPromise = files.map((pnid) =>
        filesResourceService.getIdOfLatestFile(pnid.name)
      );
      return Promise.all(latestIdsPromise).then((latestIds) => {
        const onlyDifferentIds = currentIds.reduce(
          (arr: number[][], _, i: number) => {
            if (currentIds[i] !== latestIds[i]) {
              const idsArray = [currentIds[i], latestIds[i]];
              return [...arr, idsArray];
            }
            return [...arr];
          },
          [] as number[][]
        );
        // In some cases where the p&id is not contextualized, it would match with 0.
        // When that happens we don't want to update the file.
        const mapOutEmptyIds = onlyDifferentIds.filter(
          (idPairs) => idPairs[1] && idPairs[1] !== 0
        );
        return Object.fromEntries(mapOutEmptyIds);
      });
    }
    return Promise.resolve({});
  }, [files]);

  const getPnidNamesFromIds = useCallback(
    (fileIds: string[]) => {
      const listOfNames = fileIds.reduce((arr: string[], fileId: string) => {
        const relevantFile = files.find((file) => file.id === +fileId);
        if (relevantFile) {
          return [...arr, relevantFile.name];
        }
        return [...arr];
      }, [] as string[]);
      return listOfNames;
    },
    [files]
  );

  return {
    activeFile,
    connectedFiles,
    files,
    fetchFile,
    checkPnidsUpdates,
    getPnidNamesFromIds,
  };
};
