import { CogniteAnnotation } from '@cognite/annotations-experimental';
import { KeyValuePair, Phase, StepItem, WorkStep } from 'types';

export class WorkStepsMigrationService {
  /**
   * Mark all worksteps with error that they need revision.
   * Updates annotatedResourceId (fileId) for annotations used in the procedure.
   * @param phases Phase[]
   * @param filesIdsMap oldFile->newFile id map
   * @returns
   */
  migrateWorkSteps(phases: Phase[], filesIdsMap: KeyValuePair): Phase[] {
    if (!phases || !phases.length) {
      return [];
    }

    const newPhases = phases.map((phase) => {
      return {
        ...phase,
        workSteps: phase.workSteps.map((workStep) => {
          return {
            ...workStep,
            stepItem: this.migrateWorkstepStepItem(
              filesIdsMap,
              workStep?.stepItem
            ),
            error:
              workStep.error && workStep.error.needMigration
                ? workStep.error
                : this.getErrorIfUpdated(filesIdsMap, workStep?.stepItem),
          };
        }),
      };
    });

    return newPhases;
  }

  updateWorkstepsWithAnnotation(
    phases: Phase[],
    updatedWorkStep: WorkStep,
    oldAnnotationId: number,
    newAnnotation: CogniteAnnotation
  ): Phase[] {
    if (!phases || !phases.length) {
      return [];
    }

    const newPhases = phases.map((phase) => {
      return {
        ...phase,
        workSteps: phase.workSteps.map((workStep) => {
          if (
            workStep.id === updatedWorkStep.id ||
            !workStep.error ||
            !workStep.error.needMigration
          ) {
            return workStep;
          }
          return {
            ...workStep,
            stepItem: this.overrideWorkstepStepItemAnnotations(
              oldAnnotationId,
              newAnnotation,
              workStep?.stepItem
            ),
          };
        }),
      };
    });

    return newPhases;
  }

  private getErrorIfUpdated(filesIdsMap: KeyValuePair, stepItem?: StepItem) {
    const usedFileIds = [
      this.getAnnotationResourceId(stepItem?.annotation),
      stepItem?.line && stepItem?.line.annotation
        ? this.getAnnotationResourceId(stepItem?.line.annotation)
        : undefined,
      stepItem?.relativeRef && stepItem?.relativeRef.annotation
        ? this.getAnnotationResourceId(stepItem?.relativeRef.annotation)
        : undefined,
    ].filter((n) => n);

    if (!usedFileIds || !usedFileIds.length) {
      return undefined;
    }

    const hasUpdatedPnid = Object.keys(filesIdsMap).some((id) =>
      usedFileIds.includes(+id)
    );
    return !hasUpdatedPnid
      ? undefined
      : {
          comment: 'This step needs revision',
          data: {},
          needMigration: true,
        };
  }

  private getWorkstepAnnotationsIds(stepItem?: StepItem): number[] {
    let annotationsIds = [
      this.getAnnotationId(stepItem?.annotation),
      stepItem?.line && stepItem?.line.annotation
        ? this.getAnnotationId(stepItem?.line.annotation)
        : undefined,
      stepItem?.relativeRef && stepItem?.relativeRef.annotation
        ? this.getAnnotationId(stepItem?.relativeRef.annotation)
        : undefined,
    ];

    annotationsIds = annotationsIds.filter((n) => n);

    if (!annotationsIds || !annotationsIds.length) {
      return [];
    }
    return annotationsIds as number[];
  }

  private migrateWorkstepStepItem(
    filesIdsMap: KeyValuePair,
    stepItem?: StepItem
  ): StepItem {
    const migratedStepItem = {
      ...this.migrateStepItemAnnotationResourceId(filesIdsMap, stepItem),
      line: this.migrateStepItemAnnotationResourceId(
        filesIdsMap,
        stepItem?.line
      ),
      relativeRef: this.migrateStepItemAnnotationResourceId(
        filesIdsMap,
        stepItem?.relativeRef
      ),
    };

    return migratedStepItem;
  }

  private overrideWorkstepStepItemAnnotations(
    oldAnnotationId: number,
    newAnnotation: CogniteAnnotation,
    stepItem?: StepItem
  ): StepItem {
    const migratedStepItem = {
      ...this.overrideStepItemAnnotation(
        oldAnnotationId,
        newAnnotation,
        stepItem
      ),
      line: this.overrideStepItemAnnotation(
        oldAnnotationId,
        newAnnotation,
        stepItem?.line
      ),
      relativeRef: this.overrideStepItemAnnotation(
        oldAnnotationId,
        newAnnotation,
        stepItem?.relativeRef
      ),
    };

    return migratedStepItem;
  }

  private migrateStepItemAnnotationResourceId(
    filesIdsMap: KeyValuePair,
    stepItem?: StepItem
  ): StepItem | undefined {
    if (!stepItem) {
      return undefined;
    }
    return {
      ...stepItem,
      annotation: this.migrateAnnotationFileId(
        stepItem.annotation,
        filesIdsMap
      ),
    };
  }

  private overrideStepItemAnnotation(
    oldAnnotationId: number,
    newAnnotation: CogniteAnnotation,
    stepItem?: StepItem
  ): StepItem | undefined {
    if (!stepItem) {
      return undefined;
    }

    if (!stepItem.annotation) {
      return stepItem;
    }

    if (stepItem.annotation.id === oldAnnotationId) {
      return {
        ...stepItem,
        annotation: newAnnotation,
      };
    }

    return stepItem;
  }

  private migrateAnnotationFileId(annotation: any, filesIdsMap: KeyValuePair) {
    // eslint-disable-next-line
    const annotatedResourceId = filesIdsMap.hasOwnProperty(
      annotation?.annotatedResourceId
    )
      ? filesIdsMap[annotation?.annotatedResourceId]
      : annotation?.annotatedResourceId;
    return !annotation
      ? undefined
      : {
          ...annotation,
          annotatedResourceId,
          source: 'unverified',
        };
  }

  private getAnnotationResourceId(annotation: any): number | undefined {
    return !annotation ? undefined : annotation.annotatedResourceId;
  }
  private getAnnotationId(annotation: any): number | undefined {
    return !annotation ? undefined : annotation.id;
  }
}
