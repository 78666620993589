import { Button, Flex, Icon } from '@cognite/cogs.js';
import { FlexArea } from 'pages/Project/elements';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { PassValve, Project } from 'types';
import ProjectInfoSaved from 'pages/Project/components/ProjectInfoSaved';
import PassingValves from 'pages/Project/components/PassingValves';
import { WorkstepsTags } from 'pages/Project/components/WorkstepsTags';
import StopExecutionModal from 'shared/Modal';
import { useHistory } from 'react-router-dom';
import { ProjectInfoModalWrapper } from './elements';

// taken from cogs and modified

export interface ProjectInfoModalProps {
  project: Project;
  passValves: PassValve[];
  visible: boolean;
  setVisible: Function;
  stopExecution: () => void;
  viewOnly: boolean;
}

export const ProjectInfoModal = ({
  project,
  passValves,
  visible,
  setVisible,
  stopExecution,
  viewOnly,
}: ProjectInfoModalProps) => {
  const [showStopExecModal, setShowStopExecModal] = useState(false);

  const history = useHistory();

  return (
    <Modal
      closeTimeoutMS={50}
      className="cogs-modal"
      overlayClassName="cogs-modal-overlay"
      isOpen={!!visible}
      ariaHideApp={false}
      onRequestClose={() => setVisible(false)}
      style={{
        content: {
          width: '100%',
          outline: 'none',
          top: '50%',
          transform: 'translateY(-50%)',
          margin: '0 auto',
          minHeight: '100%',
        },
        overlay: {
          transition: 'opacity 50ms ease-in-out',
        },
      }}
    >
      <Button
        key="modal-close"
        unstyled
        className="cogs-modal-close"
        onClick={() => setVisible(false)}
      >
        <Icon type="Close" />
      </Button>

      <ProjectInfoModalWrapper>
        <div className="modal-spacer">
          <Flex direction="row" style={{ gap: '5px', alignItems: 'center' }}>
            <Button
              data-testid="to-dashboard-btn"
              type="tertiary"
              style={{
                marginRight: '10px',
                background: 'green',
                color: 'white',
              }}
              onClick={() => history.push(`/project/${project.id}`)}
            >
              <Icon type="ArrowLeft" style={{ marginRight: '10px' }} />
              To Dashboard
            </Button>
            {!viewOnly && (
              <Button
                data-testid="stop-execution-btn"
                type="danger"
                onClick={() => setShowStopExecModal(true)}
              >
                <Icon type="Close" style={{ marginRight: '10px' }} />
                Valid Change Required
              </Button>
            )}
          </Flex>
          <StopExecutionModal
            visible={showStopExecModal}
            setVisible={setShowStopExecModal}
            // eslint-disable-next-line no-console
            okHandler={stopExecution}
            okText="Yes, stop execution"
            okButtonType="danger"
            cancelText="No, keep going"
            title="Are you sure you want to stop the execution?"
          >
            <p data-testid="stop-execution-msg">
              If you stop the execution it indicates you have encountered a
              problem. The procedure goes back into planning mode and you will
              not be able to continue the execution.
            </p>
          </StopExecutionModal>
        </div>

        <FlexArea>
          <div className="col c1">
            <ProjectInfoSaved project={project} />
          </div>

          <div className="col c2">
            <PassingValves
              editMode={false}
              valves={passValves}
              checkedValvesArray={project.checkedValves}
            />
            <br />
            <WorkstepsTags
              projectStatus={project.status}
              editMode={false}
              showTagNumbers
            />
          </div>
        </FlexArea>
      </ProjectInfoModalWrapper>
    </Modal>
  );
};
