import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  actions,
  fetchAttachments,
  fetchApprovalFiles,
  fetchPreWorkItems,
  fetchReviewComments,
} from 'redux/reducers/project';
import { fetchFilesByIds, fetchPnIDsforAsset } from 'redux/reducers/files';
import workStepsSlice, {
  fetchWorkSteps,
  fetchWorkStepsAndRemoveExecution,
} from 'redux/reducers/worksteps';
import { AssetMappingType, Project } from 'types';
import { RootState } from 'redux/store';
import { AuthState } from 'redux/reducers/auth';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { actions as searchActions } from 'redux/reducers/search';
import useMetrics from './useMetrics';
import { useDrawings } from './useDrawings';

export const useProjectLoad = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { copyDrawingsToNewProcedure } = useDrawings();

  const { user } = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  const metrics = useMetrics(METRICS_AREA.PROJECT_LOAD);

  const loadProcedure = (proj: Project, loadFiles = true) => {
    const timer = metrics.start(METRICS.PROJECT_LOAD_TIME, {
      project: proj.id,
      status: proj.status,
    });
    const procedureId = proj.id as string;
    dispatch(workStepsSlice.actions.clearWorkSteps());
    dispatch(actions.selectProject(proj));
    dispatch(
      fetchWorkSteps({
        projectId: procedureId,
        projectStatus: proj.status || 'compilation',
      })
    );
    dispatch(fetchReviewComments(procedureId));
    dispatch(fetchPreWorkItems(procedureId));
    dispatch(fetchAttachments(procedureId));
    dispatch(fetchApprovalFiles(procedureId));
    const listOfFileIds = proj.pnIds?.map((pnid) => Number(pnid.id));
    if (listOfFileIds && loadFiles === true) {
      dispatch(fetchFilesByIds(listOfFileIds));
    }
    dispatch(actions.setIsProcedureLoaded());
    metrics.stop(timer);
  };

  const openProcedure = (proj: Project, editMode: boolean = true) => {
    dispatch(workStepsSlice.actions.clearWorkSteps());
    dispatch(actions.selectProject(proj));
    metrics.track(METRICS.PROJECT_LOAD, {
      site: proj.rootAsset,
      status: proj.status,
      editMode,
    });

    if (editMode) {
      history.push(`/project/${proj.id}`);
    } else {
      history.push(`/project/${proj.id}?viewOnly=true`);
    }
  };

  const copyCreateNewProcedure = (proj: Project) => {
    const newId = uuidv4();
    dispatch(workStepsSlice.actions.clearWorkSteps());
    dispatch(
      fetchWorkStepsAndRemoveExecution({
        projectId: proj.id,
        projectStatus: proj.status || 'compilation',
      })
    );
    dispatch(
      actions.loadAndCreateProject({ procedure: proj, newId, newUser: user })
    );
    const listOfFileIds = proj.pnIds?.map((pnid) => Number(pnid.id));
    if (listOfFileIds) {
      dispatch(fetchFilesByIds(listOfFileIds));
    }
    dispatch(fetchPreWorkItems(proj.id as string));
    copyDrawingsToNewProcedure(
      { ...proj, id: newId },
      {}
    ).then((pnIdsDrawings) =>
      dispatch(actions.setPnidDrawings({ pnIdsDrawings }))
    );
    dispatch(actions.setIsProcedureLoaded());
    dispatch(searchActions.setClearSearch());
    history.push(`/project/${newId}`);
    metrics.track(METRICS.PROJECT_USE_HISTORICAL, {
      site: proj.rootAsset,
      status: proj.status,
    });
  };

  const createNewProcedure = (
    asset: AssetMappingType,
    selectedRootAsset: string
  ) => {
    const newId = uuidv4();
    dispatch(actions.createProject({ asset, user, newId, selectedRootAsset }));
    dispatch(fetchPnIDsforAsset(asset.assetId));
    dispatch(workStepsSlice.actions.clearWorkSteps());
    dispatch(workStepsSlice.actions.setStatusAsLoaded());
    dispatch(actions.setIsProcedureLoaded());
    dispatch(searchActions.setClearSearch());
    metrics.track(METRICS.PROJECT_CREATE, {
      site: selectedRootAsset,
      status: 'compilation',
    });
    history.push(`/project/${newId}`);
  };

  return {
    loadProcedure,
    openProcedure,
    copyCreateNewProcedure,
    createNewProcedure,
  };
};
