import { Body, Button, Flex, Title } from '@cognite/cogs.js';
import { Comments } from 'components/Comments';
import { useSave } from 'hooks/useSave';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import projectSlice from 'redux/reducers/project';
import { Comment, Project } from 'types';
import { useResolvedProject, useResolvedWorkSteps } from 'hooks/useResolved';
import { createResolvedComments } from 'pages/EditProcedure/components/Worksteps/Review/Resolved';
import { ProjectCard } from '../ProjectCard/ProjectCard';
import { StepRevision } from '../StepRevision';

export type Props = {
  project: Project;
  comments: Comment[];
  user: string;
  viewOnly: boolean;
};

const ResolveAction = ({
  project,
  user,
  isProjectResolved,
  isWorkStepsResolved,
  viewOnly,
}: {
  project: Project;
  user: string;
  isProjectResolved: boolean;
  isWorkStepsResolved: boolean;
  viewOnly: boolean;
}) => {
  const dispatch = useDispatch();

  const shallResolveButtonBeVisible = () => {
    return !viewOnly && (!isProjectResolved || !isWorkStepsResolved);
  };

  if (shallResolveButtonBeVisible()) {
    return (
      <Flex
        direction="row"
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        {!isWorkStepsResolved && (
          <Body
            level={2}
            style={{ color: 'red', paddingRight: '8px' }}
            data-testid="step-errors"
          >
            Not all steps have been revised
          </Body>
        )}
        <Button
          data-testid="dashboard-resolve"
          type="secondary"
          variant="default"
          disabled={!isWorkStepsResolved}
          onClick={() =>
            dispatch(
              projectSlice.actions.resolveComments({
                resolved: {
                  name: user,
                  date: Date.now(),
                },
              })
            )
          }
        >
          {project.resolved?.compiler?.date && project.status === 'review'
            ? 'Confirm resolution'
            : 'Resolve'}
        </Button>
      </Flex>
    );
  }
  return null;
};

export const ReviewComments = ({
  project,
  comments,
  user,
  viewOnly,
}: Props) => {
  const dispatch = useDispatch();
  const { saveReviewComment, saveHandler } = useSave();
  const isProjectResolved = useResolvedProject();
  const isWorkStepsResolved = useResolvedWorkSteps();
  const [resolvedChanged, setResolvedChanged] = useState(false);

  useEffect(() => {
    if (resolvedChanged) {
      saveHandler('Review Comments');
    } else {
      setResolvedChanged(true);
    }
    // Want the hook to just run if resolved changes
    // eslint-disable-next-line
  }, [project.resolved]);

  const undoPredicate = (comment: Comment) => {
    if (
      comment.author !== user ||
      comment?.metaData?.commentType !== 'resolve'
    ) {
      return false;
    }
    if (
      project.status === 'compilation' &&
      !project.resolved?.reviewer?.date &&
      comment.role === 'compiler'
    ) {
      return true;
    }
    if (project.status === 'review' && comment.role === 'reviewer') {
      return true;
    }
    return false;
  };

  return (
    <ProjectCard highlighted={!isProjectResolved || !isWorkStepsResolved}>
      <StepRevision projectStatus={project.status} />
      <div>
        <Flex
          direction="row"
          style={{
            padding: '8px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={4} style={{ display: 'block', marginBottom: '10px' }}>
            REVIEWER&apos;S COMMENTS
          </Title>
          <ResolveAction
            project={project}
            user={user}
            isProjectResolved={isProjectResolved}
            isWorkStepsResolved={isWorkStepsResolved}
            viewOnly={viewOnly || project.status === 'done'}
          />
        </Flex>
        <Comments
          identifier={project.id}
          userRole={project.status === 'review' ? 'reviewer' : 'compiler'}
          user={user}
          readOnly={viewOnly || project.status === 'done'}
          comments={
            comments.concat(createResolvedComments(project.resolved)) || []
          }
          onSave={saveReviewComment}
          undo={{
            predicate: undoPredicate,
            onUndo: () => {
              dispatch(
                projectSlice.actions.resolveComments({
                  resolved: undefined,
                })
              );
            },
          }}
        />
      </div>
    </ProjectCard>
  );
};
