/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useActiveWorkStep } from 'redux/reducers/activeWorkStep/hooks';
import { Menu } from '@cognite/cogs.js';
import { useHotSpot } from 'redux/reducers/hotSpot/hooks';
import { CogniteAnnotation } from '@cognite/annotations-experimental';
import { SelectionMode } from 'redux/reducers/activeWorkStep';
import { useSelectingHotSpot } from 'hooks/useSelectingHotSpot';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { ItemType, Modes, WorkStepAction } from 'types';
import { useActiveWorkstepActions } from 'redux/reducers/activeWorkStep/workstepActions';
import { HotShotMenu, HotSpotContainer } from './elements';

export type Props = {
  annotation: CogniteAnnotation;
};

export type SelectorProps = Props & {
  type?: string;
  select: Function;
};

export type TemplateSelectorProps = SelectorProps & {
  setType: Function;
};

export const TemplateSelector = ({
  annotation,
  type,
  setType,
  select,
}: TemplateSelectorProps) => {
  const { itemTypes, templates } = useActiveWorkStep();

  const {
    selectTemplate,
    getItemTypes,
    getTemplates,
  } = useActiveWorkstepActions();
  const { getItemByType, clearActiveHotSpot } = useHotSpot();
  const { deIsolationMode } = useWorkSteps();

  useEffect(() => {
    if (type) {
      getTemplates(type);
    }
  }, [getTemplates, type]);

  // Move out to more setup information
  useEffect(() => {
    getItemTypes();
  }, [getItemTypes]);

  if (!annotation) {
    return null;
  }

  const handleClick = (selectedAction: WorkStepAction) => {
    selectTemplate(selectedAction);
    const selectedItem = getItemByType(type);
    if (selectedItem) {
      select(selectedItem);
      clearActiveHotSpot();
    }
  };

  return (
    <HotSpotContainer key={annotation.id}>
      {!type && itemTypes && (
        <HotShotMenu
          data-testid={`hotSpot-assetType-selector-${annotation.id}`}
        >
          <Menu.Header>Select type</Menu.Header>
          {itemTypes.map((it: ItemType) => {
            return (
              <Menu.Item
                key={it.type}
                onClick={() => {
                  setType(it.type);
                }}
              >
                <span data-testid={`type-option-${it.type}`}>
                  {it.description}
                </span>
              </Menu.Item>
            );
          })}
        </HotShotMenu>
      )}
      {type && templates[type] && (
        <HotShotMenu data-testid={`hotSpot-template-selector-${annotation.id}`}>
          <Menu.Header>
            {deIsolationMode ? 'De-isolation' : 'Isolation'}
          </Menu.Header>
          {templates &&
            templates[type]
              ?.filter((action) =>
                deIsolationMode
                  ? action.modes.includes(Modes.deIsolation)
                  : action.modes.includes(Modes.isolation)
              )
              .map((template, index) => {
                if (template.items.length > 1) {
                  return (
                    <Menu.Submenu
                      key={`sub-action-${template.label}`}
                      appendTo="parent"
                      trigger="mouseenter"
                      content={
                        <Menu>
                          {template.items.map((action) => {
                            return (
                              <Menu.Item
                                key={`${action.id}`}
                                onClick={() => {
                                  handleClick(action);
                                }}
                              >
                                <span
                                  style={{ textAlign: 'left' }}
                                  data-testid={`template-option-${action.text}`}
                                >
                                  {action.text}
                                </span>
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      }
                    >
                      <span data-testid={`submenu-start-${template.label}`}>
                        {template.label}
                      </span>
                    </Menu.Submenu>
                  );
                }
                const [item] = template.items;
                return (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <span
                      style={{ textAlign: 'left' }}
                      data-testid={`template-option-${template.label}`}
                    >
                      {template.label}
                    </span>
                  </Menu.Item>
                );
              })}
        </HotShotMenu>
      )}
    </HotSpotContainer>
  );
};

export const RelativeRefSelector = ({
  annotation,
  type,
  select,
}: SelectorProps) => {
  const { referenceTypes } = useActiveWorkStep();
  const { getReferenceTypes } = useActiveWorkstepActions();
  const { getItemByType, clearActiveHotSpot } = useHotSpot();

  useEffect(() => {
    getReferenceTypes(type || 'asset');
  }, [getReferenceTypes, type]);

  return (
    <HotShotMenu data-testid={`hotSpot-relativRef-selector-${annotation.id}`}>
      {referenceTypes &&
        referenceTypes[type || 'asset']?.map((relation, index) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                const selectedItem = getItemByType(type);
                if (selectedItem) {
                  select(selectedItem, relation);
                  clearActiveHotSpot();
                }
              }}
            >
              <span data-testid={`relativeRef-option-${relation.text}`}>
                {relation.text}
              </span>
            </Menu.Item>
          );
        })}
    </HotShotMenu>
  );
};

export const LineSelector = ({ annotation, select }: SelectorProps) => {
  const { referenceTypes } = useActiveWorkStep();
  const { getReferenceTypes } = useActiveWorkstepActions();
  const { getItem, clearActiveHotSpot } = useHotSpot();

  useEffect(() => {
    getReferenceTypes('line');
  }, [getReferenceTypes]);

  return (
    <HotShotMenu data-testid={`hotSpot-line-selector-${annotation.id}`}>
      {referenceTypes.line?.map((option, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              const selectedItem = getItem();
              if (selectedItem) {
                select(selectedItem);
                clearActiveHotSpot();
              }
            }}
          >
            <span data-testid={`line-option-${option.text}`}>
              {option.text}
            </span>
          </Menu.Item>
        );
      })}
    </HotShotMenu>
  );
};

export const HotSpotMenu = ({ annotation }: Props) => {
  const {
    selectLine,
    selectRelativeRef,
    selectAsset,
    createAnnotationMode,
    selectionModeActiveWorkStep,
  } = useSelectingHotSpot();

  const { activeHotSpot, isActiveHotSpot } = useHotSpot();
  const [type, setType] = useState<string>(
    activeHotSpot.annotation?.data?.type
  );

  useEffect(() => {
    setType(activeHotSpot.annotation?.data?.type);
  }, [setType, activeHotSpot]);

  if (!isActiveHotSpot(annotation)) {
    return null;
  }

  if (createAnnotationMode) {
    if (activeHotSpot.annotation?.data?.type === 'line') {
      return (
        <LineSelector
          annotation={activeHotSpot.annotation as CogniteAnnotation}
          select={selectLine}
        />
      );
    }
    return (
      <RelativeRefSelector
        type={type}
        annotation={activeHotSpot.annotation as CogniteAnnotation}
        select={selectRelativeRef}
      />
    );
  }
  if (
    selectionModeActiveWorkStep === SelectionMode.Line &&
    activeHotSpot.annotation?.data?.type === 'line'
  ) {
    return (
      <LineSelector
        annotation={activeHotSpot.annotation as CogniteAnnotation}
        select={selectLine}
      />
    );
  }
  if (
    selectionModeActiveWorkStep === SelectionMode.RelativeRef &&
    activeHotSpot.annotation?.data?.type !== 'line'
  ) {
    return (
      <RelativeRefSelector
        type={type}
        annotation={activeHotSpot.annotation as CogniteAnnotation}
        select={selectRelativeRef}
      />
    );
  }
  if (selectionModeActiveWorkStep === SelectionMode.Item) {
    return (
      <TemplateSelector
        type={type}
        setType={setType}
        annotation={activeHotSpot.annotation as CogniteAnnotation}
        select={selectAsset}
      />
    );
  }

  return null;
};
