import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from '@cognite/annotations-experimental';
import isMatch from 'lodash/isMatch';

const distance = (refBoundingBox: any, annotationBoundingBox: any) => {
  const dx = Math.max(
    refBoundingBox.xMin - annotationBoundingBox.xMax,
    annotationBoundingBox.xMin - refBoundingBox.xMax,
    0
  );
  const dy = Math.max(
    refBoundingBox.yMin - annotationBoundingBox.yMax,
    annotationBoundingBox.yMin - refBoundingBox.yMax,
    0
  );
  return Math.sqrt(dx * dx + dy * dy);
};

export const findClosest = (
  annotation?: CogniteAnnotation,
  annotations?: Array<CogniteAnnotation | CogniteAnnotationSpec>
) => {
  if (!annotation || !annotations) return undefined;
  // eslint-disable-next-line
  let closest: any = undefined;
  let distanceNum: number;
  annotations.forEach((current) => {
    const dis = distance(annotation.region, current.region);

    if (!closest || dis < distanceNum) {
      closest = current as CogniteAnnotation;
      distanceNum = dis;
    }
  });
  return closest;
};

export const findWithSameRegion = (
  annotation?: CogniteAnnotation,
  annotations?: Array<CogniteAnnotation | CogniteAnnotationSpec>
) => {
  if (!annotation || !annotations) return undefined;
  // eslint-disable-next-line
  let closest: any = undefined;
  annotations.forEach((current) => {
    const dis = isMatch(annotation.region, current.region);

    if (dis) {
      closest = current as CogniteAnnotation;
    }
  });
  return closest;
};
