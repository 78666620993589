import styled from 'styled-components/macro';
import { Button, Colors, TopBar } from '@cognite/cogs.js';
import { ReactComponent as Icon } from './IsoPlanIcon.svg';

export const CogsTopBar = styled(TopBar)`
  background-color: ${Colors.white.hex()};
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06),
    0px 1.12694px 3.38082px rgba(0, 0, 0, 0.0624048);
`;

export const IsoPlanIcon = styled(Icon)`
  padding: 0px 10px;
  box-sizing: content-box;
  &:hover {
    cursor: pointer;
  }
`;

export const DanglerLinkButton = styled(Button)`
  &:disabled: {
    color: red;
  }
`;

export const NonClickableAsset = styled.span`
  color: var(--cogs-greyscale-grey10);
  font-weight: 500;
`;

export const TitleText = styled.div`
  line-height: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin: 0px 8px;
`;
