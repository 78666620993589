import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'redux/store';
import {
  ConfirmDraftWorkStepDTO,
  Phase,
  PhaseType,
  PnId,
  StepExecutonStatus,
  WorkStep,
  WorkstepPairs,
} from 'types';
import { FileInfo } from '@cognite/sdk';
import { toast } from '@cognite/cogs.js';
import { CogniteAnnotation } from '@cognite/annotations-experimental';
import { TagNumberPosition } from 'pages/Project/components/WorkstepsTags/types';
import { filesResourceService } from 'resources';
import { useActiveWorkStep } from '../activeWorkStep/hooks';
import workStepSlice, { WorkStepState } from './workstepsReducer';
import { SelectionMode } from '../activeWorkStep';
import { actions } from '../files';
import { useAnnotations } from '../annotations';
import projectSlice, { fetchWorkorders, fetchPassValves } from '../project';
import { useActiveWorkstepActions } from '../activeWorkStep/workstepActions';
import {
  getActivePhase,
  countAllWorkStepsPhaseExecuted as countWsExecuted,
} from '../../../pages/EditProcedure/components/ExecuteProcedure/ExecuteProcedure.utils';
import { workstepsService } from '../../../services';
import { AuthState } from '../auth';

export const useWorkSteps = () => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const { active, setActive, setOverlayClicked } = useActiveWorkStep();

  const {
    setEditable,
    setSelectionMode,
    setActiveAfterSave,
    cancelActive,
  } = useActiveWorkstepActions();

  const {
    updateWithUserCreatedAnnotation,
    createUserAnnotation,
    cancelNewAnnotation,
    removeOldAnnotationFromState,
  } = useAnnotations();

  const slice = useSelector<RootState, WorkStepState>((state) => {
    return state.workSteps;
  });

  const { user } = useSelector<RootState, AuthState>((state) => state.auth);

  const countAllWorkSteps = (): number => {
    const allPositions = slice.phases
      .map((phase) =>
        phase.workSteps
          .filter((workstep) => !workstep.isDraft)
          .map((workstep) => workstep.position)
      )
      .flat();

    return allPositions.length;
  };

  const countAllWorkStepsPhase = (phaseId: string): number => {
    const allPositions = slice.phases
      .find((phase) => phase.id === phaseId)
      ?.workSteps.filter((workstep) => !workstep.isDraft);
    return allPositions?.length || 0;
  };

  const countAllWorkStepsPhaseExecuted = (phaseId: string): number => {
    const searchPhase = slice.phases.find((phase) => phase.id === phaseId);
    if (!searchPhase) {
      return 0;
    }
    return countWsExecuted(searchPhase);
  };

  const countAllWorkStepsPhaseChecked = (phaseId: string): number => {
    const allPositions = slice.phases
      .find((phase) => phase.id === phaseId)
      ?.workSteps.filter((workstep) => !workstep.isDraft)
      .filter(
        (workstep) =>
          workstep.execution?.getStatus() === StepExecutonStatus.Checked
      );
    return allPositions?.length || 0;
  };

  const countIsolationWorkSteps = (shouldCountDraftSteps: boolean): number => {
    const allIsolationPhases = slice.phases.filter(
      (phase) => phase.phaseType === 'isolation'
    );
    const allIsolationWorksteps = allIsolationPhases
      .map((phase) =>
        phase.workSteps
          .filter(
            (ws) =>
              !(
                shouldCountDraftSteps &&
                (ws.isDraft || ws.workStepType === 'missing')
              )
          )
          .map((workstep) => workstep.position)
      )
      .flat();
    return allIsolationWorksteps.length;
  };

  const countDeIsolationWorkSteps = (
    shouldCountDraftSteps: boolean
  ): number => {
    const allDeIsolationPhases = slice.phases.filter(
      (phase) => phase.phaseType === 'deisolation'
    );
    const allDeIsolationWorksteps = allDeIsolationPhases
      .map((phase) =>
        phase.workSteps
          .filter(
            (ws) =>
              !(
                shouldCountDraftSteps &&
                (ws.isDraft || ws.workStepType === 'missing')
              )
          )
          .map((workstep) => workstep.position)
      )
      .flat();
    return allDeIsolationWorksteps.length;
  };

  const countDraftWorkSteps = (skipIgoredSteps: boolean): number => {
    const predicate = (ws: WorkStep) =>
      (ws.isDraft || ws.workStepType === 'missing') &&
      !(skipIgoredSteps && ws.isIgnored);
    return filterWorkstepsWithPredicate(predicate).length;
  };

  const getWorkstepPairs = useCallback((ws: WorkStep[]): WorkstepPairs[] => {
    const pairs: WorkstepPairs[] = [];
    ws.forEach((workStep) => {
      const foundIndex = pairs.findIndex(
        (x) => x.id === workStep.linkedWorkStepId
      );
      const correctedPosition =
        workStep.isDraft && workStep.displayPosition
          ? workStep.displayPosition
          : workStep.position;
      if (foundIndex === -1) {
        pairs.push({
          id: workStep.id,
          position: correctedPosition,
          color: workStep.stepAction?.color || 'white',
        });
      } else {
        pairs[foundIndex] = {
          ...pairs[foundIndex],
          deisolationId: workStep.id,
          deisolationPosition: correctedPosition,
          deisolationColor: workStep.stepAction?.color || 'white',
        };
      }
    });
    return pairs;
  }, []);

  const getFirstPnidId = (): number | undefined => {
    if (slice.phases.length !== 0) {
      let position = 0;
      const firstWorkStep = getWorkstepWithPredicate((ws) => {
        // eslint-disable-next-line
        position++;
        return !!(
          ws.position.toString() === position.toString() &&
          ws.stepItem &&
          ws.stepItem.annotation
        );
      });

      if (firstWorkStep) {
        return firstWorkStep.stepItem?.annotation?.annotatedResourceId;
      }
    }
    return undefined;
  };

  const getActivePhaseId = (): string | undefined => {
    const { phases } = slice;
    const result = getActivePhase(phases);
    return result?.id || undefined;
  };

  const revisedWorkSteps = () => {
    return slice.phases
      .map((phase: Phase) =>
        phase.workSteps.filter(
          (workStep: WorkStep) => !!workStep.review?.comments?.length
        )
      )
      .flat();
  };

  const workStepsContainingAnnotation = useCallback(
    (annotationId: string, shouldFilterDraftWorkSteps: boolean) => {
      const result = slice.phases
        .map((phase) => {
          return phase.workSteps.filter((workStep) => {
            if (
              shouldFilterDraftWorkSteps &&
              (workStep.isDraft || workStep.workStepType === 'missing')
            ) {
              return false;
            }
            return workStep?.stepItem?.annotation?.id === +annotationId;
          });
        })
        .flat();
      return result;
    },
    [slice.phases]
  );

  const getNextPosition = (phases: Phase[]): number => {
    const positions = phases
      .map((phase) => phase.workSteps.map((workStep) => workStep.position))
      .flat()
      .sort((a: number, b: number) => +a - +b);
    if (positions.length > 0) {
      return positions[positions.length - 1] + 1;
    }
    return 1;
  };

  const getWorkstepWithPredicate = (
    predicate: (workStep: WorkStep) => boolean
  ): WorkStep | undefined => {
    return slice.phases
      .map((phase) => phase.workSteps)
      .reduce((a, b) => a.concat(b), [])
      .find((workStep) => predicate(workStep));
  };

  const filterWorkstepsWithPredicate = (
    predicate: (workStep: WorkStep) => boolean
  ): WorkStep[] => {
    return slice.phases
      .map((phase) => phase.workSteps)
      .reduce((a, b) => a.concat(b), [])
      .filter((workStep) => predicate(workStep));
  };

  const setWorkStepAsActive = useCallback(
    (workStep: WorkStep) => {
      setActive(workStep);
    },
    [setActive]
  );

  const setWorkStepAsNeedsSaving = useCallback(() => {
    setOverlayClicked();
  }, [setOverlayClicked]);

  const fetchFileForActiveWorkstep = useCallback(
    (workStep: WorkStep) => {
      const fileId = workStep.stepItem?.annotation?.annotatedResourceId;
      if (fileId) {
        dispatch(actions.connectFile(fileId));
        dispatch(actions.setActiveFile(fileId));
      }
    },
    [dispatch]
  );

  const selectWorkStep = useCallback(
    (workStep: WorkStep) => {
      setWorkStepAsActive(workStep);
    },
    [setWorkStepAsActive]
  );

  const newWorkStep = useCallback(
    ({ phase, position, positionWithinPhase, phasesType }) => {
      const workStep = {
        id: uuidv4(),
        position,
      };
      cancelNewAnnotation();
      dispatch(
        workStepSlice.actions.addWorkStep({
          workStep,
          phase: phase || {
            id: uuidv4(),
            name: 'Phase',
            position: 0,
            phaseType: phasesType,
          },
          positionWithinPhase,
        })
      );
      setEditable(workStep);
      setSelectionMode(SelectionMode.Item);
    },
    [dispatch, setEditable, setSelectionMode, cancelNewAnnotation]
  );

  const addNewPhase = useCallback(
    (name: string, phaseType: PhaseType) => {
      dispatch(
        workStepSlice.actions.createPhase({
          name,
          phaseType,
        })
      );
    },
    [dispatch]
  );

  const editPhaseName = useCallback(
    (phaseId: string, phaseName: string) => {
      dispatch(
        workStepSlice.actions.editPhaseName({
          phaseId,
          phaseName,
        })
      );
    },
    [dispatch]
  );

  const approvalPhase = useCallback(
    (phaseId: string, email: string) => {
      dispatch(
        workStepSlice.actions.approvalPhase({
          phaseId,
          email,
        })
      );
    },
    [dispatch]
  );

  const unApprovalPhase = useCallback(
    (phaseId: string, email: string) => {
      dispatch(
        workStepSlice.actions.unApprovalPhase({
          phaseId,
          email,
        })
      );
    },
    [dispatch]
  );

  const deleteEmptyPhase = useCallback(
    (phaseId: string, linkedPhaseId?: string) => {
      dispatch(
        workStepSlice.actions.deleteEmptyPhase({
          phaseId,
          linkedPhaseId,
        })
      );
    },
    [dispatch]
  );

  const editWorkStep = useCallback(
    (workStep: WorkStep) => {
      setEditable(workStep);
      cancelNewAnnotation();
    },
    [setEditable, cancelNewAnnotation]
  );

  const setWorkstepAsDone = (
    workStep: WorkStep,
    phaseId: string,
    doneBy: string
  ) => {
    dispatch(
      workStepSlice.actions.setWorkstepAsDone({
        workStep,
        doneBy,
        phaseId,
        doneTime: new Date().getTime(),
      })
    );
  };

  const batchUpdateWorkstepsTagNumbers = (tagNumbers: TagNumberPosition[]) => {
    dispatch(
      workStepSlice.actions.batchUpdateWorkstepsTagNumbers({
        tagNumbers,
      })
    );
  };

  const undoWorkstep = (workStep: WorkStep, phaseId: string) => {
    dispatch(
      workStepSlice.actions.undoWorkstep({
        workStep,
        phaseId,
      })
    );
  };

  const undoCheckedWorkstep = (workStep: WorkStep, phaseId: string) => {
    dispatch(
      workStepSlice.actions.undoCheckedWorkStep({
        workStep,
        phaseId,
      })
    );
  };

  const setWorkstepAsChecked = (
    workStep: WorkStep,
    phaseId: string,
    checkedBy: string
  ) => {
    dispatch(
      workStepSlice.actions.setWorkstepAsChecked({
        workStep,
        checkedBy,
        checkedTime: new Date().getTime(),
        phaseId,
      })
    );
  };
  const setWorkstepStoppedExecution = (workStep: WorkStep, phaseId: string) => {
    dispatch(
      workStepSlice.actions.setWorkstepStoppedExecution({
        workStep,
        phaseId,
        executionStoppedTime: new Date().getTime(),
      })
    );
  };

  const addReviewComment = async (
    updatedWorkStep: WorkStep,
    phaseId: string
  ) => {
    dispatch(
      workStepSlice.actions.saveWorkStep({
        workStep: updatedWorkStep,
        phaseId,
      })
    );
    return true;
  };

  const resolveReviewComments = async (
    updatedWorkStep: WorkStep,
    phaseId: string
  ) => {
    dispatch(
      workStepSlice.actions.saveWorkStep({
        workStep: updatedWorkStep,
        phaseId,
      })
    );
    return true;
  };

  const saveWorkStep = async (
    workStep: WorkStep,
    phaseId: string,
    approve?: boolean
  ) => {
    try {
      setSaving(true);
      const {
        annotation: updatedRelativRef,
        forRemoval: relativeRefForRemoval,
      } = await createUserAnnotation(
        workStep?.stepItem?.relativeRef?.annotation,
        {
          ...workStep?.stepItem?.relativeRef?.annotation,
          linkedResourceExternalId:
            workStep.stepItem?.relativeRef?.asset?.externalId,
        } as CogniteAnnotation
      );

      const {
        annotation: updatedLine,
        forRemoval: lineForRemoval,
      } = await createUserAnnotation(workStep?.stepItem?.line?.annotation, {
        ...workStep?.stepItem?.line?.annotation,
        linkedResourceExternalId: workStep.stepItem?.line?.asset?.externalId,
      } as CogniteAnnotation);

      const {
        annotation: updatedAnnotation,
        forRemoval: annotationForRemoval,
      } = await createUserAnnotation(workStep?.stepItem?.annotation, {
        ...workStep?.stepItem?.annotation,
        data: {
          ...workStep?.stepItem?.annotation?.data,
          indirectExternalId: updatedRelativRef?.linkedResourceExternalId,
          lineExternalId: updatedLine?.linkedResourceExternalId,
          indirectRelation: workStep.stepItem?.indirectReference?.text,
          type: workStep.stepItem?.type?.type,
          subDetail: workStep.safeguarding ? workStep.safeguarding : undefined,
        },
        linkedResourceExternalId: workStep.stepItem?.asset?.externalId,
      } as CogniteAnnotation);

      let updatedWorkStep: WorkStep = {
        ...workStep,
        stepItem: {
          ...workStep.stepItem,
          annotation: updatedAnnotation,
          relativeRef: workStep.stepItem?.relativeRef
            ? {
                ...workStep.stepItem?.relativeRef,
                annotation: updatedRelativRef,
              }
            : undefined,
          line: workStep.stepItem?.line
            ? {
                ...workStep.stepItem?.line,
                annotation: updatedLine,
              }
            : undefined,
        },
      };

      if (workStep.error && workStep.error.needMigration) {
        const oldRelativeRefAnnotationId = removeOldAnnotationFromState(
          updatedRelativRef as CogniteAnnotation
        );
        migrateOtherWorkstepsWithSameAnnotation(
          updatedWorkStep,
          oldRelativeRefAnnotationId,
          updatedRelativRef
        );
        const oldLineAnnotationId = removeOldAnnotationFromState(
          updatedLine as CogniteAnnotation
        );
        migrateOtherWorkstepsWithSameAnnotation(
          updatedWorkStep,
          oldLineAnnotationId,
          updatedLine
        );
        const oldAnnotationId = removeOldAnnotationFromState(
          updatedAnnotation as CogniteAnnotation
        );
        migrateOtherWorkstepsWithSameAnnotation(
          updatedWorkStep,
          oldAnnotationId,
          updatedAnnotation
        );
      }

      /*
        If the user approves the workstep, all sources should be set to the current user that gives the approval
        Afterwards delete the remaining error
        e.g. Approve new P&ID revision, Approve asset detail change
       */
      if (approve && workStep.error) {
        updatedWorkStep = workstepsService.updateWorkStepAnnotationsProperty(
          updatedWorkStep,
          'source',
          user
        );

        delete updatedWorkStep.error;
      }

      cancelActive();
      setActiveAfterSave(updatedWorkStep);
      updateWithUserCreatedAnnotation({
        userAnnotation: updatedRelativRef,
        pipeLineAnnotation: relativeRefForRemoval,
      });
      updateWithUserCreatedAnnotation({
        userAnnotation: updatedLine,
        pipeLineAnnotation: lineForRemoval,
      });
      updateWithUserCreatedAnnotation({
        userAnnotation: updatedAnnotation,
        pipeLineAnnotation: annotationForRemoval,
      });

      dispatch(
        workStepSlice.actions.saveWorkStep({
          workStep: updatedWorkStep,
          phaseId,
        })
      );
    } catch (error) {
      toast.error(<div>We weren’t able to update the hotspot. Try again.</div>);
    } finally {
      setSaving(false);
    }
  };

  const migrateOtherWorkstepsWithSameAnnotation = useCallback(
    (
      workStep: WorkStep,
      oldAnnotationId?: number,
      newAnnotation?: CogniteAnnotation
    ) => {
      if (!oldAnnotationId || !newAnnotation || !newAnnotation.id) {
        return;
      }

      dispatch(
        workStepSlice.actions.migrateWorkStepsAnnotation({
          workStep,
          oldAnnotationId,
          newAnnotation,
        })
      );
    },
    [dispatch]
  );

  const deleteWorkStep = useCallback(
    ({ workStepId, phaseId }: { workStepId: string; phaseId: string }) => {
      dispatch(
        workStepSlice.actions.removeWorkStep({
          workStepId,
          phaseId,
        })
      );
      cancelActive();
    },
    [dispatch, cancelActive]
  );

  const moveWorkStep = useCallback(
    ({ workStep, toPhase, fromPhase, oldIndex, newIndex }) => {
      dispatch(
        workStepSlice.actions.moveWorkStep({
          workStep,
          toPhase,
          fromPhase,
          oldIndex,
          newIndex,
        })
      );
    },
    [dispatch]
  );

  const setDeIsolationMode = (deIsolationMode: boolean) => {
    dispatch(workStepSlice.actions.setDeIsolationMode({ deIsolationMode }));
  };

  const findWorkStepsWithAnnotation = useCallback(
    (annotation: CogniteAnnotation): WorkStep[] => {
      return slice.phases
        .map((phase) =>
          phase.workSteps.filter((workStep) => {
            return workStep.stepItem?.annotation?.id === annotation.id;
          })
        )
        .flat();
    },
    [slice.phases]
  );

  const updateAnnotationOfWorkStepsInvalid = useCallback(
    (newAnnotation: CogniteAnnotation, oldAnnotation: CogniteAnnotation) => {
      dispatch(
        workStepSlice.actions.updateAnnotationInvalid({
          newAnnotation,
          oldAnnotation,
        })
      );
    },
    [dispatch]
  );

  const fetchEventsBasedOnWorkSteps = useCallback(() => {
    const allPositions = slice.phases
      .map((phase) =>
        phase.workSteps.reduce((agr: number[], workstep) => {
          if (workstep.stepItem?.annotation?.annotatedResourceId) {
            return [...agr, workstep.stepItem?.annotation?.annotatedResourceId];
          }
          return [...agr];
        }, [] as number[])
      )
      .flat();

    const allPositionsUnique = [...new Set(allPositions)];

    if (!allPositions.length) {
      // just set as empty and loaded
      dispatch(fetchWorkorders([]));
      dispatch(fetchPassValves([]));
    }

    if (allPositionsUnique.length) {
      filesResourceService
        .fetchFilesByIds(allPositionsUnique)
        .then((responsePnIDs) => {
          const allPnidTypes: PnId[] = responsePnIDs.length
            ? responsePnIDs.map((singlePnid) => {
                return {
                  id: String(singlePnid.id),
                  nameInCdf: singlePnid.name,
                  externalId: singlePnid.externalId,
                  version: singlePnid.metadata?.['REVISION'],
                  filename: singlePnid.metadata?.['FILE_NAME'],
                  description: singlePnid.metadata?.['DOCUMENT_TITLE_2'],
                  extendedDescription:
                    singlePnid.metadata?.['DOCUMENT_TITLE_3'],
                };
              })
            : ([] as PnId[]);

          dispatch(projectSlice.actions.addConnectedPnids(allPnidTypes));

          const allIds = responsePnIDs.length
            ? responsePnIDs.reduce((arr: number[], singlePnID: FileInfo) => {
                if (singlePnID.assetIds?.length) {
                  return [...arr, ...singlePnID.assetIds];
                }
                return [...arr];
              }, [] as number[])
            : ([] as number[]);

          const uniqueIds = [...new Set(allIds)];
          if (uniqueIds.length) {
            dispatch(fetchWorkorders(uniqueIds));
            dispatch(fetchPassValves(uniqueIds));
          } else {
            dispatch(fetchWorkorders([]));
            dispatch(fetchPassValves([]));
          }
        });
    }
  }, [dispatch, slice.phases]);

  const createDeIsolationWorkSteps = useCallback(() => {
    dispatch(workStepSlice.actions.createDeIsolation());
  }, [dispatch]);

  const confirmDraftWorkStep = useCallback(
    (dto: ConfirmDraftWorkStepDTO) => {
      dispatch(workStepSlice.actions.confirmDraftWorkStep(dto));
    },
    [dispatch]
  );
  const previewDraftWorkStep = useCallback(
    (dto: ConfirmDraftWorkStepDTO) => {
      dispatch(workStepSlice.actions.previewDraftWorkStep(dto));
    },
    [dispatch]
  );
  const ignoreDraftWorkStep = useCallback(
    (dto: ConfirmDraftWorkStepDTO) => {
      dispatch(workStepSlice.actions.ignoreDraftWorkStep(dto));
    },
    [dispatch]
  );
  const setWorkStepAnnotationCustomOffset = useCallback(
    (annotationId: number, offsetX: number, offsetY: number) => {
      dispatch(
        workStepSlice.actions.setAnnotationCustomOffset({
          annotationId,
          offsetX,
          offsetY,
        })
      );
    },
    [dispatch]
  );

  const stepsNeedRevision = useCallback(
    () =>
      slice.phases
        ?.map((phase) =>
          phase.workSteps.filter(
            (workstep) =>
              workstep.review ||
              workstep.error?.comment === 'This step needs revision'
          )
        )
        .flat(),
    [slice.phases]
  );

  return {
    ...slice,
    countAllWorkSteps,
    countIsolationWorkSteps,
    countDeIsolationWorkSteps,
    countAllWorkStepsPhase,
    countAllWorkStepsPhaseExecuted,
    countAllWorkStepsPhaseChecked,
    getWorkstepPairs,
    workStepsContainingAnnotation,
    updateAnnotationOfWorkStepsInvalid,
    findWorkStepsWithAnnotation,
    setDeIsolationMode,
    getNextPosition,
    getActivePhaseId,
    selectWorkStep,
    addReviewComment,
    resolveReviewComments,
    newWorkStep,
    addNewPhase,
    editPhaseName,
    deleteEmptyPhase,
    editWorkStep,
    saveWorkStep,
    deleteWorkStep,
    moveWorkStep,
    fetchEventsBasedOnWorkSteps,
    revisedWorkSteps,
    setWorkstepAsDone,
    setWorkstepAsChecked,
    setWorkstepStoppedExecution,
    undoWorkstep,
    approvalPhase,
    unApprovalPhase,
    getWorkstepWithPredicate,
    filterWorkstepsWithPredicate,
    batchUpdateWorkstepsTagNumbers,
    setWorkStepAsActive,
    setWorkStepAsNeedsSaving,
    fetchFileForActiveWorkstep,
    getFirstPnidId,
    createDeIsolationWorkSteps,
    confirmDraftWorkStep,
    previewDraftWorkStep,
    cancelActive,
    countDraftWorkSteps,
    ignoreDraftWorkStep,
    setWorkStepAnnotationCustomOffset,
    saving,
    active,
    stepsNeedRevision,
    undoCheckedWorkstep,
  };
};
