import React from 'react';
import {
  ActiveWorkStep,
  ConfirmDraftWorkStepDTO,
  Phase,
  ProjectStatus,
  WorkStep,
} from 'types';
import { Button, Flex } from '@cognite/cogs.js';
import { DeleteProps, WorkStepItem } from '.';
import { MissingWorkStepItem } from './MissingWorkStepItem';
import { MultiChoiceDraftWorkStepItem } from './MultiChoiceDraftWorkStepItem';

export interface WorkStepTypeSelectorProps {
  user: string;
  active: ActiveWorkStep;
  workStep: WorkStep;
  phase: Phase;
  saving: boolean;
  isDoneAndChecked: boolean;
  projectStatus?: ProjectStatus;
  onWorkStepSelected: (workStep: WorkStep) => void;
  onEditWorkStep: (workStep: WorkStep) => void;
  onDeleteWorkstep: ({ workStepId, phaseId }: DeleteProps) => void;
  onSaveWorkstep: (workStep: WorkStep, phaseId: string) => void;
  onConfirmDraftWorkStep: (dto: ConfirmDraftWorkStepDTO) => void;
  onPreviewDraftWorkStep: (dto: ConfirmDraftWorkStepDTO) => void;
  onIgnoreDraftWorkStep: (dto: ConfirmDraftWorkStepDTO) => void;
  viewOnly: boolean;
}

export const WorkStepTypeSelector = ({
  user,
  active,
  workStep,
  phase,
  saving,
  isDoneAndChecked,
  projectStatus,
  onWorkStepSelected,
  onEditWorkStep,
  onDeleteWorkstep,
  onSaveWorkstep,
  onConfirmDraftWorkStep,
  onPreviewDraftWorkStep,
  onIgnoreDraftWorkStep,
  viewOnly,
}: WorkStepTypeSelectorProps) => {
  const isWorkstepSelected = active.workStep?.id === workStep.id;
  const isWorkstepEditable = isWorkstepSelected ? active.editable : false;

  const renderIgnoreButton = (
    <Button
      type="ghost-danger"
      variant="default"
      size="small"
      className="cogs-btn-tiny--padding"
      data-testid={`ignore-missing-workstep-btn-${workStep.id}`}
      onClick={() =>
        onIgnoreDraftWorkStep({
          phaseId: phase.id,
          workStep,
          workStepActionSlug: '',
        })
      }
    >
      Ignore
    </Button>
  );

  const workStepItem = (
    <WorkStepItem
      user={user}
      active={active}
      onSelectWorkstep={onWorkStepSelected}
      onEditWorkstep={onEditWorkStep}
      onDeleteWorkstep={onDeleteWorkstep}
      onSaveWorkstep={onSaveWorkstep}
      workStep={
        active.workStep?.id === workStep.id && active.editable
          ? active.workStep
          : workStep
      }
      editable={isWorkstepEditable}
      isSaving={saving}
      projectStatus={projectStatus}
      phaseId={phase.id}
      isDoneAndChecked={isDoneAndChecked}
      viewOnly={viewOnly}
    >
      {workStep.isDraft &&
        isWorkstepSelected &&
        projectStatus === 'compilation' &&
        !viewOnly &&
        !isWorkstepEditable && (
          <div
            data-testid={`confirm-draft-workstep-${workStep.id}`}
            style={{
              background: 'var(--cogs-midblue-7)',
              padding: '4px 18px 15px 24px',
              overflow: 'hidden',
              display: 'block',
            }}
          >
            <div>Is this right?</div>
            <Flex direction="row-reverse" gap={5} style={{ marginTop: '22px' }}>
              <Button
                type="primary"
                variant="default"
                size="small"
                className="cogs-btn-tiny--padding"
                data-testid={`confirm-draft-workstep-btn-${workStep.id}`}
                onClick={() =>
                  onConfirmDraftWorkStep({
                    phaseId: phase.id,
                    workStep,
                    workStepActionSlug: '',
                  })
                }
              >
                Confirm
              </Button>
              <Button
                type="tertiary"
                size="small"
                className="cogs-btn-tiny--padding"
                data-testid={`edit-draft-workstep-btn-${workStep.id}`}
                onClick={() => onEditWorkStep(workStep)}
              >
                Edit
              </Button>
              {renderIgnoreButton}
            </Flex>
          </div>
        )}
    </WorkStepItem>
  );

  const renderMultiChoiceStep = () => (
    <MultiChoiceDraftWorkStepItem
      phaseId={phase.id}
      active={active}
      workStep={workStep}
      onWorkStepSelected={onWorkStepSelected}
      onPreviewWorkStep={onPreviewDraftWorkStep}
      disabled={viewOnly}
    >
      {workStep.stepAction && workStepItem}
      {!viewOnly && !workStep.stepAction && (
        <Flex
          data-testid={`ignore-missing-workstep-${workStep.id}`}
          style={{
            padding: '10px',
            overflow: 'hidden',
          }}
          justifyContent="flex-end"
        >
          {renderIgnoreButton}
        </Flex>
      )}
    </MultiChoiceDraftWorkStepItem>
  );

  if (workStep.workStepType === 'missing') {
    const renderMissingWorkstepBody = () => {
      if (
        viewOnly ||
        !isWorkstepSelected ||
        (isWorkstepSelected && projectStatus !== 'compilation')
      ) {
        return null;
      }
      if (
        workStep.isDraft &&
        workStep.draftStepOptions &&
        !workStep.isIgnored
      ) {
        return renderMultiChoiceStep();
      }

      return (
        <Flex
          data-testid={`confirm-missing-workstep-${workStep.id}`}
          style={{
            padding: '10px',
            overflow: 'hidden',
          }}
          direction="row-reverse"
          gap={5}
        >
          <Button
            type="primary"
            variant="default"
            size="small"
            className="cogs-btn-tiny--padding"
            data-testid={`confirm-missing-workstep-btn-${workStep.id}`}
            onClick={() =>
              workStep.isIgnored && workStep.draftStepOptions
                ? onPreviewDraftWorkStep({
                    phaseId: phase.id,
                    workStep,
                    workStepActionSlug: '',
                  })
                : onConfirmDraftWorkStep({
                    phaseId: phase.id,
                    workStep,
                    workStepActionSlug: '',
                  })
            }
          >
            Generate
          </Button>
          {renderIgnoreButton}
        </Flex>
      );
    };

    return (
      <MissingWorkStepItem
        phaseId={phase.id}
        active={active}
        workStep={workStep}
        onWorkStepSelected={onWorkStepSelected}
      >
        {renderMissingWorkstepBody()}
      </MissingWorkStepItem>
    );
  }

  if (workStep.isDraft && workStep.draftStepOptions) {
    return renderMultiChoiceStep();
  }
  return workStepItem;
};
