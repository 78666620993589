import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from '@cognite/annotations-experimental';
import { Asset } from '@cognite/sdk';
import { TagNumberPosition } from 'pages/Project/components/WorkstepsTags/types';
import { Status } from 'redux/types';

export type Activity = {
  timestamp?: string;
  action: string;
  details: string;
};

export type UserActivity = {
  userId: string;
  activities: Activity[];
};

export type Project = {
  id?: string;
  name?: string;
  fileName: string;
  logFile?: UserActivity[];
  created?: number;
  lastUpdated?: number;
  executionStarted?: number;
  completed?: number;
  due?: string;
  compiler?: string;
  objectives?: string;
  method?: string;
  type?: string;
  status?: ProjectStatus;
  rejected?: {
    name: string;
    date: string;
  };
  resubmitted?: {
    name: string;
    date: string;
  };
  reviewed?: {
    name: string;
    date: string;
  };

  isolated?: {
    name: string;
    date: string;
  };

  executionStopped?: WorkLogDetails;
  resolved?: Resolved;
  asset?: {
    id: string;
    externalId?: string;
    name?: string;
    description?: string;
  };
  isDeleted?: boolean;
  rootAsset?: string;
  checkedValves?: Array<string>;
  pnIds?: Array<PnId>;
  pnidsTitles?: Array<string>;
  pnIdsDrawings?: KeyValuePair;
  certificateNumber?: string;

  executorsList?: string[];
  supervisorsList?: string[];
  needsMigration?: boolean;
  executionSubState?: ExecutionSubStates;
  isApprovedProcedure?: boolean;
  copyOfApprovedProcedure?: string;
  deletionRecord?: WorkLogDetails;
};

export interface WorkLogDetails {
  name: string;
  date: number;
}

// Need to figure out how to do the ordering and relationship between a workstep and a possible reverse workstep
// Ref https://github.com/cognitedata/isolation-planning/pull/14

export type Resolved = {
  compiler?: {
    name: string;
    date: number;
  };
  reviewer?: {
    name: string;
    date: number;
  };
};

export type WorkStepType = 'isolation' | 'deisolation' | 'missing';

export type WorkStep = {
  id: string;
  position: number;
  displayPosition?: number;
  comment?: string;
  stepAction?: WorkStepAction;
  stepItem?: StepItem;
  tag?: boolean;
  tagNumber?: string;
  review?: Review;
  execution?: StepExecutionDetails;
  safeguarding?: 'LO' | 'LC';
  error?: { comment: string; data: any; needMigration?: boolean };
  workStepType?: WorkStepType;
  linkedWorkStepId?: string;
  linkedWorkStepSlug?: string;
  isDraft?: boolean;
  isIgnored?: boolean;
  draftStepOptions?: WorkStepAction[];
};

export enum Modes {
  isolation = 'isolation',
  deIsolation = 'deIsolation',
}
export type Review = {
  resolved?: Resolved;
  comments?: Array<Comment>;
};

export enum StepExecutonStatus {
  NotStarted = 'Not Started',
  Executed = 'Executed',
  Checked = 'Checked',
}

export interface IStepExecutionDetails {
  doneBy?: string;
  doneTime?: number;
  checkedBy?: string;
  checkedTime?: number;
  executionStopped?: boolean;
  executionStoppedTime?: number;
}
export class StepExecutionDetails implements IStepExecutionDetails {
  doneBy?: string;
  doneTime?: number;
  checkedBy?: string;
  checkedTime?: number;
  executionStopped?: boolean = false;
  executionStoppedTime?: number;

  constructor(params: IStepExecutionDetails) {
    Object.assign(this, params);
  }

  static notStartedWithExecution(): StepExecutionDetails {
    return new StepExecutionDetails({
      checkedBy: '',
      checkedTime: undefined,
      doneBy: '',
      doneTime: undefined,
      executionStopped: false,
      executionStoppedTime: undefined,
    });
  }

  static uncheckedStepWithExecution(workStep: WorkStep): StepExecutionDetails {
    return new StepExecutionDetails({
      checkedBy: '',
      checkedTime: undefined,
      doneBy: workStep.execution?.doneBy,
      doneTime: workStep.execution?.doneTime,
      executionStopped: workStep.execution?.executionStopped,
      executionStoppedTime: workStep.execution?.executionStoppedTime,
    });
  }

  getStatus(): StepExecutonStatus {
    if (this.checkedBy && this.doneBy) {
      return StepExecutonStatus.Checked;
    }

    if (this.doneBy) {
      return StepExecutonStatus.Executed;
    }

    return StepExecutonStatus.NotStarted;
  }

  getWasExecutionStopped(): boolean {
    if (this.executionStopped && this.executionStoppedTime) {
      return true;
    }
    return false;
  }
}

export enum ExecutionSubStates {
  NotStarted = 'NotStarted',
  IsolationStarted = 'IsolationStarted',
  IsolationDone = 'IsolationDone',
  DeIsolationStarted = 'DeIsolationStarted',
  DeIsolationDone = 'DeIsolationDone',
}

export type Role = 'compiler' | 'reviewer';

export type Comment = {
  id: string;
  position?: number;
  commentContent: string;
  author: string;
  date: number;
  role: Role;
  reply?: Comment;
  metaData?: { [key: string]: string };
};

export type WorkStepAction = {
  id: string;
  text: string;
  description?: string;
  template?: string;
  color: string;
  slug: string;
  defaultTagged?: boolean;
};

export type ItemActions = {
  label: string;
  items: Array<WorkStepAction>;
  modes: Array<Modes>;
};

export type Reference = {
  id: string;
  text: string;
  description?: string;
};

export type StepItem = {
  asset?: Item;
  assetExternalId?: string;
  detail?: string;
  type?: ItemType;
  indirectReference?: Reference;
  relativeRef?: StepItem;
  annotation?: CogniteAnnotation;
  annotationId?: number;
  line?: StepItem;
};

export type Item = Pick<
  Asset,
  'id' | 'externalId' | 'name' | 'description' | 'metadata'
> & {};

export type ItemType = {
  type: string;
  description: string;
  template: string;
  options?: string[];
};

export type Phase = {
  id: string;
  name: string;
  position: number;
  phaseType: PhaseType;
  workSteps: Array<WorkStep>;
  deIsolation?: boolean;
  linkedPhaseId?: string;
  approvedBy?: string;
  approvedTime?: number;
  disapprovedBy?: string;
};

export interface PhasesAnnotationsMap {
  phases: Phase[];
  annotations: CogniteAnnotation[];
}

export interface ProcedurePhasesWorkstepsDTO {
  phases: Phase[];
  annotations: CogniteAnnotation[];
}

export type SizeAndClassType = {
  size: number;
  unit: 'mm' | 'inches';
  class: string;
};

// for now version is optional but we need to read it from somewhere
export type PnId = {
  id: string;
  nameInCdf?: string;
  externalId?: string;
  version?: string;
  filename?: string;
  description?: string;
  extendedDescription?: string;
};

export type PnIdHistory = {
  pnIds: Array<PnId>;
};

export type ProjectStatus =
  | 'compilation'
  | 'reviewReady'
  | 'review'
  | 'executionReady'
  | 'execution'
  | 'done'
  | 'deleted'
  | undefined;

// write better name
export type AssetMappingType = {
  assetId: number;
  assetFloc: string;
  assetName: string;
  assetDescription: string;
};

export type PreWorkItem = {
  id: string;
  itemContent: string;
  raisedDate: number;
  checked: boolean;
  removed: boolean;
};

export type ProcedureFileAttachment = {
  id: string;
  name: string;
  author: string;
  uploading: boolean;
  removed: boolean;
};

export type ProcedureApprovalFile = {
  id: string;
  name: string;
  author: string;
  uploading: boolean;
  removed: boolean;
  description: string;
  approvedBy: string;
};

export type WorkOrder = {
  id: string;
  floc: string;
  description: string;
  endDate: number;
  createdDate: number;
  createdDateInCdf: number;
  author: string;
};

export type PassValve = {
  id: string;
  floc: string;
  description: string;
  tag: string;
  url: string;
  raised: string;
};

export interface ProjectStateVM {
  project: Project;
  status: Status;
  newProject: boolean;
  appMode: AppMode;
  editMethod: boolean;
  error: string | undefined;
  workorders: WorkOrder[];
  woStatus: Status;
  passValves: PassValve[];
  pvStatus: Status;
  reviewComments: Comment[];
  commentStatus: Status;
  connectedPnids: PnId[];
  preWorkItems: PreWorkItem[];
  preWorkItemsStatus: Status;
  attachmentsList: ProcedureFileAttachment[];
  attachmentStatus: Status;
  tagNumbersUpdated: boolean;
  hasEmptyTagNumbers: boolean;
  hasEnteredProcessTagNumbers: boolean;
  hasEnteredElectricTagNumbers: boolean;
  isProcedureLoaded: boolean;
  approvalFilesStatus: Status;
  approvalFilesList: ProcedureApprovalFile[];
  executorsList: string[];
  supervisorsList: string[];
  isApprovedProcedure: boolean;
}

export interface WorkstepsStateVM {
  phases: Array<Phase>;
  workStepsLoaded: boolean;
  deIsolationMode: boolean;
  workStepsViewed: boolean;
  hasDeIsolationPhases: boolean;
  status: Status;
  error: string | undefined;
}

export type PhaseType = 'isolation' | 'deisolation';

// This should be moved into separate files and imported here
export interface BaseWorkstepsActionDTO {
  workStep: WorkStep;
  phaseId: string;
}
export interface MoveWorkstepActionDTO {
  workStep: WorkStep;
  toPhase: string;
  fromPhase: string;
  oldIndex: number;
  newIndex: number;
}

export interface RemoveWorkStepActionDTO {
  workStepId: string;
  phaseId: string;
}

export interface AddWorkStepActionDTO {
  workStep: WorkStep;
  phase: Phase;
  positionWithinPhase?: number;
}

export interface SetWorkstepAsDoneActionDTO extends BaseWorkstepsActionDTO {
  doneBy: string;
  doneTime: number;
}
export interface SetWorkstepAsCheckedActionDTO extends BaseWorkstepsActionDTO {
  checkedBy: string;
  checkedTime: number;
}
export interface SetWorkstepStoppedExecutionActionDTO
  extends BaseWorkstepsActionDTO {
  executionStoppedTime: number;
}

export interface ConfirmDraftWorkStepDTO {
  phaseId: string;
  workStep: WorkStep;
  workStepActionSlug: string;
}

export interface BatchUpdateWorkstepsTagNumbersActionDTO {
  tagNumbers: TagNumberPosition[];
}

export interface ActiveWorkStep {
  workStep?: WorkStep;
  editable: boolean;
  overlayClicked: boolean;
}

export interface CreateNewWorkStepDTO {
  phase: Phase;
  position: number;
  workStepIndex: number;
}

export interface FetchAnnotationsForFileDTO {
  fileId: number;
  projectAnnotations: CogniteAnnotation[];
  procedureNeedsMigration: boolean;
}

export interface FetchAnnotationsForProcedureDTO {
  fileAnnotations: FetchAnnotationsForFileDTO[];
}

export interface FetchDrawingsForProcedureDTO {
  fileIds: string[];
}

export interface FileAnnotationsDTO {
  fileId: number;
  annotations: CogniteAnnotation[];
}

export interface NewAnnotation {
  annotation?: CogniteAnnotationSpec;
  needRelativeRef?: boolean;
  oldAnnotation?: CogniteAnnotation;
}

export interface ColorFiles {
  id: number;
  color: string;
}
export interface FetchWorkStepsDTO {
  projectId: string | undefined;
  projectStatus?: ProjectStatus;
}

export interface KeyValuePair {
  [key: string]: any;
}

export interface DrawingDTO {
  content: string;
  externalId: string;
}
export type AppMode = 'view' | undefined;

export const customEvents = {
  expandPhase: 'expandPhase',
  workStepsHeightChanged: 'workStepsHeightChanged',
  deIsolationStepsCreated: 'deIsolationStepsCreated',
};

export type WorkstepPairs = {
  id: string;
  position: number;
  color: string;
  deisolationId?: string;
  deisolationPosition?: number;
  deisolationColor?: string;
};
