import React from 'react';
import { Colors } from '@cognite/cogs.js';
import LoadingBox from 'components/LoadingBox';
import SearchPageFooter from 'components/SearchPageFooter';
import CreateProcedureButton from 'components/SearchPageFooter/CreateProcedureButton';
import { Project } from 'types';
import { SearchResults } from './SearchResults';

export type SearchResultsProps = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  showCreateButton: boolean;
  procedures: Array<Project>;
  noResultsMessage: String;
};

export const SearchManager = ({
  isError,
  isSuccess,
  isLoading,
  showCreateButton,
  procedures,
  noResultsMessage,
}: SearchResultsProps) => {
  return (
    <>
      {isLoading && (
        <div style={{ textAlign: 'left' }} data-testid="searchLoader">
          <LoadingBox
            backgroundColor={Colors['greyscale-grey1'].hex()}
            textColor={Colors['greyscale-grey6'].hex()}
          />
        </div>
      )}
      {isError && (
        <span
          style={{ fontSize: '14px', textAlign: 'left' }}
          data-testid="searchNoAssets"
        >
          No assets were found for that search query, try another one
        </span>
      )}
      {isSuccess && (
        <>
          <SearchResults
            procedures={procedures}
            noResultsMessage={noResultsMessage}
          />
          {procedures.length ? (
            <SearchPageFooter showCreateButton={showCreateButton} />
          ) : null}
          {!procedures.length && showCreateButton ? (
            <div style={{ textAlign: 'left', marginTop: '10px' }}>
              <CreateProcedureButton />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default SearchManager;
