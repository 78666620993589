import styled from 'styled-components/macro';
import { Button, Colors, Loader as CogsLoader } from '@cognite/cogs.js';
import zIndex from 'utils/zindex';

export const ConnectedFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`;

export const ChooseStartingPnidWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: start;
  text-align: left;
  box-sizing: border-box;
`;

export const SmallFilePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  position: relative;
`;

export const DateCreatedRevisionEl = styled.small`
  position: absolute;
  right: 10px;
  top: 3px;
  background: white;
  padding: 5px;
  text-align: right;
`;

export type PreviewProps = {
  isActive?: boolean;
  color?: string;
};

export const Preview = styled.div.attrs((props: PreviewProps) => {
  const color = props.color ?? Colors['greyscale-grey5'].hex();
  return {
    style: {
      border: props.isActive ? `3px solid ${color}` : `1px solid ${color}`,
    },
  };
})`
  min-width: 270px;
  min-height: 190px;
  width: 299px;
  height: 190px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${Colors.white.hex()};
  overflow: hidden;
`;
export const FileName = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  margin: 13px 0 18px 0;
`;

export const DisableEvents = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export const FilePreviewWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.white.hex()};
  overflow: hidden;
  touch-action: none;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.MINIMUM};
  display: none;

  &.visible {
    display: block;
  }
`;

export const Loader = styled(CogsLoader)`
  position: relative;
  top: none;
  left: none;
`;

export const StepBoxesWrapper = styled.div.attrs<{ removePadding?: boolean }>(
  ({ removePadding }) => {
    return {
      className: 'z-2',
      style: {
        padding: removePadding ? '0px' : '4px',
      },
    };
  }
)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  float: left;
`;

export const StepBoxContainer = styled.div`
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  float: left;

  &.active {
    border: 3px solid var(--cogs-midblue-3);
  }
`;

export const StepBoxInnerDiv = styled.div<{
  color: string;
  fontColor: string;
}>`
  background-color: ${(props) => props.color};
  border-radius: 2px 0px 0px 2px;
  padding: 2px 7px;
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.fontColor};
  cursor: pointer;
  float: left;

  &:last-child {
    border-radius: 0px 2px 2px 0px;
  }
`;

export const SideDrawerTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const MiniWidgetsLabel = styled.b`
  padding: 11px 0;
  margin-right: 12px;
  color: var(--cogs-greyscale-grey6);
  text-transform: uppercase;
  font-weight: 600;
  line-height: 16px;
`;

export const FileRefreshButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: calc(24px + 108px + 20px); // Place next toolbar items
  bottom: 0;
  z-index: ${zIndex.MAXIMUM};
`;

export const FileQualityButtons = styled.div`
  position: absolute;
  top: 24px;
  right: calc(24px + 108px + 140px); // Place next toolbar items
  z-index: ${zIndex.MAXIMUM};

  > button {
    &:first-of-type {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:nth-of-type(2) {
      border-radius: 0 !important;
    }
    &:last-of-type {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
`;
