import { CogniteAnnotation } from '@cognite/annotations-experimental';
import { Asset } from '@cognite/sdk';
import { Phase, PhasesAnnotationsMap, StepItem } from 'types';
import { logger } from 'utils/logger';

export class PhaseWorkstepsDataMapper {
  serialize(data: Phase[]): PhasesAnnotationsMap {
    const dataToProcess = [...data];
    const phasesAnnotationsMap: PhasesAnnotationsMap = {
      phases: [],
      annotations: [],
    };

    for (let i = 0; i < dataToProcess.length; i++) {
      const phase = dataToProcess[i];
      if (!phase.phaseType) {
        phase.phaseType = 'isolation';
      }

      for (let j = 0; j < phase.workSteps.length; j++) {
        const workStep = phase.workSteps[j];

        // Only for UI purposes is used
        if (workStep.displayPosition) {
          delete workStep.displayPosition;
        }

        const workStepStepItem = this.serializeStepItem(
          workStep.stepItem as StepItem,
          phasesAnnotationsMap
        );
        phase.workSteps[j].stepItem = workStepStepItem;
      }

      phasesAnnotationsMap.phases.push(phase);
    }

    return phasesAnnotationsMap;
  }

  deserialize(
    phasesAnnotationsMap: PhasesAnnotationsMap,
    assets: Asset[],
    useDisplayPosition: boolean
  ): Phase[] {
    const phases: Phase[] = [];

    let stepPosition = 1;
    phasesAnnotationsMap.phases.forEach((phase) => {
      try {
        const newPhase = { ...phase };
        if (!newPhase.phaseType) {
          newPhase.phaseType = 'isolation';
        }
        const workSteps: any = [];

        newPhase.workSteps.forEach((workStep) => {
          const newWorkStep = { ...workStep };
          const workStepStepItem = this.deserializeStepItem(
            workStep.stepItem as StepItem,
            phasesAnnotationsMap,
            assets
          );
          newWorkStep.stepItem = workStepStepItem;

          if (
            useDisplayPosition &&
            !newWorkStep.isDraft &&
            newWorkStep.workStepType !== 'missing'
          ) {
            newWorkStep.displayPosition = stepPosition;
            // eslint-disable-next-line
            stepPosition++;
          }
          workSteps.push(newWorkStep);
        });

        newPhase.workSteps = workSteps;

        phases.push(newPhase);
      } catch (err) {
        logger(err);
      }
    });
    return phases;
  }

  private deserializeStepItem(
    stepItem: StepItem,
    phasesAnnotationsMap: PhasesAnnotationsMap,
    assets: Asset[]
  ): StepItem {
    let workStepStepItem = { ...stepItem } as any;
    const propertiesToSerialize = ['', 'relativeRef', 'line', 'asset'];
    propertiesToSerialize.forEach((property: any) => {
      let subStepItem = (property && workStepStepItem[property]
        ? (workStepStepItem[property] as StepItem)
        : workStepStepItem) as StepItem;

      if (subStepItem.assetExternalId) {
        subStepItem = this.deserializeAsset(subStepItem, assets);
      }

      if (subStepItem.annotationId) {
        subStepItem = this.deserializeAnnotation(
          subStepItem,
          phasesAnnotationsMap
        );
      }

      if (!property || !workStepStepItem[property]) {
        workStepStepItem = subStepItem;
      } else {
        workStepStepItem[property] = subStepItem;
      }
    });

    return workStepStepItem;
  }

  private serializeStepItem(
    stepItem: StepItem,
    phasesAnnotationsMap: PhasesAnnotationsMap
  ): StepItem {
    let workStepStepItem = { ...stepItem } as any;
    const propertiesToSerialize = ['', 'relativeRef', 'line', 'asset'];

    propertiesToSerialize.forEach((property) => {
      let subStepItem =
        property && workStepStepItem[property]
          ? (workStepStepItem[property] as StepItem)
          : workStepStepItem;
      if (subStepItem.asset && subStepItem.asset.externalId) {
        subStepItem = this.serializeAsset(subStepItem);
      }

      if (subStepItem.annotation && subStepItem.annotation.id) {
        subStepItem = this.serializeAnnotation(
          subStepItem,
          phasesAnnotationsMap
        );
      }
      if (!property || !workStepStepItem[property]) {
        workStepStepItem = subStepItem;
      } else {
        workStepStepItem[property] = subStepItem;
      }
    });
    return workStepStepItem;
  }

  private serializeAsset(stepItem: StepItem): StepItem {
    const newStepItem = { ...stepItem };
    newStepItem.assetExternalId = newStepItem.asset?.externalId;
    delete newStepItem.asset;
    return newStepItem;
  }

  private deserializeAsset(stepItem: StepItem, assets: Asset[]): StepItem {
    const newStepItem = { ...stepItem };
    const searchPredicate = (asset: Asset) =>
      asset.externalId === stepItem.assetExternalId;

    // eslint-disable-next-line
    if (assets.findIndex(searchPredicate) === -1) {
      logger(
        `[ASSET_EXTERNAL_ID]=${stepItem.assetExternalId} was not found in assets list`
      );
      return newStepItem;
    }

    newStepItem.asset = assets.find(searchPredicate);
    delete newStepItem.assetExternalId;
    return newStepItem;
  }

  private serializeAnnotation(
    stepItem: StepItem,
    phasesAnnotationsMap: PhasesAnnotationsMap
  ): StepItem {
    const newStepItem = { ...stepItem };
    const workStepItemAnnotation = stepItem.annotation as CogniteAnnotation;
    const annotationId = workStepItemAnnotation.id;

    if (
      // eslint-disable-next-line
      phasesAnnotationsMap.annotations.findIndex(
        (a) => a.id === annotationId
      ) === -1
    ) {
      phasesAnnotationsMap.annotations.push(workStepItemAnnotation);
    }
    newStepItem.annotationId = annotationId;
    delete newStepItem.annotation;
    return newStepItem;
  }

  private deserializeAnnotation(
    stepItem: StepItem,
    phasesAnnotationsMap: PhasesAnnotationsMap
  ): StepItem {
    const newStepItem = { ...stepItem };
    const searchPredicate = (annotation: CogniteAnnotation) =>
      annotation.id === newStepItem.annotationId;

    if (
      // eslint-disable-next-line
      phasesAnnotationsMap.annotations.findIndex(searchPredicate) === -1
    ) {
      logger(
        `[ANNOTATION_ID]=${newStepItem.annotationId} was not found in annotations list`
      );
      return newStepItem;
    }
    newStepItem.annotation = phasesAnnotationsMap.annotations.find(
      searchPredicate
    );
    delete newStepItem.annotationId;
    return newStepItem;
  }
}
