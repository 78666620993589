import styled from 'styled-components/macro';
import { Flex, Graphic, Button } from '@cognite/cogs.js';

export const ContentWrapper = styled(Flex).attrs({ direction: 'column' })`
  padding-left: 22px;
  padding-bottom: 22px;
  height: 100%;
  justify-content: space-between;
`;

export const LogoArea = styled(Flex).attrs({ direction: 'row' })`
  align-items: center;
  font-size: 13px;
  margin-top: 50px;
`;

export const CogniteLogo = styled(Graphic).attrs({ type: 'Cognite' })`
  margin-right: 12px;
`;

export const ProcedureButton = styled(Button).attrs({ type: 'tertiary' })`
  margin-left: 8px;
`;

export const Text = styled.span`
  font-size: 16px;
`;

export const VersionText = styled.div`
  font-size: 11px;
`;
