/* eslint-disable no-param-reassign */
import { modelFactory } from 'factories';
import { Phase, ProcedurePhasesWorkstepsDTO, ProjectStatus } from 'types';
import { getClient } from '../config/cognitesdk';

export class WorkstepsResourceService {
  async fetchPhasesAndWorksteps(
    procedureId: string,
    projectStatus: ProjectStatus,
    client = getClient()
  ): Promise<Phase[]> {
    const fileDownloadUrl = await client.files.getDownloadUrls([
      { externalId: procedureId },
    ]);

    if (!fileDownloadUrl?.length) {
      return Promise.resolve([]);
    }

    const workstepsContent = await client
      .get<{
        content: ProcedurePhasesWorkstepsDTO;
      }>(fileDownloadUrl[0].downloadUrl)
      .then((data) => {
        const dto = data.data.content;

        if (!dto) {
          return [];
        }

        // Support backwards compatibility
        // if is an array, then it must be the old format
        if (Array.isArray(dto)) {
          return modelFactory.mapWorkstepsExecution(dto as Phase[]);
        }

        if (!dto.phases.length) {
          return [];
        }

        const useDisplayPosition =
          projectStatus === 'execution' || projectStatus === 'executionReady';

        const assetsIds = this.getUniqueAssetsExternalIds(dto).map(
          (externalId) => {
            return { externalId };
          }
        );

        if (!assetsIds.length) {
          return modelFactory.mapToPhasesAndWorksteps(
            dto,
            [],
            useDisplayPosition
          );
        }

        return client.assets.retrieve(assetsIds).then((assets) => {
          return modelFactory.mapToPhasesAndWorksteps(
            dto,
            assets,
            useDisplayPosition
          );
        });
      });

    // when a new workstep is created by upgraded app it will break the IP of current deployed version
    // this filter is to filter out worksteps with new annotations.

    const workstepsContentFiltered = workstepsContent.filter((ws) => {
      ws.workSteps = ws.workSteps.filter((phase) => {
        return phase.stepItem?.annotation?.annotationType !== 'isoplan.IsoPlanAnnotation';
      })
      return ws.workSteps.length > 0;
    })

    return workstepsContentFiltered;
  }

  private getUniqueAssetsExternalIds(
    dto: ProcedurePhasesWorkstepsDTO
  ): string[] {
    const assetsExternalIds: string[] = [];

    dto.phases.forEach((phase) => {
      phase.workSteps.forEach((workStep) => {
        if (workStep.stepItem?.assetExternalId) {
          assetsExternalIds.push(workStep.stepItem?.assetExternalId);
        }
        if (
          workStep.stepItem?.relativeRef &&
          workStep.stepItem?.relativeRef?.assetExternalId
        ) {
          assetsExternalIds.push(
            workStep.stepItem?.relativeRef?.assetExternalId
          );
        }

        if (
          workStep.stepItem?.line &&
          workStep.stepItem?.line?.assetExternalId
        ) {
          assetsExternalIds.push(workStep.stepItem?.line?.assetExternalId);
        }
      });
    });

    return [...new Set(assetsExternalIds)];
  }

  async fetchAndCopyPhasesAndWorksteps(
    procedureId: string,
    projectStatus: ProjectStatus,
    client = getClient()
  ) {
    const response = await this.fetchPhasesAndWorksteps(
      procedureId,
      projectStatus,
      client
    );

    const responseWithoutExecution = response.map((eachPhase) => {
      delete eachPhase.approvedBy;
      delete eachPhase.approvedTime;
      const adjustedPhaseWorksteps = eachPhase.workSteps.map((eachWStep) => {
        delete eachWStep.execution;
        delete eachWStep.review;
        delete eachWStep.tagNumber;
        return eachWStep;
      });
      return { ...eachPhase, workSteps: adjustedPhaseWorksteps };
    });

    return responseWithoutExecution;
  }
}
