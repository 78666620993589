/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActiveWorkStep,
  ItemActions,
  ItemType,
  Reference,
  SizeAndClassType,
  StepItem,
  WorkStep,
  WorkStepAction,
} from 'types';

interface IActiveWorkStepState {
  active: ActiveWorkStep;
  selectionMode?: SelectionMode;
  templates: {
    [key: string]: Array<ItemActions>;
  };
  referenceTypes: { [key: string]: Array<Reference> };
  itemTypes: Array<ItemType>;
  classTypes: Array<string>;
}

export enum SelectionMode {
  Item = 'Item',
  RelativeRef = 'RelativeRef',
  Line = 'Line',
}

const initialState: IActiveWorkStepState = {
  active: {
    editable: false,
    overlayClicked: false,
  },
  templates: {},
  referenceTypes: {},
  itemTypes: [],
  classTypes: [],
};

const activeWorkStepSlice = createSlice({
  name: 'activeWorkStep',
  initialState,
  reducers: {
    setEditable: (state, action: PayloadAction<{ workStep: WorkStep }>) => {
      state.active.workStep = action.payload.workStep;
      state.active.editable = true;
      state.active.overlayClicked = false;
    },
    setNonEditable: (state) => {
      state.active.editable = false;
      state.active.overlayClicked = false;
    },
    setActive: (state, action: PayloadAction<{ workStep: WorkStep }>) => {
      if (!state.active.editable) {
        state.active.workStep = action.payload.workStep;
        state.active.editable = false;
        state.active.overlayClicked = false;
      }
    },
    setActiveAfterSave: (
      state,
      action: PayloadAction<{ workStep: WorkStep }>
    ) => {
      state.active.workStep = action.payload.workStep;
      state.active.editable = false;
    },
    cancelActive: (state) => {
      state.active.workStep = undefined;
      state.active.editable = false;
      state.selectionMode = undefined;
    },
    setOverlayClicked: (state) => {
      state.active.overlayClicked = true;
    },
    setTemplates: (
      state,
      action: PayloadAction<{
        templates: Array<ItemActions>;
        type: string;
      }>
    ) => {
      state.templates[action.payload.type] = action.payload.templates;
    },
    setItemTypes: (
      state,
      action: PayloadAction<{ itemTypes: Array<ItemType> }>
    ) => {
      state.itemTypes = action.payload.itemTypes;
    },
    setClassTypes: (
      state,
      action: PayloadAction<{ classTypes: Array<string> }>
    ) => {
      state.classTypes = action.payload.classTypes;
    },
    setReferenceTypes: (
      state,
      action: PayloadAction<{ referenceTypes: Array<Reference>; type: string }>
    ) => {
      state.referenceTypes[action.payload.type] = action.payload.referenceTypes;
    },
    setTemplate: (
      state,
      action: PayloadAction<{ template: WorkStepAction }>
    ) => {
      if (state.active.workStep) {
        state.active.workStep.stepAction = action.payload.template;
      }
    },
    setSelectionMode: (
      state,
      action: PayloadAction<{ mode: SelectionMode }>
    ) => {
      state.selectionMode = action.payload.mode;
    },
    clearSelectionMode: (state) => {
      state.selectionMode = undefined;
    },
    setItem: (state, action: PayloadAction<{ item: StepItem }>) => {
      if (state.active.workStep && state.active.editable) {
        if (
          !state.active.workStep.stepItem ||
          state.selectionMode === SelectionMode.Item
        ) {
          let payloadStepItem = action.payload.item;

          if (
            state.active.workStep.error &&
            state.active.workStep.error.needMigration &&
            payloadStepItem.annotation
          ) {
            payloadStepItem = {
              ...payloadStepItem,
              annotation: {
                ...payloadStepItem.annotation,
                data: {
                  ...payloadStepItem.annotation.data,
                  indirectExternalId: '',
                  indirectRelation: '',
                  lineExternalId: '',
                },
              },
            };
          }
          state.active.workStep.stepItem = payloadStepItem;
          if (
            state.active.workStep.error &&
            !state.active.workStep.error.needMigration
          ) {
            delete state.active.workStep.error;
          }
          if (
            !payloadStepItem.annotation?.data?.indirectExternalId &&
            !payloadStepItem.annotation?.data?.indirectRelation &&
            payloadStepItem.type?.template?.includes('relativeRef')
          ) {
            state.selectionMode = SelectionMode.RelativeRef;
          } else if (!payloadStepItem.annotation?.data?.lineExternalId) {
            state.selectionMode = SelectionMode.Line;
          } else {
            state.selectionMode = undefined;
          }
        }
      }
    },
    setRelativeRef: (
      state,
      action: PayloadAction<{ item: StepItem; relation?: Reference }>
    ) => {
      if (state.active.workStep && state.active.editable) {
        if (
          state.active.workStep.stepItem &&
          state.selectionMode === SelectionMode.RelativeRef
        ) {
          state.active.workStep.stepItem.relativeRef = action.payload.item;
          state.active.workStep.stepItem.indirectReference =
            action.payload.relation;

          if (
            state.active.workStep.error &&
            state.active.workStep.error.needMigration &&
            state.active.workStep?.stepItem?.annotation
          ) {
            state.active.workStep.stepItem.annotation.data = {
              ...state.active.workStep.stepItem.annotation.data,
              indirectRelation: action.payload.relation?.text,
              indirectExternalId: action.payload.item.asset?.externalId,
            };
          }

          state.active.workStep.stepItem.line = undefined;
          state.selectionMode = SelectionMode.Line;
        }
      }
    },
    setLine: (state, action: PayloadAction<{ item: StepItem }>) => {
      if (
        state.active.editable &&
        state.active.workStep &&
        state.active.workStep.stepItem
      ) {
        state.active.workStep.stepItem.line = action.payload.item;
        state.selectionMode = undefined;
        if (
          state.active.workStep.error &&
          state.active.workStep.error.needMigration &&
          state.active.workStep?.stepItem?.annotation
        ) {
          state.active.workStep.stepItem.annotation.data = {
            ...state.active.workStep.stepItem.annotation.data,
            lineExternalId: action.payload.item?.asset?.externalId,
          };
        }
      }
    },
    updateItemOption: (
      state,
      action: PayloadAction<{ item: StepItem; option: Reference }>
    ) => {
      const update = (workStepItem: StepItem) => {
        if (
          workStepItem &&
          workStepItem.annotation?.id === action.payload.item.annotation?.id
        ) {
          workStepItem.indirectReference = action.payload.option;
        } else if (workStepItem.relativeRef) {
          update(workStepItem.relativeRef);
        }
      };

      if (state.active.workStep && state.active.workStep.stepItem) {
        update(state.active.workStep.stepItem);
      }
      return state;
    },
    updateRelativePosition: (
      state,
      action: PayloadAction<{ item: StepItem; position: string }>
    ) => {
      if (
        state.active.workStep?.stepItem?.annotation &&
        state.active.workStep.stepItem.annotation?.id ===
          action.payload.item.annotation?.id
      ) {
        state.active.workStep.stepItem.annotation.data = {
          ...state.active.workStep.stepItem.annotation?.data,
          relativePosition: action.payload.position,
        };
      }
      return state;
    },
    setSizeAndClass: (
      state,
      action: PayloadAction<{ sizeAndClass: SizeAndClassType }>
    ) => {
      if (state.active.workStep?.stepItem?.annotation) {
        state.active.workStep.stepItem.annotation.data = {
          ...state.active.workStep.stepItem.annotation?.data,
          sizeAndClass: action.payload.sizeAndClass,
        };
      }
    },
    updateItemDetail: (state, action: PayloadAction<{ detail: string }>) => {
      if (state.active.workStep?.stepItem?.annotation) {
        state.active.workStep.stepItem.detail = action.payload.detail;
        state.active.workStep.stepItem.annotation.data = {
          ...state.active.workStep.stepItem.annotation?.data,
          detail: action.payload.detail,
        };
      }
    },
  },
});

export type ActiveWorkStepState = ReturnType<
  typeof activeWorkStepSlice.reducer
>;
export const { actions } = activeWorkStepSlice;
export default activeWorkStepSlice;
