import styled from 'styled-components/macro';
import { Colors } from '@cognite/cogs.js';

export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0px;
  bottom: 0px;
  align-items: center;
  height: 56px;
  width: 100%;
  padding-right: 15px;
  box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white.hex()};

  .firstFlex {
    align-items: center;
    width: 100%;
  }

  .scroll-to-top {
    margin-left: auto;
  }

  button.cogs-btn.cogs-btn-primary {
    white-space: nowrap;
    height: 32px;
    margin: 0px 15px 0px 50px;
  }

  button.cogs-btn.cogs-btn-secondary {
    white-space: nowrap;
    padding: 8px;
    margin-right: 50px;
    font-weight: 500;
  }

  .suggestion {
    text-align: left;
    font-size: 14px;
    @media (max-width: 580px) {
      visibility: hidden;
    }
    p {
      margin: 0px;
    }
  }
`;
