import { PhaseType } from 'types';

export const usePhasesScroll = () => {
  return {
    toggleCollapseLinkedPhase,
  };

  function toggleCollapseLinkedPhase(phaseId: string, phaseType: PhaseType) {
    const linkedPhaseEl = getLinkedPhase(phaseId, phaseType);
    if (linkedPhaseEl) {
      const togglePhaseEl = elBy('togglePhase', 'testid', linkedPhaseEl);
      togglePhaseEl.click();

      setTimeout(() => {
        const activeEl = document.querySelector(
          '[data-active="true"] [data-wsid]'
        ) as HTMLDivElement;
        if (activeEl) {
          activeEl.click();
        }

        const sidebarContentWrapper = document.querySelector(
          '.worksteps-sidebar-content-wrapper'
        ) as HTMLDivElement;
        const paddingTop = +sidebarContentWrapper.style.paddingTop.replace(
          'px',
          ''
        );

        const deworkstepsList = elBy(
          'deisolationWorkstepList',
          'worksteps-list-container-id'
        );

        if (paddingTop && deworkstepsList) {
          const deworkstepsListWrapper = deworkstepsList.parentNode as HTMLDivElement;
          deworkstepsListWrapper.style.marginTop = '0px';
        }
      }, 200);
    }
  }

  function getLinkedPhase(
    phaseId: string,
    phaseType: PhaseType
  ): HTMLDivElement {
    if (phaseType === 'deisolation') {
      const deIsolationPhase = elBy(phaseId, 'phase-id');
      const isolationPhaseId = deIsolationPhase.getAttribute(
        'data-linked-phase-id'
      ) as string;
      return elBy(isolationPhaseId, 'phase-id');
    }

    return elBy(phaseId, 'linked-phase-id');
  }

  function elBy(
    elId: string,
    findBy:
      | 'testid'
      | 'worksteps-list-container-id'
      | 'linked-phase-id'
      | 'phase-id',
    parentNode?: HTMLDivElement
  ): HTMLDivElement {
    let selector;
    switch (findBy) {
      default:
      case 'testid':
        selector = `[data-testid="${elId}"]`;
        break;
      case 'worksteps-list-container-id':
        selector = `[data-worksteps-list-container-id="${elId}"]`;
        break;
      case 'linked-phase-id':
        selector = `[data-linked-phase-id="${elId}"]`;
        break;
      case 'phase-id':
        selector = `[data-phaseid="${elId}"]`;
        break;
    }
    const parent = parentNode || document;
    return parent.querySelector(selector) as HTMLDivElement;
  }
};
