/* eslint-disable no-empty-function */
/* eslint-disable no-param-reassign */
import { ModelFactory } from 'factories/model.factory';
import { Status } from 'redux/types';
import {
  AddWorkStepActionDTO,
  BaseWorkstepsActionDTO,
  BatchUpdateWorkstepsTagNumbersActionDTO,
  ConfirmDraftWorkStepDTO,
  MoveWorkstepActionDTO,
  Phase,
  PhaseType,
  RemoveWorkStepActionDTO,
  SetWorkstepAsCheckedActionDTO,
  SetWorkstepAsDoneActionDTO,
  SetWorkstepStoppedExecutionActionDTO,
  WorkstepsStateVM,
} from 'types';
import { RemoveWorkStepCommand, UpdateWorkStepCommand } from './commands';
import { PhasesService } from './phases.service';
import { ProjectExecutionService } from './project-execution.service';
import { WorkstepsBuilderService } from './worksteps-builder.service';
import { WorkstepsService } from './worksteps.service';

export class WorkstepsStateService {
  private removeWorkstepCmd: RemoveWorkStepCommand;
  private updateWorkStepCommand: UpdateWorkStepCommand;
  constructor(
    private workstepsService: WorkstepsService,
    private phasesService: PhasesService,
    private projectExecutionService: ProjectExecutionService,
    private workstepsBuilderService: WorkstepsBuilderService,
    private modelFactory: ModelFactory
  ) {
    this.removeWorkstepCmd = new RemoveWorkStepCommand(
      this.workstepsService,
      this.phasesService
    );
    this.updateWorkStepCommand = new UpdateWorkStepCommand(
      this.workstepsService,
      this.phasesService,
      this.workstepsBuilderService
    );
  }

  getInitialState(): WorkstepsStateVM {
    return {
      phases: [] as Array<Phase>,
      workStepsLoaded: false,
      deIsolationMode: false,
      workStepsViewed: false,
      hasDeIsolationPhases: false,
      status: Status.idle,
      error: undefined as string | undefined,
    };
  }

  setWorkSteps(state: WorkstepsStateVM, phases: Phase[]): WorkstepsStateVM {
    state.status = Status.success;
    state.phases = phases;
    state.workStepsLoaded = true;
    state.hasDeIsolationPhases = this.phasesService.hasDeIsolationPhases(
      phases
    );

    return state;
  }

  moveWorkstep(
    state: WorkstepsStateVM,
    dto: MoveWorkstepActionDTO
  ): WorkstepsStateVM {
    const { fromPhase, newIndex, oldIndex, toPhase, workStep } = dto;
    const { phases } = state;
    const fromIndex = this.workstepsService.findPhaseIndex(phases, fromPhase);
    const toIndex = this.workstepsService.findPhaseIndex(phases, toPhase);

    if (fromIndex === -1 || toIndex === -1) {
      return state;
    }

    this.workstepsService.moveWorkstep(
      phases,
      workStep,
      fromIndex,
      toIndex,
      oldIndex,
      newIndex
    );
    state.phases = this.workstepsService.updateOrderOfWorkSteps(phases);
    return state;
  }

  removeWorkstep(
    state: WorkstepsStateVM,
    dto: RemoveWorkStepActionDTO
  ): WorkstepsStateVM {
    return this.removeWorkstepCmd.execute(state, dto);
  }

  addWorkstep(
    state: WorkstepsStateVM,
    dto: AddWorkStepActionDTO
  ): WorkstepsStateVM {
    const { phase, workStep, positionWithinPhase } = dto;
    const { phases } = state;
    const phaseIdx = this.getPhaseIndex(phases, phase.id);
    if (phaseIdx === -1) {
      state.phases.push({
        ...phase,
        workSteps: [workStep],
      });
    } else {
      phases[phaseIdx] = this.workstepsService.addWorkStep(
        phases[phaseIdx],
        workStep,
        positionWithinPhase
      );
    }

    if (phases[phaseIdx] && state.hasDeIsolationPhases) {
      state.phases = this.workstepsBuilderService.addMissingStep(
        state.phases,
        dto
      );
    }

    state.phases = this.workstepsService.updateOrderOfWorkSteps(phases);

    return state;
  }

  updateWorkstep(
    state: WorkstepsStateVM,
    dto: BaseWorkstepsActionDTO
  ): WorkstepsStateVM {
    return this.updateWorkStepCommand.execute(state, dto);
  }

  setWorkstepAsDone(
    state: WorkstepsStateVM,
    dto: SetWorkstepAsDoneActionDTO
  ): WorkstepsStateVM {
    state.phases = this.projectExecutionService.setWorkstepAsDone(
      state.phases,
      dto
    );
    return state;
  }

  undoWorkstepAsDone(
    state: WorkstepsStateVM,
    dto: BaseWorkstepsActionDTO
  ): WorkstepsStateVM {
    state.phases = this.projectExecutionService.undoWorkstepAsDone(
      state.phases,
      dto
    );

    return state;
  }

  undoCheckedWorkstepAsUncheck(
    state: WorkstepsStateVM,
    dto: BaseWorkstepsActionDTO
  ): WorkstepsStateVM {
    state.phases = this.projectExecutionService.undoCheckedWorkstepAsUncheck(
      state.phases,
      dto
    );

    return state;
  }

  setWorkstepAsChecked(
    state: WorkstepsStateVM,
    dto: SetWorkstepAsCheckedActionDTO
  ): WorkstepsStateVM {
    state.phases = this.projectExecutionService.setWorkstepAsChecked(
      state.phases,
      dto
    );
    return state;
  }

  setWorkstepStoppedExecution(
    state: WorkstepsStateVM,
    dto: SetWorkstepStoppedExecutionActionDTO
  ): WorkstepsStateVM {
    state.phases = this.projectExecutionService.setWorkstepStoppedExecution(
      state.phases,
      dto
    );
    return state;
  }

  batchUpdateWorkstepsTagNumbers(
    state: WorkstepsStateVM,
    dto: BatchUpdateWorkstepsTagNumbersActionDTO
  ): WorkstepsStateVM {
    const { tagNumbers } = dto;
    const { phases } = state;
    state.phases = this.workstepsService.batchUpdateWorkstepsTagNumbers(
      phases,
      tagNumbers
    );
    return state;
  }

  updatePhaseName(
    state: WorkstepsStateVM,
    phaseId: string,
    phaseName: string
  ): WorkstepsStateVM {
    state.phases = this.phasesService.updatePhaseMatchIdCallback(
      state.phases,
      phaseId,
      (phase) => this.phasesService.updatePhaseName(phase, phaseName)
    );
    return state;
  }

  createPhase(
    state: WorkstepsStateVM,
    name: string,
    phaseType: PhaseType
  ): WorkstepsStateVM {
    const phases =
      phaseType === 'isolation'
        ? state.phases.filter((phase) => phase.phaseType === phaseType) || []
        : state.phases;

    const isDeisolationPhase = phaseType === 'deisolation';
    const phase = this.modelFactory.createPhase(
      name,
      phases.length,
      phaseType,
      isDeisolationPhase
    );
    state.status = Status.success;
    state.phases.push(phase);

    if (state.hasDeIsolationPhases) {
      this.workstepsBuilderService.addMissingPhase(
        state.phases,
        phase,
        phaseType
      );
    }
    state.phases = this.phasesService.updateOrderOfPhases(state.phases);
    return state;
  }

  deleteEmptyPhase(
    state: WorkstepsStateVM,
    phaseId: string,
    linkedPhaseId?: string
  ): WorkstepsStateVM {
    const updatedPhases = state.phases.filter((phase) => {
      if (linkedPhaseId) {
        return !(phase.id === phaseId || phase.id === linkedPhaseId);
      }
      return !(phase.id === phaseId || phase.linkedPhaseId === phaseId);
    });
    state.phases = updatedPhases;
    return state;
  }
  approvePhase(
    state: WorkstepsStateVM,
    phaseId: string,
    email: string
  ): WorkstepsStateVM {
    state.phases = this.phasesService.updatePhaseMatchIdCallback(
      state.phases,
      phaseId,
      (phase) => this.phasesService.setApproved(phase, email)
    );
    return state;
  }

  unApprovePhase(
    state: WorkstepsStateVM,
    phaseId: string,
    email: string
  ): WorkstepsStateVM {
    state.phases = this.phasesService.updatePhaseMatchIdCallback(
      state.phases,
      phaseId,
      (phase) => this.phasesService.setUnApproved(phase, email)
    );
    return state;
  }
  previewDraftWorkStep(
    state: WorkstepsStateVM,
    dto: ConfirmDraftWorkStepDTO
  ): WorkstepsStateVM {
    state.phases = this.workstepsBuilderService.previewDraftWorkStep(
      state.phases,
      dto
    );
    return state;
  }
  ignoreDraftWorkStep(
    state: WorkstepsStateVM,
    dto: ConfirmDraftWorkStepDTO
  ): WorkstepsStateVM {
    state.phases = this.workstepsBuilderService.ignoreDraftWorkStep(
      state.phases,
      dto
    );
    return state;
  }

  confirmDraftWorkStep(
    state: WorkstepsStateVM,
    dto: ConfirmDraftWorkStepDTO
  ): WorkstepsStateVM {
    state.phases = this.workstepsBuilderService.confirmDraftWorkStep(
      state.phases,
      dto
    );
    return state;
  }

  createDeIsolationWorkSteps(state: WorkstepsStateVM): WorkstepsStateVM {
    state.phases = this.workstepsBuilderService.createDeIsolationWorkSteps(
      state.phases
    );
    state.hasDeIsolationPhases = this.phasesService.hasDeIsolationPhases(
      state.phases
    );
    return state;
  }

  private getPhaseIndex(phases: Phase[], phaseId: string): number {
    return this.workstepsService.findPhaseIndex(phases, phaseId);
  }
}
