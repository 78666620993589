import React, { useState } from 'react';
import { Body, Button } from '@cognite/cogs.js';
import { Comments } from 'components/Comments';
import { LinkButton } from 'shared/LinkButton/LinkButton';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { Comment, ProjectStatus, WorkStep, Role } from 'types';
import { DeSelectedBox, ReviewDeselected } from './ReviewDeselected';
import {
  createResolvedComments,
  hasReviewerCommentsAfterResolved,
} from './Resolved';

export type Props = {
  workStep: WorkStep;
  phaseId: string;
  user: string;
  projectStatus?: ProjectStatus;
  isActive: boolean;
  isInEditMode: boolean;
  viewOnly: boolean;
};

const ReviewerActions = ({
  workStep,
  user,
  phaseId,
  setShowNewComment,
}: {
  workStep: WorkStep;
  user: string;
  phaseId: string;
  setShowNewComment: Function;
}) => {
  const { resolveReviewComments } = useWorkSteps();
  if (
    workStep.review?.resolved?.compiler &&
    !workStep.review?.resolved?.reviewer
  ) {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="ghost"
          data-testid="reject-and-comment"
          onClick={() => {
            setShowNewComment(true);
          }}
        >
          Add comment
        </Button>
        <Button
          type="secondary"
          data-testid="reviewer-confirm-resolution"
          variant="default"
          onClick={() => {
            resolveReviewComments(
              {
                ...workStep,
                review: {
                  ...workStep.review,
                  resolved: {
                    ...workStep.review?.resolved,
                    reviewer: {
                      name: user,
                      date: Date.now(),
                    },
                  },
                },
              },
              phaseId
            );
          }}
        >
          Confirm resolution
        </Button>
      </div>
    );
  }
  return null;
};

const ResolveButton = ({
  workStep,
  user,
  phaseId,
  projectStatus,
  setShowNewComment,
}: {
  workStep: WorkStep;
  user: string;
  phaseId: string;
  projectStatus: ProjectStatus;
  setShowNewComment: Function;
}) => {
  const { resolveReviewComments } = useWorkSteps();

  const resolve = (role: Role, resolved: { name: string; date: number }) => {
    resolveReviewComments(
      {
        ...workStep,
        review: {
          ...workStep.review,
          resolved: {
            ...workStep.review?.resolved,
            compiler:
              role === 'compiler'
                ? resolved
                : workStep.review?.resolved?.compiler,
            reviewer:
              role === 'reviewer'
                ? resolved
                : workStep.review?.resolved?.reviewer,
          },
        },
      },
      phaseId
    );
  };

  if (projectStatus === 'compilation' && workStep.review?.comments?.length) {
    return (
      <Button
        data-testid="resolve-as-compiler"
        type="ghost"
        onClick={() =>
          resolve('compiler', {
            name: user,
            date: Date.now(),
          })
        }
      >
        Resolve
      </Button>
    );
  }
  if (projectStatus === 'review' && workStep.review?.resolved?.compiler?.date) {
    return (
      <Button
        data-testid="resolve-after-all-as-reviewer"
        type="ghost"
        onClick={() => {
          resolve('reviewer', {
            name: user,
            date: Date.now(),
          });
          setShowNewComment(false);
        }}
      >
        Resolve after all
      </Button>
    );
  }
  return null;
};

export const WorkStepReview = ({
  workStep,
  isActive,
  isInEditMode,
  projectStatus,
  user,
  phaseId,
  viewOnly,
}: Props) => {
  const { addReviewComment, resolveReviewComments } = useWorkSteps();

  const resolved =
    !!workStep.review?.resolved?.compiler?.date &&
    !!workStep.review?.resolved?.reviewer?.date;

  const [showNewComment, setShowNewComment] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(resolved);

  const shouldHideNewComment = () => {
    if (viewOnly) return true;
    if (projectStatus === 'done') return true;
    if (isInEditMode) return true;
    if (showNewComment) return false;

    if (workStep.review?.resolved?.compiler) {
      if (workStep.review?.resolved?.reviewer) {
        return true;
      }
      if (hasReviewerCommentsAfterResolved(workStep)) {
        return false;
      }
      return true;
    }
    return false;
  };

  const undoPredicate = (comment: Comment) => {
    if (comment.author !== user || comment?.metaData?.commentType !== 'resolve')
      return false;
    if (
      projectStatus === 'compilation' &&
      !workStep.review?.resolved?.reviewer?.date &&
      comment.role === 'compiler'
    ) {
      return true;
    }
    if (projectStatus === 'review' && comment.role === 'reviewer') {
      return true;
    }
    return false;
  };

  const onUndo = () => {
    resolveReviewComments(
      {
        ...workStep,
        review: {
          ...workStep.review,
          resolved: {
            compiler:
              projectStatus === 'compilation'
                ? undefined
                : workStep.review?.resolved?.compiler,
            reviewer:
              projectStatus === 'review'
                ? undefined
                : workStep.review?.resolved?.reviewer,
          },
        },
      },
      phaseId
    );
  };

  if (workStep.review?.comments?.length || projectStatus === 'review') {
    if (isActive && !collapsed) {
      return (
        <div style={{ width: '95%' }}>
          <Comments
            userRole={projectStatus === 'compilation' ? 'compiler' : 'reviewer'}
            user={user}
            comments={
              workStep.review?.comments?.concat(
                createResolvedComments(workStep.review?.resolved)
              ) || []
            }
            identifier={`workStep_${workStep.id}`}
            onSave={async (reviewComment: Comment) => {
              return addReviewComment(
                {
                  ...workStep,
                  review: {
                    ...workStep.review,
                    comments: [
                      ...(workStep.review?.comments || []),
                      reviewComment,
                    ],
                  },
                },
                phaseId
              );
            }}
            undo={{
              predicate: undoPredicate,
              onUndo,
            }}
            readOnly={shouldHideNewComment()}
            newCommentTitleElement={() => {
              return showNewComment ? (
                <Body
                  level={3}
                  style={{ color: 'var(--cogs-danger)', fontWeight: 'bold' }}
                >
                  NOT RESOLVED
                </Body>
              ) : (
                <span />
              );
            }}
            secondaryItem={() => (
              <ResolveButton
                workStep={workStep}
                user={user}
                phaseId={phaseId}
                projectStatus={projectStatus}
                setShowNewComment={setShowNewComment}
              />
            )}
          />
          {resolved && !collapsed && (
            <div
              style={{
                width: '95%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '8px',
              }}
            >
              <LinkButton onClick={() => setCollapsed(true)}>
                Collapse comments
              </LinkButton>
            </div>
          )}
          {projectStatus === 'review' &&
            shouldHideNewComment() &&
            !viewOnly && (
              <ReviewerActions
                workStep={workStep}
                user={user}
                phaseId={phaseId}
                setShowNewComment={setShowNewComment}
              />
            )}
        </div>
      );
    }
    if (workStep.review?.resolved && collapsed && isActive) {
      return (
        <DeSelectedBox color="var(--cogs-pink-7)">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <span style={{ fontStyle: 'italic' }}>Resolved</span>
            <LinkButton onClick={() => setCollapsed(false)}>
              View comment history
            </LinkButton>
          </div>
        </DeSelectedBox>
      );
    }
    return (
      <ReviewDeselected
        workStep={workStep}
        user={user}
        hasReviewerCommentsAfterResolved={hasReviewerCommentsAfterResolved}
      />
    );
  }
  return null;
};
