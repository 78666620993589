import { Button, Icon } from '@cognite/cogs.js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { Phase, ProjectStatus, StepExecutonStatus, WorkStep } from 'types';
import {
  ExecutionListCollapseButton,
  ExecutionCompletion,
  ExecutionWorkStepList,
} from './elements';
import { ExecutionWorkStepModal } from './ExecutionWorkStepModal';
import { ExecutionPhases } from './ExecutionPhases';
import { ApprovePhaseModal } from './ApprovePhaseModal';

import { BackToDashboardModal } from './BackToDashboardModal';

import { UnApprovePhaseModal } from './UnApprovePhaseModal';

export type ExecutionListProps = {
  projectStatus?: ProjectStatus;
  projectId: string;
  executorsList: string[];
  supervisorsList: string[];
  phases: Phase[];
  buttonsDisabled: boolean;
  undoWorkstep: (phaseId: string) => void;
  uncheckWorkstep: (phaseId: string) => void;
  setWorkstepAsChecked: (phaseId: string, email: string) => void;
  setWorkstepAsDone: (phaseId: string, email: string) => void;
  setPhaseApproved: (phaseId: string, email: string) => void;
  setPhaseUnApproved: (phaseId: string, email: string) => void;
  completeProcedure: () => void;
  viewOnly: boolean;
};

export const ExecutionList = ({
  projectStatus,
  projectId,
  executorsList,
  supervisorsList,
  phases,
  buttonsDisabled,
  undoWorkstep,
  setWorkstepAsChecked,
  setWorkstepAsDone,
  uncheckWorkstep,
  setPhaseApproved,
  completeProcedure,
  setPhaseUnApproved,
  viewOnly,
}: ExecutionListProps) => {
  const {
    active,
    setWorkStepAsActive,
    getWorkstepWithPredicate,
    getActivePhaseId,
  } = useWorkSteps();

  const [executionModalOpen, setExecutionModalOpen] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);

  const [backToDashboardModalOpen, setBackToDashboardModalOpen] = useState(
    false
  );

  const [unApprovalModalOpen, setUnApprovalModalOpen] = useState({
    isOpenModel: false,
    phaseId: '',
  });

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [
    isModalInChooseCheckedState,
    setIsModalInChooseCheckedState,
  ] = useState(false);

  const [currentPhaseId, setCurrentPhaseId] = useState('');
  const [curExecutorsList, setCurExecutorsList] = useState<string[]>(
    executorsList
  );

  useEffect(() => {
    if (active) {
      setCurExecutorsList(
        executorsList.filter(
          (item) => active.workStep?.execution?.doneBy !== item
        )
      );
    }
  }, [active, executorsList]);

  const areAllPhasesApproved = useMemo(() => {
    return phases.every((phase) => !!phase.approvedBy);
  }, [phases]);

  const execListRef = useRef(null);

  const resetState = () => {
    setCurrentPhaseId('');
    setExecutionModalOpen(false);
    setApprovalModalOpen(false);
    setUnApprovalModalOpen({ isOpenModel: false, phaseId: '' });
    setIsModalInChooseCheckedState(false);
  };

  const onUndoStep = (phaseId: string) => {
    undoWorkstep(phaseId);
    resetState();
    setCurExecutorsList(executorsList);
  };

  const onUncheckStep = (phaseId: string) => {
    uncheckWorkstep(phaseId);
    resetState();
    setCurExecutorsList(executorsList);
  };

  const onExecutionModal = (email: string) => {
    setExecutionModalOpen(false);
    if (isModalInChooseCheckedState) {
      setWorkstepAsChecked(currentPhaseId, email);
      setCurExecutorsList(executorsList);
    } else {
      setWorkstepAsDone(currentPhaseId, email);
      setCurExecutorsList(executorsList.filter((item) => item !== email));
    }
  };

  const onApprovalModal = (email: string) => {
    const activePhaseId = getActivePhaseId() || '';

    setApprovalModalOpen(false);
    setPhaseApproved(activePhaseId, email);
    setBackToDashboardModalOpen(true);
  };

  const onUnApprovalModal = (email: string, phaseId: string) => {
    setUnApprovalModalOpen({ isOpenModel: false, phaseId: '' });
    setPhaseUnApproved(phaseId, email);
  };

  const scrollWorkstepInView = useCallback((workstepId) => {
    const elSelector = `[data-wsid="work-step-item-${workstepId}"]`;
    const nextWorkStepDomEl = document.querySelector(elSelector);
    if (nextWorkStepDomEl !== null && nextWorkStepDomEl.scrollIntoView) {
      nextWorkStepDomEl.scrollIntoView();
    }
  }, []);

  // just an improvement in case if the step is not in viewport
  const setActiveWorkstep = useCallback(() => {
    if (active.workStep) {
      scrollWorkstepInView(active.workStep.id);
      return;
    }

    const predicate = (workStep: WorkStep) =>
      !workStep.isDraft &&
      workStep.execution?.getStatus() !== StepExecutonStatus.Checked;

    const firstNotExecWorkStep = getWorkstepWithPredicate(predicate);

    if (firstNotExecWorkStep) {
      setWorkStepAsActive(firstNotExecWorkStep);
      scrollWorkstepInView(firstNotExecWorkStep.id);
    }
    // eslint-disable-next-line
  }, [getWorkstepWithPredicate, setWorkStepAsActive]);

  const onWheel = useCallback((e: any) => {
    // @ts-ignore
    const containerScrollPosition = execListRef.current.scrollLeft;
    // @ts-ignore
    execListRef.current.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: 'smooth',
    });
  }, []);

  useEffect(() => {
    setActiveWorkstep();
    // eslint-disable-next-line
  }, [phases]);

  return (
    <div>
      <ExecutionWorkStepModal
        isModalOpen={executionModalOpen}
        setModalOpen={setExecutionModalOpen}
        isModalInChooseCheckedState={isModalInChooseCheckedState}
        executorsList={curExecutorsList}
        onSelection={onExecutionModal}
        projectId={projectId}
      />
      <BackToDashboardModal
        isModalOpen={backToDashboardModalOpen}
        setModalOpen={setBackToDashboardModalOpen}
        projectId={projectId}
      />
      <ApprovePhaseModal
        isModalOpen={approvalModalOpen}
        setModalOpen={setApprovalModalOpen}
        supervisorsList={supervisorsList}
        onSelection={onApprovalModal}
        projectId={projectId}
      />

      <UnApprovePhaseModal
        isModalOpen={unApprovalModalOpen}
        setModalOpen={setUnApprovalModalOpen}
        executorsList={executorsList}
        onSelection={onUnApprovalModal}
        projectId={projectId}
      />

      <ExecutionListCollapseButton
        className="cogs-btn cogs-btn-secondary cogs-btn-outline z-8"
        title={isCollapsed ? 'Expand Worksteps' : 'Collapse Worksteps'}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="execution-list-collapse-button" key="icon-Collapse">
          {isCollapsed ? (
            <Icon type="Collapse" size={21} />
          ) : (
            <Icon type="Expand" size={21} />
          )}
        </div>
      </ExecutionListCollapseButton>

      <ExecutionWorkStepList
        className={isCollapsed ? 'collapsed' : 'z-12'}
        data-testid="workstepList"
        ref={execListRef}
        onWheel={onWheel}
      >
        <ExecutionPhases
          onUncheckStep={onUncheckStep}
          phases={phases}
          viewOnly={viewOnly}
          onUndoStep={onUndoStep}
          buttonsDisabled={buttonsDisabled}
          setExecutionModalOpen={setExecutionModalOpen}
          setUnApprovalModalOpen={setUnApprovalModalOpen}
          setApprovalModalOpen={setApprovalModalOpen}
          setIsModalInChooseCheckedState={setIsModalInChooseCheckedState}
          setCurrentPhaseId={setCurrentPhaseId}
          projectStatus={projectStatus}
        />

        {!viewOnly && (
          <ExecutionCompletion>
            <div>Complete Execution</div>
            <Button
              type="tertiary"
              size="default"
              disabled={!areAllPhasesApproved}
              onClick={completeProcedure}
              data-testid="complete-procedure-btn"
            >
              Complete
            </Button>
          </ExecutionCompletion>
        )}
      </ExecutionWorkStepList>
    </div>
  );
};
