import React, { useCallback } from 'react';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { ProjectStatus } from 'types';
import { AddPhase } from '../AddPhase';
import { WorkstepsOverlay, WorkstepsSidebarContainer } from './elements';
import { WorkstepsList } from './WorkstepsList';

export interface WorkstepsSidebarProps {
  projectStatus?: ProjectStatus;
  viewOnly: boolean;
}

export const WorkstepsSidebar = ({
  projectStatus,
  viewOnly,
}: WorkstepsSidebarProps) => {
  const {
    active,
    addNewPhase,
    setWorkStepAsNeedsSaving,
    hasDeIsolationPhases,
  } = useWorkSteps();
  const onAddNewIsolationPhase = useCallback((name) => {
    addNewPhase(name, 'isolation');
    // eslint-disable-next-line
  }, []);
  const onAddNewDeIsolationPhase = useCallback((name) => {
    addNewPhase(name, 'deisolation');
    // eslint-disable-next-line
  }, []);

  const onOverlayClicked = useCallback(
    () => {
      setWorkStepAsNeedsSaving();
    },
    // eslint-disable-next-line
    [setWorkStepAsNeedsSaving]
  );

  return (
    <WorkstepsSidebarContainer hasDeisolation={hasDeIsolationPhases}>
      <div className="worksteps-list-header">
        <div
          className={`worksteps-sidebar-panel compact${
            hasDeIsolationPhases ? '' : ' full-width'
          }`}
        >
          Isolation
        </div>
        {hasDeIsolationPhases && (
          <div className="worksteps-sidebar-panel compact">De-Isolation</div>
        )}
      </div>
      <div className="worksteps-sidebar-content">
        <div className="worksteps-sidebar-content-wrapper">
          <div
            className={`worksteps-sidebar-panel${
              hasDeIsolationPhases ? '' : ' full-width'
            }`}
          >
            {active.editable && projectStatus === 'compilation' && (
              <WorkstepsOverlay onClick={() => onOverlayClicked()} />
            )}
            <WorkstepsList
              projectStatus={projectStatus}
              phasesType="isolation"
              onOverlayClicked={onOverlayClicked}
              viewOnly={viewOnly}
            />
          </div>
          {hasDeIsolationPhases && (
            <div className="worksteps-sidebar-panel">
              {active.editable && projectStatus === 'compilation' && (
                <WorkstepsOverlay onClick={() => onOverlayClicked()} />
              )}
              <WorkstepsList
                projectStatus={projectStatus}
                phasesType="deisolation"
                onOverlayClicked={onOverlayClicked}
                viewOnly={viewOnly}
              />
            </div>
          )}
        </div>
      </div>

      {!viewOnly && projectStatus === 'compilation' && (
        <div
          className="worksteps-list-footer"
          data-testid="worksteps-list-footer"
        >
          <div
            className={`worksteps-sidebar-panel compact ${
              hasDeIsolationPhases ? '' : 'full-width'
            }`}
          >
            <AddPhase onAddNewPhase={onAddNewIsolationPhase} />
          </div>
          {hasDeIsolationPhases && (
            <div className="worksteps-sidebar-panel compact de-isolation-footer-panel">
              <AddPhase onAddNewPhase={onAddNewDeIsolationPhase} />
            </div>
          )}
        </div>
      )}
    </WorkstepsSidebarContainer>
  );
};
