import { Detail, Radio } from '@cognite/cogs.js';
import React from 'react';
import { Bar, SafeguardingValue, SafeguardingEditWrapper } from './elements';

enum SafeguardingState {
  'No' = 'NO',
  'LockedOpen' = 'LO',
  'LockedClosed' = 'LC',
}

export type Props = {
  safeguarding?: string;
  setSafeguarding: Function;
  active: boolean;
  editable: boolean;
};

export const SafeguardingValve = ({
  safeguarding,
  setSafeguarding,
  active,
  editable,
}: Props) => {
  const handleChange = (_checked: boolean, value: string) => {
    setSafeguarding(value);
  };

  if (editable) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '8px 0px 8px 0px',
          whiteSpace: 'normal',
        }}
      >
        <Detail style={{ color: 'var(--cogs-greyscale-grey6)' }}>
          Safeguarding valve
        </Detail>
        <div className="cogs-body-3">
          <SafeguardingEditWrapper>
            <Radio
              id="safeguardingNo"
              name="safeguarding"
              data-testid="no-safeguard"
              value={SafeguardingState.No}
              checked={!safeguarding || safeguarding === SafeguardingState.No}
              onChange={handleChange}
            >
              No
            </Radio>
            <Radio
              id="safeguardingOpen"
              name="safeguarding"
              data-testid="open-safeguard"
              value={SafeguardingState.LockedOpen}
              checked={safeguarding === SafeguardingState.LockedOpen}
              onChange={handleChange}
            >
              Normally locked opened
            </Radio>
            <Radio
              id="safeguardingClose"
              name="safeguarding"
              data-testid="close-safeguard"
              value={SafeguardingState.LockedClosed}
              checked={safeguarding === SafeguardingState.LockedClosed}
              onChange={handleChange}
            >
              Normally locked closed
            </Radio>
          </SafeguardingEditWrapper>
        </div>
      </div>
    );
  }
  if (active && safeguarding && safeguarding !== SafeguardingState.No) {
    return (
      <div
        style={{
          display: 'flex',
          padding: '8px 0px 8px 0px',
          whiteSpace: 'normal',
        }}
      >
        <Bar data-testid="active-safeguard" className="cogs-body-1">
          Safeguarding valve normally locked
          <SafeguardingValue>
            {safeguarding === SafeguardingState.LockedOpen ? 'open' : 'closed'}
          </SafeguardingValue>
        </Bar>
      </div>
    );
  }
  return null;
};
