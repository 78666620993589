import React from 'react';
import { Avatar, Flex } from '@cognite/cogs.js';
import { Comment } from 'types';
import { LinkButton } from 'shared/LinkButton/LinkButton';
import { TextDetailSmall } from 'pages/Project/components/elements';
import { toDisplayDate } from 'utils/date';
import { CommentContainer } from '../elements';
import { TextDetail } from './elements';

export type Undo =
  | {
      predicate: (comment: Comment) => boolean;
      onUndo: (comment: Comment) => void;
    }
  | undefined;

type UploadedCommentProps = {
  comment: Comment;
  undo?: Undo;
};

const UploadedComment = ({ comment, undo }: UploadedCommentProps) => {
  const backgroundColor = comment.role === 'reviewer' ? '#fdf3f5' : '#f5f5f5';

  return (
    <CommentContainer
      style={{ backgroundColor }}
      data-testid={`comment-${comment.id}`}
    >
      <Flex direction="column" style={{ justifyContent: 'space-between' }}>
        <Flex direction="row" style={{ alignItems: 'center' }}>
          <Avatar
            title={comment.author}
            style={{ alignSelf: 'flex-start', marginRight: '8px' }}
            text={comment.author}
          />
          <TextDetailSmall>
            {toDisplayDate(comment.date, 'D.M.YYYY hh:mm a')}
          </TextDetailSmall>
        </Flex>
        <TextDetail>
          {comment.commentContent}{' '}
          {undo?.predicate(comment) && (
            <>
              {' - '}
              <LinkButton
                data-testid={`undo-comment-${comment.id}`}
                onClick={() => undo?.onUndo(comment)}
              >
                Undo
              </LinkButton>
            </>
          )}
        </TextDetail>
      </Flex>
    </CommentContainer>
  );
};

export default UploadedComment;
