import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
  createAnnotations,
  deleteAnnotations,
  listAnnotations,
} from '@cognite/annotations-experimental';
import { AnnotationDuplicatesService } from 'services/annotation-duplicates.service';
import { FetchAnnotationsForFileDTO, FileAnnotationsDTO } from 'types';
import { getClient } from '../config/cognitesdk';

export class AnnotationsResourceService {
  private annotationDuplicatesService: AnnotationDuplicatesService;
  constructor() {
    this.annotationDuplicatesService = new AnnotationDuplicatesService();
  }
  async fetchAnnotationsForFileId(
    dto: FetchAnnotationsForFileDTO
  ): Promise<FileAnnotationsDTO> {
    const { fileId, projectAnnotations } = dto;
    // The api is changed, annotatedResourceType is required parameter
    const filter = {
      annotatedResourceIds: [{ id: fileId }],
      annotatedResourceType: 'file',
    } as any;
    const fileAnnotationsTemp = await listAnnotations(getClient(), filter);

    const fileAnnotations = fileAnnotationsTemp.filter(
      annotation => annotation.annotationType !== 'isoplan.IsoPlanAnnotation'
    )

    const filteredAnnotations = dto.procedureNeedsMigration
      ? this.mergeProjectAndFileAnnotationsForMigration(
          fileAnnotations,
          projectAnnotations
        )
      : this.filterOutDuplicatesMergeWithProjectAnnotations(
          fileAnnotations,
          projectAnnotations
        );
    return {
      fileId,
      annotations: filteredAnnotations ?? [],
    };
  }

  deleteAnnotation(id: number): Promise<{}> {
    return deleteAnnotations(getClient(), [{ id }]);
  }

  createAnnotation(
    annotation: CogniteAnnotationSpec
  ): Promise<CogniteAnnotation[]> {
    return createAnnotations(getClient(), [annotation]);
  }

  private filterOutDuplicatesMergeWithProjectAnnotations(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    return this.annotationDuplicatesService.filterOutDuplicateProjectAnnotations(
      this.annotationDuplicatesService.filterOutDuplicatePipelineAnnotations(
        fileAnnotations
      ),
      projectAnnotations
    );
  }

  private mergeProjectAndFileAnnotationsForMigration(
    fileAnnotations: CogniteAnnotation[],
    projectAnnotations: CogniteAnnotation[]
  ) {
    const markedProjectAnnotations = projectAnnotations.map((annotation) => {
      return {
        ...annotation,
        source: 'unverified',
      };
    });

    const res = this.annotationDuplicatesService.mergeWithProjectAnnotations(
      this.annotationDuplicatesService.filterOutDuplicatePipelineAnnotations(
        fileAnnotations
      ),
      markedProjectAnnotations
    );
    return res;
  }
}
