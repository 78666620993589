import { Button, Colors, Title } from '@cognite/cogs.js';
import LoadingBox from 'components/LoadingBox';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActiveWorkStep, ProjectStatus, WorkStep } from 'types';
import { toDisplayDate } from 'utils/date';
import { workstepsService } from 'services';
import { WorkStepReview } from '../Review/WorkStepReview';
import { SafeguardingValve } from '../SafeguardingValve';
import { Template } from '../Template/Template';

import {
  Box,
  WorkStepContainer,
  Step,
  RowContainer,
  ColumnContainer,
  Actions,
  DeleteWarning,
  WorkStepContainerInner,
  CheckedAndDoneArea,
} from './elements';
import { CommentArea } from './CommentArea';
import { WorkStepHeader } from './WorkStepHeader';
import { WarningArea } from './WarningArea';

export interface DeleteProps {
  workStepId: string;
  phaseId: string;
}

export interface WorkStepItemProps {
  active: ActiveWorkStep;
  workStep: WorkStep;
  phaseId: string;
  editable: boolean;
  user: string;
  projectStatus?: ProjectStatus;
  isActive?: boolean;
  showWorkstepBackgroundAsActive?: boolean;
  onSelectWorkstep: (workStep: WorkStep) => void;
  onEditWorkstep: (workStep: WorkStep) => void;
  onDeleteWorkstep: ({ workStepId, phaseId }: DeleteProps) => void;
  onSaveWorkstep: (
    workStep: WorkStep,
    phaseId: string,
    approve?: boolean
  ) => void;
  isSaving?: boolean;
  isDoneAndChecked?: boolean;
  children?: any;
  viewOnly: boolean;
}

export const WorkStepItem = React.memo(
  (props: WorkStepItemProps) => {
    const {
      active,
      workStep,
      phaseId,
      editable,
      projectStatus,
      user,
      isDoneAndChecked,
      onSelectWorkstep,
      onEditWorkstep,
      onDeleteWorkstep,
      onSaveWorkstep,
      viewOnly,
    } = props;
    const isEditable = editable && !viewOnly;

    const [deleteWarning, setDeleteWarning] = useState(false);
    const [comment, setComment] = useState(workStep.comment);
    const [tag, setTag] = useState(!!workStep.tag);
    const [safeguarding, setSafeguarding] = useState<undefined | 'LO' | 'LC'>(
      undefined
    );

    const isActive = workStep.id === active.workStep?.id;
    const isDefaultTagged = workStep.stepAction?.defaultTagged || false;

    useEffect(() => {
      if (
        !safeguarding &&
        workStep.stepItem?.type?.options?.includes('safeguard')
      ) {
        if (workStep.safeguarding) {
          setSafeguarding(workStep.safeguarding);
        } else if (workStep.stepItem?.annotation?.data?.subDetail) {
          setSafeguarding(workStep.stepItem?.annotation?.data?.subDetail);
        }
      }
    }, [safeguarding, workStep.safeguarding, workStep.stepItem]);

    // force tag checkbox on true on working item
    useEffect(() => {
      if (isDefaultTagged && isDefaultTagged !== tag && isActive) {
        setTag(isDefaultTagged);
      }
    }, [isDefaultTagged, tag, isActive]);

    const stepRef = useRef<HTMLDivElement>(null);

    const showWorkstepBackgroundAsActive =
      props.showWorkstepBackgroundAsActive !== undefined
        ? props.showWorkstepBackgroundAsActive
        : isActive;

    const isInExecution = projectStatus === 'execution';

    const isWorkstepOnlyComment = workstepsService.isWorkstepOnlyComment(
      workStep
    );

    const editWorkstep = useCallback(() => {
      if (viewOnly) {
        return;
      }

      if (projectStatus !== 'compilation') {
        return;
      }

      if (
        !isDoneAndChecked ||
        (workStep.error && workStep.error.needMigration)
      ) {
        onEditWorkstep(workStep);
      }
    }, [projectStatus, workStep, isDoneAndChecked, onEditWorkstep, viewOnly]);

    return (
      <WorkStepContainer
        stepColor={workStep.stepAction?.color}
        editable={isEditable}
      >
        <RowContainer>
          <WorkStepContainerInner>
            {isEditable && deleteWarning && (
              <DeleteWarning
                data-testid={`warning-delete-workstep-${workStep.id}`}
              >
                <Title level="6" style={{ color: Colors.white.hex() }}>
                  Do you want to remove this step?
                </Title>
                <div>
                  <Button
                    data-testid={`cancel-delete-workstep-${workStep.id}`}
                    type="secondary"
                    style={{ marginRight: '5px' }}
                    onClick={() => setDeleteWarning(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid={`confirm-delete-workstep-${workStep.id}`}
                    type="danger"
                    onClick={() => {
                      onDeleteWorkstep({ workStepId: workStep.id, phaseId });
                      setDeleteWarning(false);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </DeleteWarning>
            )}
            {active.editable &&
              active.overlayClicked &&
              active.workStep?.id === workStep.id && (
                <WarningArea
                  stepId={workStep.id}
                  message="Save or delete this step before you proceed"
                />
              )}
            {!!workStep.error && (
              <WarningArea
                stepId={workStep.id}
                message="This step must be reviewed"
              />
            )}
            <Step
              data-testid={`instruction-field-${workStep.id}`}
              data-wsid={`work-step-item-${workStep.id}`}
              className={showWorkstepBackgroundAsActive ? 'active' : ''}
              title={
                isActive && !editable && projectStatus === 'compilation'
                  ? 'Double click to edit'
                  : ''
              }
              ref={stepRef}
              tabIndex={0}
              onClick={() => {
                onSelectWorkstep(workStep);
              }}
              onDoubleClick={editWorkstep}
              isDoneAndChecked={isDoneAndChecked}
            >
              <Box>
                {!isInExecution && !isDoneAndChecked && (
                  <div className="drag-handle-box">
                    <img
                      alt="::"
                      src={`${process.env.PUBLIC_URL}/images/DragHandle.svg`}
                    />
                  </div>
                )}

                <div className="work-step-body-box">
                  <WorkStepHeader
                    editable={isEditable}
                    workstepId={workStep.id}
                    wsPosition={
                      workStep.displayPosition && workStep.isDraft
                        ? workStep.displayPosition
                        : workStep.position
                    }
                    wsTagNumber={workStep.tagNumber}
                    wsTag={workStep.tag}
                    tag={tag}
                    setTag={setTag}
                    safeguarding={safeguarding}
                    status={workStep.execution?.getStatus()}
                  />
                  <RowContainer style={{ marginBottom: '4px' }}>
                    <Template
                      active={active}
                      workStep={workStep}
                      editable={isEditable}
                    />
                  </RowContainer>

                  {workStep?.stepItem?.type?.options?.includes('safeguard') && (
                    <SafeguardingValve
                      safeguarding={safeguarding}
                      setSafeguarding={setSafeguarding}
                      editable={isEditable}
                      active={isActive}
                    />
                  )}

                  <CommentArea
                    editable={isEditable}
                    comment={workStep.comment}
                    newComment={comment}
                    workstepId={workStep.id}
                    isActive={isActive}
                    isWorkstepOnlyComment={isWorkstepOnlyComment}
                    setComment={setComment}
                  />

                  {!isInExecution && (
                    <WorkStepReview
                      workStep={workStep}
                      user={user}
                      phaseId={phaseId}
                      projectStatus={projectStatus}
                      isActive={isActive}
                      isInEditMode={editable}
                      viewOnly={viewOnly}
                    />
                  )}

                  {editable && (
                    <ColumnContainer>
                      <RowContainer
                        style={{
                          marginTop: '5px',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        {props.isSaving ? (
                          <>
                            <LoadingBox
                              text="Saving"
                              backgroundColor={Colors.white.hex()}
                              textColor={Colors.black.hex()}
                            />
                          </>
                        ) : (
                          <Actions>
                            <Button
                              data-testid={`delete-workStep-${workStep.id}`}
                              type="ghost-danger"
                              size="small"
                              style={{ fontSize: 12, marginRight: '5px' }}
                              onClick={() => {
                                setDeleteWarning(true);
                              }}
                            >
                              Delete step
                            </Button>
                            <Button
                              data-testid={`save-workstep-${workStep.id}`}
                              type="primary"
                              size="small"
                              onClick={() => {
                                onSaveWorkstep(
                                  { ...workStep, comment, tag, safeguarding },
                                  phaseId
                                );
                              }}
                            >
                              Save step
                            </Button>
                            {workStep.error && (
                              <Button
                                data-testid={`approve-workstep-${workStep.id}`}
                                type="primary"
                                size="small"
                                onClick={() => {
                                  onSaveWorkstep(
                                    { ...workStep, comment, tag, safeguarding },
                                    phaseId,
                                    true
                                  );
                                }}
                                style={{ marginLeft: '5px' }}
                              >
                                Approve
                              </Button>
                            )}
                          </Actions>
                        )}
                      </RowContainer>
                    </ColumnContainer>
                  )}
                </div>
              </Box>
            </Step>
            {isDoneAndChecked && !isInExecution && (
              <CheckedAndDoneArea>
                Done {toDisplayDate(workStep.execution?.doneTime, 'h:mm a')} |
                Checked{' '}
                {toDisplayDate(workStep.execution?.checkedTime, 'h:mm a')}
              </CheckedAndDoneArea>
            )}
            {props.children}
          </WorkStepContainerInner>
        </RowContainer>
      </WorkStepContainer>
    );
  },
  (prev, next) => {
    if (
      prev.active &&
      prev.active?.workStep &&
      next.workStep.id === prev.active.workStep?.id
    ) {
      return false;
    }

    if (next.workStep.error || (prev.workStep.error && !next.workStep.error)) {
      return false;
    }

    const isWorkstepEqual = prev.workStep.position === next.workStep.position;

    // prettier-ignore
    // eslint-disable-next-line
    const isActiveWorkstep = next.active?.workStep && next.workStep.id === next.active.workStep?.id? false: true;

    // eslint-disable-next-line
    return isWorkstepEqual && isActiveWorkstep;
  }
);
