export function typedKeys<T>(o: T): (keyof T)[] {
  // type cast should be safe because that's what really Object.keys() does
  return Object.keys(o) as (keyof T)[];
}

export const regExpHelper = {
  splitIntoBulks: (str?: string) =>
    str ? str.match(/<(.*?)>|{(.*?)}|\[(.*?)\]|(\w+)/g) : [],
  removeGreaterThan: (str: string) => str.replace(/<|>/g, ''),
};
