import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Menu, Button } from '@cognite/cogs.js';
import { SearchState } from 'redux/reducers/search';
import { RootState } from 'redux/store';
import { useProjectLoad } from 'hooks/useProjectLoad';

const CreateProcedureButton = () => {
  const [activeChooseFlocModal, setActiveChooseFlocModal] = useState(false);

  const { assets, selectedRootAsset } = useSelector<RootState, SearchState>(
    (state) => {
      return state.search;
    }
  );

  const { createNewProcedure } = useProjectLoad();

  return (
    <>
      <Modal
        onCancel={() => setActiveChooseFlocModal(false)}
        footer={null}
        visible={activeChooseFlocModal}
        width={750}
      >
        {assets.length
          ? assets.map((asset) => {
              return (
                <Menu.Item
                  key={`asset-menu-${asset.assetId}`}
                  onClick={() => createNewProcedure(asset, selectedRootAsset)}
                  style={{ textAlign: 'left' }}
                >
                  {`${asset.assetFloc.split('.').pop()} | ${
                    asset.assetName
                  } | ${asset.assetDescription}`}
                </Menu.Item>
              );
            })
          : 'Please start searching for FLOC in the input field'}
      </Modal>
      <Button
        data-testid="createProcedure"
        type="primary"
        onClick={() => {
          setActiveChooseFlocModal(true);
        }}
      >
        Create new procedure
      </Button>
    </>
  );
};

export default CreateProcedureButton;
