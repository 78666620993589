import {
  Item,
  Phase,
  PhaseType,
  Project,
  ProjectStatus,
  Reference,
  StepExecutionDetails,
  StepItem,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

import uniqueId from 'lodash/uniqueId';
import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from '@cognite/annotations-experimental';
import { PendingCogniteAnnotation } from '@cognite/annotations';
import {
  InstallBlindAndTag,
  RemoveItem,
  TagCloseAction,
  TagOpenAction,
  Valve,
  types,
  OpenAction,
} from './actions';

export const createIsolationProject = ({
  id = uniqueId(),
  name = 'ProjectName',
  compiler = 'Compiler',
  objectives = 'Serious Objectives',
  status = 'compilation' as ProjectStatus,
  ...rest
}): Project => {
  return {
    id,
    name,
    fileName: name,
    created: 1603194605971,
    compiler,
    objectives,
    status,
    ...rest,
  };
};

export const projects = [
  createIsolationProject({
    id: '1',
    name: 'V-9660 Internal Inspection',
    created: 1603194605971,
    compiler: 'Peter',
    objectives:
      'Isolate, drain and de-pressure V-9660 in a way that the platform is not destroyed.',
  } as Project),
  createIsolationProject({
    id: '2',
    name: 'U-0550 Internal Inspection',
    created: 1603194605971,
    compiler: 'Peter Again',
    objectives: 'Not really sure what we want',
  } as Project),
  createIsolationProject({
    id: '3',
    created: 1603194605971,
    name: 'Created by mistake',
    compiler: 'Foo',
  } as Project),
  createIsolationProject({
    id: '4',
    created: 1603194605971,
    name: '',
    compiler: '',
  } as Project),
];

export const AttachedTo = {
  id: uuidv4(),
  text: 'attached to',
  description: 'Attached to',
};

export const Upstream = {
  id: uuidv4(),
  text: 'upstream of',
  description: 'Upstream of',
};

export const Downstream = {
  id: uuidv4(),
  text: 'downstream of',
  description: 'Downstream of',
};

export const On = {
  id: uuidv4(),
  text: 'on',
  description: 'On',
};

export const references: { [key: string]: Array<Reference> } = {
  nozzle: [AttachedTo],
  asset: [Upstream, Downstream, On],
  vessel: [Upstream, Downstream, On],
  pipe: [Upstream, Downstream, On],
  'pipe-vessel': [Upstream, Downstream, On],
  instrument: [Upstream, Downstream, On],
  motor: [Upstream, Downstream, On],
  pump: [Upstream, Downstream, On],
  heater: [Upstream, Downstream, On],
  line: [On],
};

export const DetailItem = {
  detail: '15V77',
  type: Valve,
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
};

export const AssetItem = {
  asset: {
    id: 12334,
    externalId: 'V-1070',
    name: 'V-1070',
  },
  type: types.find((it) => it.type === 'pump'),
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
};

export const itemWithLocationOnAnnotation = {
  detail: '15V77',
  type: Valve,
  annotation: createAnnotation({
    id: 46,
    data: {
      indirectExternalId: 'V-1540',
      indirectRelation: 'upstream of',
      lineExternalId: 'HC-6-20106',
      type: 'valve',
    },
    linkedResourceExternalId: '',
  }),
};

export const LineItem = {
  asset: {
    id: 3456,
    externalId: '3/8HF-50-1010',
    name: '3456',
    description: '',
    metadata: {},
  },
  indirectReference: On,
  annotation: createAnnotation({
    id: 45,
    data: {
      type: 'line',
      linkedResourceExternalId: '3/8HF-50-1010',
    },
  }),
};

export const itemForMechanicalValve = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  } as StepItem,
  annotation: createAnnotation({
    id: 46,
    data: { type: 'valve' },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithRelativePosition = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    data: { type: 'valve', relativePosition: '2nd' },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithSubDetail = {
  detail: '15V77',
  type: Valve,
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    data: {
      subDetail: 'LO',
    },
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const itemWithFloc = {
  asset: {
    id: 12334,
    externalId: 'V-1070',
    name: 'V-1070',
  },
  type: types.find((it) => it.type === 'motor'),
  indirectReference: Upstream,
  relativeRef: {
    asset: {
      id: 2345,
      externalId: 'SDV-5102D',
      name: 'SDV-5102D',
    },
    annotation: createAnnotation({
      id: 45,
      linkedResourceExternalId: 'SDV-5102D',
    }),
  },
  annotation: createAnnotation({
    id: 46,
    linkedResourceExternalId: '',
  }),
  line: LineItem,
};

export const mockPhase = {
  id: 'phaseId',
  name: 'Phase one',
  phaseType: 'isolation',
  position: 0,
  workSteps: [
    {
      id: '12345',
      position: 1,
      comment: 'Mr Foobar 1',
    },
    {
      id: '23456',
      position: 2,
      comment: 'Mr Foobar 2',
    },
  ],
};

export const phasesWithEmptyPhase = [
  {
    id: 'phase1',
    name: 'Phase one',
    phaseType: 'isolation',
    position: 0,
    workSteps: [
      {
        id: '12345',
        position: 1,
        comment: 'This is step 1',
      },
      {
        id: '23456',
        position: 2,
        comment: 'This is step 2',
      },
    ],
  },
  {
    id: 'phase2',
    name: 'Phase two',
    phaseType: 'isolation',
    position: 1,
    workSteps: [],
  },
];

export const phasesWithTwoEmptyPhases = [
  {
    id: 'phase1',
    name: 'Phase one',
    phaseType: 'isolation',
    position: 0,
    workSteps: [
      {
        id: '12345',
        position: 1,
        comment: 'This is step 1',
      },
      {
        id: '23456',
        position: 2,
        comment: 'This is step 2',
      },
    ],
  },
  {
    id: 'phase2',
    name: 'Phase two',
    phaseType: 'isolation',
    position: 1,
    workSteps: [],
  },
  {
    id: 'phase2deiso',
    name: 'Phase two - deisolation',
    phaseType: 'deisolation',
    deIsolation: true,
    linkedPhaseId: 'phase2',
    position: 2,
    workSteps: [],
  },
  {
    id: 'phase1deiso',
    name: 'Phase one - deisolation',
    phaseType: 'deisolation',
    deIsolation: true,
    linkedPhaseId: 'phase1',
    position: 3,
    workSteps: [
      {
        id: '1152345',
        position: 3,
        comment: 'This is step 3 - deisolation',
      },
      {
        id: '1233456',
        position: 4,
        comment: 'This is step 4 - deisolation',
      },
    ],
  },
];

export const phasesWorkstepInIsolationStarted = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: new StepExecutionDetails({
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        }),
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWorkstepInNotStarted = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWithReviewComments = [
  {
    id: 'A',
    name: 'Phase one',
    position: 0,
    phaseType: 'isolation',
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        safeguarding: 'LC',
        comment: 'Must check with the checker',
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  } as Phase,
  {
    id: 'B',
    name: 'Phase two',
    position: 1,
    phaseType: 'isolation',
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        review: {
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: OpenAction,
        stepItem: itemForMechanicalValve,
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
            reviewer: {
              name: 'testing.jest@cognite.com',
              date: 1607751145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
          ],
        },
      },
      {
        id: '6',
        position: 6,
        stepAction: OpenAction,
        stepItem: itemForMechanicalValve,
        review: {
          resolved: {
            compiler: {
              name: 'jest.testing@cognite.com',
              date: 1607551145690,
            },
          },
          comments: [
            {
              id: '1',
              commentContent: 'Testing',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607351145690,
            },
            {
              id: '2',
              commentContent: 'Rejected',
              author: 'testing.jest@cognite.com',
              role: 'reviewer',
              date: 1607651145690,
            },
          ],
        },
      },
    ],
  } as Phase,
];

export const phasesWorkstepInDeisolation = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        execution: new StepExecutionDetails({
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        }),
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesWorkstepInDeisolationDone = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
] as Phase[];

export const phasesFirstIsolationPhaseApproved = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phasesFirstDeisolationPhaseApproved = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'deisolation' as PhaseType,
    position: 0,
    approvedBy: 'test@test.com',
    approvedTime: 1667912304194,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
        execution: {
          doneBy: 'test@test.com',
          checkedBy: 'test2@test.com',
          executionStopped: false,
        },
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'deisolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
] as Phase[];

export const phases = [
  {
    id: 'A',
    name: 'Phase one',
    phaseType: 'isolation' as PhaseType,
    position: 0,
    workSteps: [
      {
        id: '1',
        position: 1,
        stepAction: TagCloseAction,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '2',
        position: 2,
        stepAction: TagOpenAction,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
  {
    id: 'B',
    name: 'Phase two',
    phaseType: 'isolation' as PhaseType,
    position: 1,
    workSteps: [
      {
        id: '3',
        position: 3,
        stepAction: RemoveItem,
        stepItem: itemForMechanicalValve,
      },
      {
        id: '4',
        position: 4,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
        comment: 'Must check with the checker',
      },
      {
        id: '5',
        position: 5,
        stepAction: InstallBlindAndTag,
        stepItem: itemForMechanicalValve,
      },
    ],
  },
];

export const mockAssets: Array<Item> = [
  createAsset({
    id: 2345,
    externalId: '2345',
    name: '15V77',
    type: 'asset',
  }),
  createAsset({
    id: 3456,
    externalId: '3456',
    name: 'SDV-1011',
    type: 'asset',
  }),
  createAsset({
    id: 4567,
    externalId: '4567',
    name: '3/8HF-50-1010',
    type: 'line',
  }),
  createAsset({
    id: 5678,
    externalId: '5678',
    name: 'SDV-5102D',
    type: 'asset',
  }),
];

export function createAnnotation({
  id = 1,
  text = 'Annotation',
  linkedResourceId,
  linkedResourceExternalId,
  linkedResourceType = 'asset',
  annotatedResourceId = 9876,
  annotatedResourceType = 'file',
  annotationType = 'resource_reference',
  status = 'verified',
  source = 'test',
  data = {},
  region = {
    type: 'boundingBox',
    xMax: 0.9518436429578884,
    xMin: 0.9258512996171671,
    yMax: 0.6309489883157595,
    yMin: 0.6261043032202909,
  },
  createdTime = new Date(),
  lastUpdatedTime = new Date(),
}: any): CogniteAnnotation {
  return {
    id,
    text,
    linkedResourceId,
    linkedResourceType,
    linkedResourceExternalId,
    annotatedResourceId,
    annotatedResourceType,
    annotationType,
    data,
    status,
    source,
    region,
    createdTime,
    lastUpdatedTime,
  };
}

export function createAnnotationSpec({
  text = 'Annotation',
  linkedResourceId,
  linkedResourceExternalId,
  linkedResourceType = 'asset',
  annotatedResourceId = 9876,
  annotatedResourceType = 'file',
  annotationType = 'resource_reference',
  status = 'verified',
  source = 'test',
  data = {},
  region = {
    type: 'boundingBox',
    xMax: 0.9518436429578884,
    xMin: 0.9258512996171671,
    yMax: 0.6309489883157595,
    yMin: 0.6261043032202909,
  },
}: any): CogniteAnnotationSpec {
  return {
    text,
    linkedResourceId,
    linkedResourceType,
    linkedResourceExternalId,
    annotatedResourceId,
    annotatedResourceType,
    annotationType,
    data,
    status,
    source,
    region,
  };
}

export function createPendingAnnotation({
  type = 'annotation',
  label = 'label',
  status = 'verified',
  box = {
    xMax: 0.9518436429578884,
    xMin: 0.9258512996171671,
    yMax: 0.6309489883157595,
    yMin: 0.6261043032202909,
  },
  version = 1,
  source = 'test',
}: any): PendingCogniteAnnotation {
  return {
    type,
    label,
    status,
    source,
    version,
    box,
  };
}

export function createAsset({
  id,
  externalId,
  name,
  type,
}: {
  id: number;
  externalId: string;
  name: string;
  type: string;
}) {
  return {
    id,
    externalId,
    rootId: 0,
    name,
    description: '',
    metadata: {
      type,
    },
  };
}

export function cloneMockData(mockData: any) {
  return JSON.parse(JSON.stringify(mockData));
}

export const assetTypes = [
  { type: 'valve', description: 'Valve' },
  { type: 'spade', description: 'Spade' },
];

export * from 'utils/test/actions';
