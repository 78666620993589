import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
} from '@cognite/annotations-experimental';
import { ColorFiles } from 'types';
import { assetColor, nextColorFromList } from 'utils/colors';

export class AnnotationsColorsService {
  updatedAnnotationsColors(
    state: any,
    fileAnnotations: Array<CogniteAnnotation | CogniteAnnotationSpec>
  ) {
    // const colors = current(state).fileColors;
    const colors = JSON.parse(JSON.stringify(state.fileColors));
    const filteredFileIds = fileAnnotations
      .map((fileAnnotation) => fileAnnotation.linkedResourceId)
      .filter((id) => !colors.find((color: ColorFiles) => color.id === id));
    const uniqueFileColors = [...new Set(filteredFileIds)].map((id, index) => ({
      id,
      color: nextColorFromList(colors.length + index),
    }));

    return [...colors, ...uniqueFileColors];
  }

  colorAnnotations(
    state: any,
    rawAnnotations: Array<CogniteAnnotation | CogniteAnnotationSpec>
  ) {
    const fileAnnotations = rawAnnotations.filter(
      (annotation) =>
        annotation.annotatedResourceType === 'file' &&
        // @ts-ignore
        annotation.region.type === 'boundingBox' &&
        annotation.linkedResourceId
    );
    const colors = this.updatedAnnotationsColors(state, fileAnnotations);
    const annotations = rawAnnotations.map(
      (annotation: CogniteAnnotation | CogniteAnnotationSpec) => {
        const annotationId = annotation.linkedResourceId;
        const annotationColor = colors.find(
          (color: ColorFiles) => color.id === annotationId
        ) ?? { color: '#1c1c1c' };

        const newAnnotation = ({
          ...annotation,
          box: annotation.region,
        } as unknown) as CogniteAnnotation & { mark: any; color: string };
        if (annotation.linkedResourceType === 'asset') {
          newAnnotation.mark = {
            strokeWidth: 0,
            backgroundColor: assetColor,
          };
        }
        if (annotation.linkedResourceType === 'file') {
          newAnnotation.mark = {
            strokeWidth: 2,
            strokeColor: `${annotationColor.color}33`,
            backgroundColor: `${annotationColor.color}55`,
          };
          newAnnotation.color = annotationColor.color;
        }
        return newAnnotation;
      }
    );
    return ((annotations ?? []) as unknown) as (CogniteAnnotation & {
      mark: any;
      color: string;
    })[];
  }

  saveFileColor(
    annotation: CogniteAnnotation | CogniteAnnotationSpec,
    color: string
  ): ColorFiles {
    return { id: annotation.linkedResourceId ?? 0, color };
  }
}
