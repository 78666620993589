import { Button, Icon } from '@cognite/cogs.js';
import React, { useState } from 'react';

interface ExpandableTextProps {
  childrenText: string;
}

export const ExpandableText = ({ childrenText }: ExpandableTextProps) => {
  const [expanded, setExpanded] = useState(false);

  const contentCollapsedHeight = 85;
  const contentMaxNumberOfCharacters = 134;

  const onClickAction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div className="comment-non-active">
      <div
        className="inner"
        style={{
          paddingRight: '20px',
          maxHeight: expanded ? undefined : contentCollapsedHeight,
        }}
      >
        {childrenText}
      </div>
      {childrenText.length > contentMaxNumberOfCharacters && (
        <Button unstyled onClick={(event) => onClickAction(event)}>
          {expanded ? 'Less' : 'More'}
          <Icon type={expanded ? 'ChevronUpSmall' : 'ChevronDownSmall'} />
        </Button>
      )}
    </div>
  );
};
