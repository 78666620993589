import { Colors } from '@cognite/cogs.js';

export const assetColor = `${Colors['yellow-4'].hex()}66`;
export const userAssetColors = `${Colors['lightblue-4'].hex()}66`;
export const defaultColors = [
  '#D46AE2',
  '#6ED8BE',
  '#A4B2FC',
  '#FFB38B',
  '#FDCED6',
  '#BFBFBF',
];
const backgroundsThatGiveWhiteText = ['#923FA7', '#4A67FB', '#008000'];

// this gives us more contrasting light levels if we use the colors' array in order
export const cogsColors = [1, 5, 2, 4, 3]
  .map((number: number) =>
    ['purple', 'midorange', 'midblue', 'pink'].map((color: string) =>
      Colors[`${color}-${number}`].hex()
    )
  )
  .flat();

export const randomColor = (colors: string[]) => {
  const max = colors.length;
  const randomNr = Math.floor(Math.random() * Math.floor(max));
  return colors[randomNr];
};

export const nextColorFromList = (index: number) => {
  const colors = [...defaultColors, ...cogsColors];
  const { length } = colors;
  const adjustedIndex = index >= length ? index % length : index;
  return colors[adjustedIndex];
};

export const getTextColor = (backgroundColor?: string): string => {
  if (
    !!backgroundColor &&
    backgroundsThatGiveWhiteText.includes(backgroundColor)
  ) {
    return 'white';
  }
  return 'black';
};
