import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu } from '@cognite/cogs.js';
import searchSlice, { SearchState } from 'redux/reducers/search';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import { AssetMappingType } from 'types';
import { Button } from './elements';

const AssetSelectorButton = () => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    rootAssetsContent,
    selectedRootAsset,
    selectedRootAssetName,
    statusRootAssets,
  } = useSelector<RootState, SearchState>((state) => {
    return state.search;
  });

  const areRootAssetsLoading = statusRootAssets === Status.loading;
  const areRootAssetsSuccess = statusRootAssets === Status.success;

  const onSelection = (asset: AssetMappingType) => {
    dispatch(searchSlice.actions.setSelectedRootAsset(asset));
    setDropdownOpen(false);
  };

  const DropdownContent = (
    <Menu>
      {rootAssetsContent.map((asset, i) => (
        <Menu.Item
          key={`asset-selector-button-${i + 1}`}
          onClick={() => onSelection(asset)}
          selected={
            selectedRootAsset.toUpperCase() === asset.assetFloc.toUpperCase()
          }
        >
          <span data-testid={`asset-selector-button-${i + 1}`}>
            {asset.assetName}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      content={DropdownContent}
      visible={dropdownOpen}
      onClickOutside={() => setDropdownOpen(false)}
    >
      <Button
        unstyled
        data-testid="asset-selector-drawer-button"
        icon="ChevronDown"
        aria-label="down"
        loading={areRootAssetsLoading}
        iconPlacement="right"
        className="asset-selector-button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        title="Select asset"
      >
        {areRootAssetsSuccess && selectedRootAssetName}
      </Button>
    </Dropdown>
  );
};

export default AssetSelectorButton;
