import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PendingCogniteAnnotation } from '@cognite/annotations';
import {
  CogniteFileViewer,
  RenderItemPreviewFunction,
  ViewerEditCallbacks,
} from '@cognite/react-picture-annotation';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import { useHotSpot } from 'redux/reducers/hotSpot/hooks';
import { actions, FilesState } from 'redux/reducers/files';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { useAnnotations } from 'redux/reducers/annotations';
import { ProjectState } from 'redux/reducers/project';
import { useProcedureFilesLoad } from 'hooks/useProcedureFilesLoad';
import { Flex, toast, Icon, Button } from '@cognite/cogs.js';
import { KeyValuePair, WorkStep } from 'types';
import { cookieServices, workstepsService } from 'services';
import useMetrics from 'hooks/useMetrics';
import { METRICS, METRICS_AREA } from 'config/mixpanelConfig';
import { useDrawings } from 'hooks/useDrawings';
import debounce from 'lodash/debounce';
import { AnnotationForm } from '../AnnotationForm';
import { StepBox } from './StepBox';
import SideDrawer from './SideDrawer';
import { showFile } from './ShowFile';
import {
  FilePreviewWrapper,
  FileRefreshButton,
  Loader,
  LoaderWrapper,
  FileQualityButtons,
} from './elements';
import { useSideDrawerFilesActions } from './hooks/useSideDrawerFilesActions';
import { usePnidZoom, ViewerQuality } from './hooks/usePnidZoom';
import { PnidMiniWidget } from './PnidMiniWidget';
import { getClient } from '../../../../config/cognitesdk';

type PnidPreviewProps = {
  fileIds: number[];
  newProject: boolean;
  annotations?: any[];
  workStepsFileIds: number[];
  renderItemPreview?: RenderItemPreviewFunction;
  setWorkStepsFileIds: Function;
  viewOnly: boolean;
};

export const FilePreview = (props: PnidPreviewProps) => {
  const { fileIds, renderItemPreview, viewOnly } = props;
  const dispatch = useDispatch();
  const metrics = useMetrics(METRICS_AREA.PNID_LOAD);
  const { selectHotSpot } = useHotSpot();
  const { project, newProject } = useSelector<RootState, ProjectState>(
    (state: RootState) => state.project
  );

  const {
    active,
    workStepsLoaded,
    phases,
    selectWorkStep,
    workStepsContainingAnnotation,
    getFirstPnidId,
    countAllWorkSteps,
    fetchFileForActiveWorkstep,
    cancelActive,
    setWorkStepAnnotationCustomOffset,
    getWorkstepPairs,
  } = useWorkSteps();

  const {
    file,
    fileId,
    files,
    connectedFiles,
    fetchFile,
    setFileId,
    onAddFile,
    onFileRemoved,
    onFileSelected,
    chooseStartingFile,
  } = useSideDrawerFilesActions();

  const [isReady, setIsReady] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { preloadFilesWithAnnotations } = useProcedureFilesLoad();
  const {
    fetchDrawingForPnid,
    saveDrawingForPnid,
    getEmptyDrawingContent,
    preloadDrawingsForProcedure,
  } = useDrawings();
  const [showLoader, setShowLoader] = useState(false);
  const [pnidLoadStartTime, setPnidLoadStartTime] = useState(0);
  const [drawing, setDrawing] = useState<string | undefined>(undefined);

  const isExecutionView = project.status === 'execution';

  const quality = cookieServices.getFilePreviewQuality() || ViewerQuality.HIGH;
  const fileViewerQuality = useMemo(() => {
    // Returning undefined will make scale depending on the screen size
    if (!isExecutionView) {
      // Always show the highest quality for editing procedure
      return undefined;
    }
    if (quality === ViewerQuality.LOW) {
      return 1;
    }
    if (quality === ViewerQuality.MEDIUM) {
      return 3;
    }
    return undefined;
  }, [quality, isExecutionView]);

  const {
    curZoomedAnnotation,
    zoomOnAnnotation,
    setZoomedAnnotation,
  } = usePnidZoom(quality);

  const numberOfFiles = Number(fileIds?.length);
  const noWorksteps = countAllWorkSteps() === 0;

  const {
    editMode,
    newAnnotation,
    fileColors,
    fetchAnnotations,
    createNewAnnotation,
    highlightedAnnotations,
    setAnnotationCustomOffset,
    annotationsWithCustomOffset,
  } = useAnnotations();

  const fetchDrawing = useCallback(
    (fileIdNumber: number) => {
      fetchDrawingForPnid(
        fileIdNumber,
        project.id as string,
        project.pnIdsDrawings as KeyValuePair
      )
        .then((drawingContent) => {
          setDrawing(drawingContent);
        })
        .catch(() => setDrawing(getEmptyDrawingContent()));
    },
    [
      fetchDrawingForPnid,
      getEmptyDrawingContent,
      project.id,
      project.pnIdsDrawings,
    ]
  );

  const { status: statusFiles } = useSelector<RootState, FilesState>(
    (state) => state.files
  );
  const statusAnnotations = useSelector<RootState>(
    (state: RootState) => state.annotations.status
  ) as Status;
  const statusProcedureAnnotations = useSelector<RootState>(
    (state: RootState) => state.annotations.annotationsLoadedStatus
  ) as Status;

  const isFileFullyLoaded =
    statusFiles === Status.success &&
    statusAnnotations === Status.success &&
    statusProcedureAnnotations === Status.success &&
    isReady;

  // show sidebar if no worksteps and have files
  useEffect(() => {
    if (numberOfFiles > 1 && workStepsLoaded && isReady && noWorksteps) {
      setIsDrawerOpen(true);
    }
    // eslint-disable-next-line
  }, [numberOfFiles, workStepsLoaded, isReady, noWorksteps]);

  // Listen for file change and set the local fileId vairable
  // Internally the fileId is used to detect changes
  useEffect(() => {
    if (file.id !== fileId) {
      setFileId(file.id);
    }
  }, [file.id, fileId, setFileId]);

  // When the active workstep is changed
  // zoom the P&ID to the active ws
  useEffect(() => {
    if (!showLoader && active.workStep && project.status !== 'compilation') {
      zoomOnAnnotation(active);
    }
    // eslint-disable-next-line
  }, [active, showLoader, zoomOnAnnotation]);

  // Init hook, first load, it preloads the P&IDs, annotations...etc.
  useEffect(() => {
    if (
      workStepsLoaded &&
      statusProcedureAnnotations === Status.idle &&
      !isReady
    ) {
      setDrawing(getEmptyDrawingContent());
      preloadFilesWithAnnotations(project, phases, newProject, fileIds);
      preloadDrawingsForProcedure(project);
    }
    // eslint-disable-next-line
  }, [
    isReady,
    workStepsLoaded,
    statusProcedureAnnotations,
    props.newProject,
    phases,
    project,
    preloadFilesWithAnnotations,
    preloadDrawingsForProcedure,
    getEmptyDrawingContent,
  ]);

  // After preload is done, it selects the first P&ID from ws
  useEffect(() => {
    if (
      workStepsLoaded &&
      statusProcedureAnnotations === Status.success &&
      !isReady
    ) {
      // Read out if user has been working on different p&id and reloads
      const cookieInfo = cookieServices.getFilePreviewInfo();
      if (cookieInfo) {
        if (!fileIds.includes(cookieInfo.id)) {
          dispatch(actions.connectFile(cookieInfo.id));
          onAddFile(cookieInfo);
        } else {
          dispatch(actions.setActiveFile(cookieInfo.id));
          setFileId(cookieInfo.id);
        }
        // Remove cookie after reload so user gets initial p&id on next visit
        cookieServices.removeFilePreviewInfo();
      } else {
        const pnidFromWorsteps = getFirstPnidId();
        if (pnidFromWorsteps) {
          dispatch(actions.setActiveFile(pnidFromWorsteps));
        } else if (!pnidFromWorsteps && numberOfFiles === 1) {
          setFileId(fileIds[0]);
          dispatch(actions.setActiveFile(fileIds[0]));
        }
      }

      setIsReady(true);
    }
  }, [
    dispatch,
    isReady,
    workStepsLoaded,
    statusProcedureAnnotations,
    props.newProject,
    numberOfFiles,
    fileIds,
    getFirstPnidId,
    setFileId,
    fetchDrawing,
    onAddFile,
  ]);

  // Watch the active ws, when it is changed, the new file is loaded
  useEffect(() => {
    if (
      active.workStep?.id &&
      active.workStep.stepItem &&
      active.workStep.stepItem.annotation
    ) {
      const workStepFileId =
        active.workStep.stepItem?.annotation?.annotatedResourceId;
      if (fileId && fileId !== workStepFileId) {
        setShowLoader(true);
        setDrawing(getEmptyDrawingContent());

        const timeoutInMsToRedrawUI = project.status !== 'execution' ? 800 : 0;
        setPnidLoadStartTime(window.performance.now());
        // timeout is set to wait firt ui actions to finish
        // to avoid poor rendering performance
        // triggering setActiveFile will trigger other events
        // in order file to be fetched with all other things
        setTimeout(() => {
          fetchFileForActiveWorkstep(active.workStep as WorkStep);
        }, timeoutInMsToRedrawUI);
      }
    }

    // eslint-disable-next-line
  }, [active.workStep]);

  useEffect(() => {
    if (statusProcedureAnnotations === Status.failed) {
      toast.error(
        <div>
          Annotations for this procedure can not be fetched. Please try to
          refresh the page.
        </div>
      );
    }

    // eslint-disable-next-line
  }, [statusProcedureAnnotations]);

  useEffect(() => {
    const connectedWorkstepsFileIds = workstepsService.getWorkstepsFileIds(
      phases
    );
    if (connectedWorkstepsFileIds.length !== props.workStepsFileIds.length) {
      props.setWorkStepsFileIds(connectedWorkstepsFileIds);
    }

    // eslint-disable-next-line
  }, [connectedFiles, phases]);

  const onArrowBoxMove = useCallback(
    (arrowBox) => {
      setAnnotationCustomOffset(
        file.id,
        +arrowBox.annotationId,
        arrowBox.offsetX as number,
        arrowBox.offsetY as number
      );
      setWorkStepAnnotationCustomOffset(
        +arrowBox.annotationId,
        arrowBox.offsetX as number,
        arrowBox.offsetY as number
      );
    },
    [file.id, setAnnotationCustomOffset, setWorkStepAnnotationCustomOffset]
  );

  const onDrawingSaved = useCallback(
    (newDrawData: string) => {
      if (
        newDrawData &&
        newDrawData.length > 0 &&
        drawing !== String(newDrawData)
      ) {
        const projectId = project.id || 'undefined';
        saveDrawingForPnid(file.id, projectId, String(newDrawData));
      }
    },
    [drawing, file.id, project.id, saveDrawingForPnid]
  );

  const onAnnotationSelected = debounce((annotations: any[]) => {
    const annotation = annotations[0];
    const isFile = annotation?.linkedResourceType === 'file';
    const connectedFileId = annotation?.linkedResourceId;

    if (isFile && annotation?.linkedResourceId) {
      setShowLoader(true);
      setZoomedAnnotation(undefined);
      cancelActive();
      if (connectedFiles.includes(connectedFileId)) {
        dispatch(actions.setActiveFile(connectedFileId));
      } else {
        dispatch(actions.connectFile(annotation?.linkedResourceId));
        fetchFile(connectedFileId);
        const needsMigration =
          project.needsMigration && project.needsMigration === true;
        fetchAnnotations(connectedFileId, phases, needsMigration);
      }
    } else {
      selectHotSpot(annotation);
    }
  }, 200);

  const renderStepBox = (annotation: any) => {
    const workSteps = workStepsContainingAnnotation(annotation.id, true);
    if (workSteps.length) {
      const workstepPairs = getWorkstepPairs(workSteps);
      return (
        <StepBox
          active={active.workStep}
          workStepsPairs={workstepPairs}
          workSteps={workSteps}
          onSelected={selectWorkStep}
        />
      );
    }
    return undefined;
  };

  const callbacks: ViewerEditCallbacks = useMemo(
    () => ({
      onCreate: (annotation: PendingCogniteAnnotation) => {
        createNewAnnotation(annotation, file);
        return false;
      },
      onUpdate: (_annotation) => {
        return false;
      },
    }),
    [createNewAnnotation, file]
  );

  const setQuality = (qualityValue: ViewerQuality) => {
    cookieServices.setFilePreviewQuality(qualityValue);
    window.location.reload();
  };

  const FileViewer = (
    <CogniteFileViewer
      file={file}
      // The types in the CogniteFileViewer for the sdk are wrong, so using any to make ts happy
      sdk={getClient() as any}
      hoverable={false}
      // @ts-ignore
      annotations={highlightedAnnotations(file.id)}
      // @ts-ignore
      renderItemPreview={renderItemPreview}
      editCallbacks={callbacks}
      editable={!viewOnly && editMode}
      onAnnotationSelected={onAnnotationSelected}
      allowCustomAnnotations
      renderArrowPreview={renderStepBox}
      arrowPreviewOptions={{
        baseOffset: {
          x: -10,
          y: -60,
        },
        customOffset: annotationsWithCustomOffset(file.id),
      }}
      onArrowBoxMove={!viewOnly ? onArrowBoxMove : undefined}
      zoomOnAnnotation={curZoomedAnnotation as any}
      onFileLoaded={() => {
        setDrawing(undefined);
        if (pnidLoadStartTime) {
          const loadTime = window.performance.now() - pnidLoadStartTime;

          metrics.track(METRICS.PNID_LOAD, {
            fileId: file.id,
            fileName: file.name,
            fileRevision: file.metadata?.REVISION
              ? file.metadata?.REVISION
              : '',
            timeInMilliseconds: +loadTime.toFixed(2),
          });
          setPnidLoadStartTime(0);
        }
        setTimeout(() => {
          fetchDrawing(file.id);
          setShowLoader(false);
        }, 500); // wait for pnid to redraw
      }}
      hidePaintLayer={false}
      drawable={project.status === 'compilation' && !viewOnly}
      toolbarPosition={project.status === 'execution' ? 'topRight' : 'default'}
      loadedDrawData={drawing}
      onDrawingSaved={onDrawingSaved}
      disableAutoFetch
      hideLabel
      hideDownload
      hideSearch
      qualityScale={fileViewerQuality}
    />
  );

  const filePreview = (
    <>
      {showFile({
        fileId,
        fileIds,
        isFileFullyLoaded,
        FileViewer,
      })}
      {newAnnotation?.annotation && (
        <AnnotationForm
          annotation={newAnnotation?.annotation}
          oldAnnotation={newAnnotation?.oldAnnotation}
          procedureNeedsMigration={project.needsMigration}
        />
      )}
    </>
  );

  return (
    <FilePreviewWrapper
      data-testid="pnidPreview"
      data-activefileid={fileId || ''}
    >
      <LoaderWrapper
        data-testid="pnid-main-loader"
        className={showLoader ? 'visible' : ''}
      >
        <Loader />
      </LoaderWrapper>
      {isExecutionView ? (
        <>
          <FileRefreshButton
            type="secondary"
            onClick={() => {
              cookieServices.setFilePreviewInfo(file);
              window.location.reload();
            }}
          >
            <Icon
              type="Warning"
              style={{
                marginRight: '10px',
              }}
            />
            Reload
          </FileRefreshButton>
          <FileQualityButtons>
            <Button
              type={quality === ViewerQuality.LOW ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.LOW)}
            >
              Low
            </Button>
            <Button
              type={quality === ViewerQuality.MEDIUM ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.MEDIUM)}
            >
              Med
            </Button>
            <Button
              type={quality === ViewerQuality.HIGH ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.HIGH)}
            >
              High
            </Button>
          </FileQualityButtons>
        </>
      ) : null}
      {filePreview}
      {project.status !== 'execution' && workStepsLoaded && isReady && (
        <SideDrawer
          viewOnly={viewOnly}
          fileId={fileId}
          fileIds={fileIds}
          workStepsFileIds={props.workStepsFileIds}
          files={files}
          activeFile={file}
          fileColors={fileColors}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          onFileSelect={onFileSelected}
          onChooseStartingFile={chooseStartingFile}
          onFileRemove={onFileRemoved}
          onAddFile={onAddFile}
        />
      )}
    </FilePreviewWrapper>
  );
};

const FilePreviewWithProvider = (props: PnidPreviewProps) => {
  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <PnidMiniWidget />
      <FilePreview {...props} />
    </Flex>
  );
};

export { FilePreviewWithProvider as PnidPreview };
