import React from 'react';
import { Button, Checkbox, Input } from '@cognite/cogs.js';
import { Formik } from 'formik';
import {
  FilterBar,
  FilterInputs,
  FilterActions,
  Filter,
  FilterLabel,
  FilterInputRow,
} from '../../../containers/FilterBar/FilterBar';

export interface LibrarySearchFilters {
  procedureTitle?: string;
  externalAssetId?: string;
  compilerName?: string;
  onlyApprovedProcedures?: boolean;
  archive?: boolean;
  icNumber?: string;
}

export interface SearchBarProps {
  onSearch?: (filters: LibrarySearchFilters) => void;
  onClear?: () => void;
}

export const SearchBar = ({ onSearch, onClear }: SearchBarProps) => {
  return (
    <Formik
      initialValues={{
        procedureTitle: '',
        externalAssetId: '',
        compilerName: '',
        onlyApprovedProcedures: false,
        archive: true,
        icNumber: '',
      }}
      onSubmit={(values) => {
        if (onSearch) {
          onSearch(values);
        }
      }}
      onReset={() => {
        if (onClear) {
          onClear();
        }
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, handleReset }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <FilterBar>
            <FilterInputs>
              <FilterInputRow>
                <Filter style={{ maxWidth: '200px' }}>
                  <FilterLabel>Procedure</FilterLabel>
                  <Input
                    placeholder="Procedure title"
                    name="procedureTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.procedureTitle}
                    data-testid="name-search-input"
                  />
                </Filter>
                <Filter style={{ maxWidth: '200px' }}>
                  <FilterLabel>FLOC</FilterLabel>
                  <Input
                    placeholder="FLOC ID"
                    name="externalAssetId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalAssetId}
                    data-testid="floc-search-input"
                  />
                </Filter>
                <Filter style={{ maxWidth: '200px' }}>
                  <FilterLabel>IC number</FilterLabel>
                  <Input
                    placeholder="IC number"
                    name="icNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.icNumber}
                    data-testid="ic-search-input"
                  />
                </Filter>
                <Filter style={{ maxWidth: '200px' }}>
                  <FilterLabel>Compiler</FilterLabel>
                  <Input
                    placeholder="Compiler name"
                    name="compilerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.compilerName}
                    data-testid="compiler-search-input"
                  />
                </Filter>
              </FilterInputRow>{' '}
              <FilterInputRow>
                <Filter style={{ minWidth: '255px', maxWidth: '255px' }}>
                  <FilterLabel>Approved procedures</FilterLabel>
                  <Checkbox
                    name="onlyApprovedProcedures"
                    onChange={(value, e) => handleChange(e)}
                    checked={values.onlyApprovedProcedures}
                    data-testid="approved-procedures-search-toggle"
                  >
                    Show only approved procedures
                  </Checkbox>
                </Filter>
                <Filter style={{ minWidth: '200px', maxWidth: '200px' }}>
                  <FilterLabel>Archive</FilterLabel>
                  <Checkbox
                    name="archive"
                    data-testid="archive-search-toggle"
                    onChange={(value, e) => handleChange(e)}
                    checked={values.archive}
                  >
                    Hide in status `done`
                  </Checkbox>
                </Filter>
              </FilterInputRow>
            </FilterInputs>
            <FilterActions>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="procedure-search-button"
              >
                Search
              </Button>
              <Button type="ghost" htmlType="reset">
                Clear
              </Button>
            </FilterActions>
          </FilterBar>
        </form>
      )}
    </Formik>
  );
};
