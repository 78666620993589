import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { WorkStep } from 'types';
import { actions, ActiveWorkStepState } from '.';

export const useActiveWorkStep = () => {
  const dispatch = useDispatch();

  const {
    active,
    templates,
    referenceTypes,
    itemTypes,
    classTypes,
    selectionMode,
  } = useSelector<RootState, ActiveWorkStepState>(
    (state) => state.activeWorkStep
  );

  const setActive = useCallback(
    (workStep: WorkStep) => {
      dispatch(actions.setActive({ workStep }));
    },
    [dispatch]
  );

  const setOverlayClicked = useCallback(() => {
    if (!active.overlayClicked) {
      dispatch(actions.setOverlayClicked());
    }
  }, [dispatch, active.overlayClicked]);

  return {
    referenceTypes,
    active,
    setActive,
    setOverlayClicked,
    templates,
    itemTypes,
    classTypes,
    selectionMode,
  };
};
