/* eslint-disable no-empty-function */
import { PhasesService } from 'services/phases.service';
import { WorkstepsBuilderService } from 'services/worksteps-builder.service';
import { WorkstepsService } from 'services/worksteps.service';
import {
  BaseWorkstepsActionDTO,
  Phase,
  PhaseType,
  WorkStep,
  WorkstepsStateVM,
} from 'types';

export class UpdateWorkStepCommand {
  constructor(
    private workstepsService: WorkstepsService,
    private phasesService: PhasesService,
    private workstepsBuilderService: WorkstepsBuilderService
  ) {}

  execute(
    state: WorkstepsStateVM,
    dto: BaseWorkstepsActionDTO
  ): WorkstepsStateVM {
    const { phaseId, workStep } = dto;
    const { phases } = state;
    const phaseIdx = this.workstepsService.findPhaseIndex(phases, phaseId);

    if (phaseIdx === -1) {
      return state;
    }

    const phaseType = this.getPhaseType(phases[phaseIdx]);
    const linkedWorkStep = state.hasDeIsolationPhases
      ? this.workstepsService.findLinkedWorkStep(
          phases,
          phaseIdx,
          workStep.id,
          phaseType
        )
      : null;

    phases[phaseIdx].workSteps = this.updateWorkStep(
      phases,
      phaseIdx,
      workStep
    );

    if (
      state.hasDeIsolationPhases &&
      linkedWorkStep &&
      linkedWorkStep?.workStepType === 'missing'
    ) {
      const linkedPhaseId = this.phasesService.getLinkedPhaseId(
        phases[phaseIdx],
        phaseType
      );
      const linkedPhaseIdx = this.phasesService.getLinkedPhaseIndex(
        phases,
        linkedPhaseId,
        phaseType
      );

      phases[linkedPhaseIdx].workSteps = this.updateLinkedWorkStep(
        phases,
        linkedPhaseIdx,
        workStep,
        linkedWorkStep
      );
    }

    return state;
  }

  private updateLinkedWorkStep(
    phases: Phase[],
    phaseIdx: number,
    workStep: WorkStep,
    linkedWorkStep: WorkStep
  ): WorkStep[] {
    return phases[phaseIdx].workSteps.map((curWorkStep) => {
      if (
        curWorkStep.id === linkedWorkStep.id &&
        curWorkStep.position === linkedWorkStep.position
      ) {
        const workstepToUpdate = {
          ...linkedWorkStep,
          safeguarding: workStep.safeguarding,
          tag: workStep.tag,
          tagNumber: workStep.tagNumber,
          stepItem: workStep.stepItem,
          stepAction: workStep.stepAction,
        };
        const updatedWorkstep = this.workstepsBuilderService.buildDeIsolationWorkStep(
          workStep,
          linkedWorkStep.position,
          workstepToUpdate
        );
        updatedWorkstep.workStepType = 'missing';
        updatedWorkstep.isDraft = true;

        return updatedWorkstep;
      }
      return curWorkStep;
    });
  }

  private updateWorkStep(
    phases: Phase[],
    phaseIdx: number,
    workStep: WorkStep
  ): WorkStep[] {
    return phases[phaseIdx].workSteps.map((curWorkStep) => {
      if (
        curWorkStep.id === workStep.id &&
        curWorkStep.position === workStep.position
      ) {
        return { ...workStep };
      }
      return curWorkStep;
    });
  }

  private getPhaseType(phase: Phase): PhaseType {
    return phase.phaseType || 'isolation';
  }
}
