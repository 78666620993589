import React, { useCallback } from 'react';
import { drawingsResourceService } from 'resources';
import {
  DrawingDTO,
  FetchDrawingsForProcedureDTO,
  KeyValuePair,
  Project,
} from 'types';
import { logger } from 'utils/logger';
import { actions } from 'redux/reducers/project/projectReducer';
import { useDispatch } from 'react-redux';
import { showErrorNotification } from 'utils/errors';
import { HIGHLIGHTER_DATASET_ID } from '../config/apiConfig';
import { useGlobal } from '../redux/reducers/global/hooks';

export const useDrawings = () => {
  const dispatch = useDispatch();

  const { getCurrentDataSetId } = useGlobal();

  const fetchDrawingsForAllPnids = useCallback(
    (dto: FetchDrawingsForProcedureDTO) => {
      try {
        const drawingsPromises = dto.fileIds.map((fileId) =>
          drawingsResourceService.fetchDrawing(fileId)
        );

        return Promise.all(drawingsPromises);
      } catch (err) {
        logger(err, `Could not make fetch highlighter drawings calls`);
        return Promise.resolve([]);
      }
    },
    []
  );

  const copyDrawingsToNewProcedure = useCallback(
    (project: Project, pnidsToUpdateMapping: KeyValuePair) => {
      if (!project.pnIdsDrawings || !Object.keys(project.pnIdsDrawings)) {
        return Promise.resolve<KeyValuePair>({});
      }
      const drawingIds = Object.values(project.pnIdsDrawings);

      return new Promise<KeyValuePair>((resolve) => {
        fetchDrawingsForAllPnids({ fileIds: drawingIds })
          .then((responses: DrawingDTO[]) => {
            const updatedDrawingsMap = {} as KeyValuePair;
            const projectId = project.id as string;
            const saveDrawingsPromises = responses.map((drawingDto) => {
              const pnIdFromDrawing = drawingsResourceService.getPnIdFromDrawingId(
                drawingDto.externalId
              );

              // eslint-disable-next-line no-prototype-builtins
              const pnIdToUse = pnidsToUpdateMapping.hasOwnProperty(
                pnIdFromDrawing
              )
                ? pnidsToUpdateMapping[pnIdFromDrawing]
                : pnIdFromDrawing;

              const newDrawingId = drawingsResourceService.getDrawingId(
                pnIdToUse,
                projectId
              );
              updatedDrawingsMap[pnIdToUse] = newDrawingId;
              return drawingsResourceService.saveDrawing(
                pnIdToUse,
                projectId,
                drawingDto.content,
                getCurrentDataSetId(HIGHLIGHTER_DATASET_ID)
              );
            });

            Promise.all(saveDrawingsPromises).then(() => {
              resolve(updatedDrawingsMap);
            });
          })
          .catch((error) => {
            const errorMsg = (
              <div data-testid="migrate-drawings-error-msg">
                <h3>Something went wrong</h3> We were not able to copy the
                drawings for Procedure. Try again.
              </div>
            );
            showErrorNotification({ message: errorMsg, error });
          });
      });
    },
    [fetchDrawingsForAllPnids, getCurrentDataSetId]
  );

  const fetchDrawingForPnid = useCallback(
    (pnId: string | number, projectId: string, pnIdsDrawings: KeyValuePair) => {
      if (
        !projectId ||
        !pnIdsDrawings ||
        // eslint-disable-next-line no-prototype-builtins
        !pnIdsDrawings.hasOwnProperty(pnId)
      ) {
        return Promise.resolve(
          drawingsResourceService.getEmptyDrawingContent()
        );
      }

      const drawingId = drawingsResourceService.getDrawingId(pnId, projectId);
      return drawingsResourceService
        .fetchDrawing(drawingId)
        .then((dto) => dto.content);
    },
    []
  );

  const saveDrawingForPnid = useCallback(
    (pnidId: number, projectId: string, drawing: string) => {
      drawingsResourceService
        .saveDrawing(
          pnidId,
          projectId,
          drawing,
          getCurrentDataSetId(HIGHLIGHTER_DATASET_ID)
        )
        .then(() => {
          const drawingId = drawingsResourceService.getDrawingId(
            pnidId,
            projectId
          );
          dispatch(
            actions.setPnidDrawing({ pnid: pnidId.toString(), drawingId })
          );
        });
    },
    [dispatch, getCurrentDataSetId]
  );

  const getEmptyDrawingContent = useCallback(() => {
    return drawingsResourceService.getEmptyDrawingContent();
  }, []);

  const preloadDrawingsForProcedure = useCallback(
    (proj: Project) => {
      if (proj.id && proj.pnIdsDrawings && Object.keys(proj.pnIdsDrawings)) {
        const fileIds = Object.values(proj.pnIdsDrawings);

        if (fileIds.length) {
          fetchDrawingsForAllPnids({ fileIds });
        }
      }
    },
    [fetchDrawingsForAllPnids]
  );

  return {
    copyDrawingsToNewProcedure,
    fetchDrawingsForAllPnids,
    fetchDrawingForPnid,
    saveDrawingForPnid,
    getEmptyDrawingContent,
    preloadDrawingsForProcedure,
  };
};
