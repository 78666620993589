/* eslint-disable no-param-reassign */
import { CogniteAnnotation } from '@cognite/annotations-experimental';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'redux/types';
import { workstepsResourceService } from 'resources';
import { workstepsMigrationService, workstepsStateService } from 'services';

import {
  AddWorkStepActionDTO,
  BaseWorkstepsActionDTO,
  BatchUpdateWorkstepsTagNumbersActionDTO,
  ConfirmDraftWorkStepDTO,
  FetchWorkStepsDTO,
  KeyValuePair,
  MoveWorkstepActionDTO,
  Phase,
  PhaseType,
  RemoveWorkStepActionDTO,
  SetWorkstepAsCheckedActionDTO,
  SetWorkstepAsDoneActionDTO,
  SetWorkstepStoppedExecutionActionDTO,
  WorkStep,
} from 'types';

export const fetchWorkSteps = createAsyncThunk(
  'workSteps/fetchWorkSteps',
  async (dto: FetchWorkStepsDTO) => {
    if (!dto.projectId) {
      return [];
    }
    const response = await workstepsResourceService.fetchPhasesAndWorksteps(
      dto.projectId,
      dto.projectStatus
    );
    return response;
  }
);

export const fetchWorkStepsAndRemoveExecution = createAsyncThunk(
  'workSteps/fetchWorkStepsAndRemoveExecution',
  async (dto: FetchWorkStepsDTO) => {
    if (!dto.projectId) {
      return [];
    }
    const response = await workstepsResourceService.fetchAndCopyPhasesAndWorksteps(
      dto.projectId,
      dto.projectStatus
    );

    return response;
  }
);

const workStepsSlice = createSlice({
  name: 'workSteps',
  initialState: workstepsStateService.getInitialState(),
  reducers: {
    moveWorkStep: (state, action: PayloadAction<MoveWorkstepActionDTO>) => {
      return workstepsStateService.moveWorkstep(state, action.payload);
    },
    removeWorkStep: (state, action: PayloadAction<RemoveWorkStepActionDTO>) => {
      return workstepsStateService.removeWorkstep(state, action.payload);
    },
    saveWorkStep: (state, action: PayloadAction<BaseWorkstepsActionDTO>) => {
      workstepsStateService.updateWorkstep(state, action.payload);
    },
    addWorkStep: (state, action: PayloadAction<AddWorkStepActionDTO>) => {
      return workstepsStateService.addWorkstep(state, action.payload);
    },
    setWorkstepAsDone: (
      state,
      action: PayloadAction<SetWorkstepAsDoneActionDTO>
    ) => {
      return workstepsStateService.setWorkstepAsDone(state, action.payload);
    },
    setWorkstepAsChecked: (
      state,
      action: PayloadAction<SetWorkstepAsCheckedActionDTO>
    ) => {
      return workstepsStateService.setWorkstepAsChecked(state, action.payload);
    },
    undoWorkstep: (state, action: PayloadAction<BaseWorkstepsActionDTO>) => {
      return workstepsStateService.undoWorkstepAsDone(state, action.payload);
    },
    undoCheckedWorkStep: (
      state,
      action: PayloadAction<BaseWorkstepsActionDTO>
    ) => {
      return workstepsStateService.undoCheckedWorkstepAsUncheck(
        state,
        action.payload
      );
    },
    batchUpdateWorkstepsTagNumbers: (
      state,
      action: PayloadAction<BatchUpdateWorkstepsTagNumbersActionDTO>
    ) => {
      return workstepsStateService.batchUpdateWorkstepsTagNumbers(
        state,
        action.payload
      );
    },
    setWorkstepStoppedExecution: (
      state,
      action: PayloadAction<SetWorkstepStoppedExecutionActionDTO>
    ) => {
      return workstepsStateService.setWorkstepStoppedExecution(
        state,
        action.payload
      );
    },
    clearWorkSteps: (state) => {
      state.phases = [] as Array<Phase>;
      state.workStepsViewed = false;
      state.hasDeIsolationPhases = false;
      state.workStepsLoaded = false;
    },
    setStatusAsLoaded: (state) => {
      state.status = Status.success;
      state.workStepsLoaded = true;
    },
    editPhaseName: (
      state,
      action: PayloadAction<{
        phaseId: string;
        phaseName: string;
      }>
    ) => {
      return workstepsStateService.updatePhaseName(
        state,
        action.payload.phaseId,
        action.payload.phaseName
      );
    },
    createPhase: (
      state,
      action: PayloadAction<{
        name: string;
        phaseType: PhaseType;
      }>
    ) => {
      return workstepsStateService.createPhase(
        state,
        action.payload.name,
        action.payload.phaseType
      );
    },

    deleteEmptyPhase: (
      state,
      action: PayloadAction<{
        phaseId: string;
        linkedPhaseId?: string;
      }>
    ) => {
      return workstepsStateService.deleteEmptyPhase(
        state,
        action.payload.phaseId,
        action.payload.linkedPhaseId
      );
    },
    approvalPhase: (
      state,
      action: PayloadAction<{
        phaseId: string;
        email: string;
      }>
    ) => {
      return workstepsStateService.approvePhase(
        state,
        action.payload.phaseId,
        action.payload.email
      );
    },

    unApprovalPhase: (
      state,
      action: PayloadAction<{
        phaseId: string;
        email: string;
      }>
    ) => {
      return workstepsStateService.unApprovePhase(
        state,
        action.payload.phaseId,
        action.payload.email
      );
    },
    previewDraftWorkStep: (
      state,
      action: PayloadAction<ConfirmDraftWorkStepDTO>
    ) => {
      return workstepsStateService.previewDraftWorkStep(state, action.payload);
    },
    ignoreDraftWorkStep: (
      state,
      action: PayloadAction<ConfirmDraftWorkStepDTO>
    ) => {
      return workstepsStateService.ignoreDraftWorkStep(state, action.payload);
    },
    confirmDraftWorkStep: (
      state,
      action: PayloadAction<ConfirmDraftWorkStepDTO>
    ) => {
      return workstepsStateService.confirmDraftWorkStep(state, action.payload);
    },
    setDeIsolationMode: (
      state,
      action: PayloadAction<{
        deIsolationMode: boolean;
      }>
    ) => {
      state.deIsolationMode = action.payload.deIsolationMode;
    },
    createDeIsolation: (state) => {
      return workstepsStateService.createDeIsolationWorkSteps(state);
    },
    updateAnnotationInvalid: (
      state,
      action: PayloadAction<{
        newAnnotation: CogniteAnnotation;
        oldAnnotation: CogniteAnnotation;
      }>
    ) => {
      const updatedPhases = state.phases.map((phase) => {
        phase.workSteps = phase.workSteps.map((workStep) => {
          if (workStep.stepItem) {
            if (
              workStep.stepItem.annotation?.id ===
              action.payload.oldAnnotation.id
            ) {
              workStep.error = {
                comment: 'Hotspot has been changed',
                data: action.payload.newAnnotation,
              };
            }
          }
          return workStep;
        });
        return phase;
      });
      state.phases = updatedPhases;
    },
    setWorkstepsToViewed: (state) => {
      state.workStepsViewed = true;
    },
    migrateProcedureWorkSteps: (
      state,
      action: PayloadAction<{
        filesIdsMap: KeyValuePair;
      }>
    ) => {
      const { filesIdsMap } = action.payload;
      state.phases = workstepsMigrationService.migrateWorkSteps(
        state.phases,
        filesIdsMap
      );
    },
    migrateWorkStepsAnnotation: (
      state,
      action: PayloadAction<{
        workStep: WorkStep;
        oldAnnotationId: number;
        newAnnotation: CogniteAnnotation;
      }>
    ) => {
      const { workStep, oldAnnotationId, newAnnotation } = action.payload;
      state.phases = workstepsMigrationService.updateWorkstepsWithAnnotation(
        state.phases,
        workStep,
        oldAnnotationId,
        newAnnotation
      );

      return state;
    },
    setAnnotationCustomOffset: (
      state,
      action: PayloadAction<{
        annotationId: number;
        offsetX: number;
        offsetY: number;
      }>
    ) => {
      state.phases = state.phases.map((phase) => {
        return {
          ...phase,
          workSteps: phase.workSteps.map((workStep) => {
            if (
              workStep.stepItem &&
              workStep.stepItem.annotation &&
              workStep.stepItem.annotation.id === action.payload.annotationId
            ) {
              return {
                ...workStep,
                stepItem: {
                  ...workStep.stepItem,
                  annotation: {
                    ...workStep.stepItem.annotation,
                    customOffset: {
                      x: action.payload.offsetX,
                      y: action.payload.offsetY,
                    },
                  },
                },
              };
            }
            return workStep;
          }),
        };
      });
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkSteps.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(
      fetchWorkSteps.fulfilled,
      (state, action: PayloadAction<Phase[]>) => {
        return workstepsStateService.setWorkSteps(state, action.payload);
      }
    );
    builder.addCase(fetchWorkSteps.rejected, (state, action) => {
      state.status = Status.failed;
      state.workStepsLoaded = false;
      state.hasDeIsolationPhases = false;
      state.error = action.error.message;
    });

    builder.addCase(
      fetchWorkStepsAndRemoveExecution.pending,
      (state, _action) => {
        state.status = Status.loading;
      }
    );
    builder.addCase(
      fetchWorkStepsAndRemoveExecution.fulfilled,
      (state, action: PayloadAction<Phase[]>) => {
        return workstepsStateService.setWorkSteps(state, action.payload);
      }
    );
    builder.addCase(
      fetchWorkStepsAndRemoveExecution.rejected,
      (state, action) => {
        state.status = Status.failed;
        state.workStepsLoaded = false;
        state.error = action.error.message;
      }
    );
  },
});

export type WorkStepState = ReturnType<typeof workStepsSlice.reducer>;
export const { actions } = workStepsSlice;
export default workStepsSlice;
