import { Button, Icon, Loader, toast } from '@cognite/cogs.js';
import { useProcedureFilesLoad } from 'hooks/useProcedureFilesLoad';
import { useProjectLoad } from 'hooks/useProjectLoad';
import { useSave } from 'hooks/useSave';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import { useActiveWorkstepActions } from 'redux/reducers/activeWorkStep/workstepActions';
import { AuthState } from 'redux/reducers/auth';
import { useFiles } from 'redux/reducers/files';
import projectSlice, {
  ProjectState,
  fetchProject,
} from 'redux/reducers/project';
import { useProject } from 'redux/reducers/project/hooks';
import { WorkStepState, useWorkSteps } from 'redux/reducers/worksteps';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import DeleteProcedureModal from 'shared/Modal';
import { KeyValuePair, Project } from 'types';
import ProjectPageComponents from './ProjectPageComponents';
import ApproveRejectProcedure from './components/ApproveRejectProcedure';
import Description from './components/Description';
import Notification from './components/Notification';
import { ProjectLayout } from './components/ProjectLayout/ProjectLayout';
import ResumeExecution from './components/ResumeExecution';
import StartExecution from './components/StartExecution';
import SubmitProcedure from './components/SubmitProcedure';
import UpdatedPnidNotification from './components/UpdatedPnidNotification';
import {
  Contents,
  DescriptionArea,
  Page,
  ProjectReadOnlyNotification,
} from './elements';
import userToDeleteList from '../../utils/userToDeleteList.json';

interface ProjectPageProps extends RouteComponentProps<{ id: string }> {}

const ProjectPage = ({ match }: ProjectPageProps) => {
  const {
    project,
    newProject,
    editMethod,
    workorders,
    woStatus,
    passValves,
    pvStatus,
    reviewComments,
    connectedPnids,
    preWorkItems,
    preWorkItemsStatus,
    attachmentsList,
    approvalFilesList,
    attachmentStatus,
    hasEmptyTagNumbers,
    status,
    isProcedureLoaded,
    approvalFilesStatus,
  } = useSelector<RootState, ProjectState>((state) => state.project);

  let { workStepsViewed } = useSelector<RootState, WorkStepState>(
    (state) => state.workSteps
  );
  const {
    areAllPreWorkItemsChecked,
    areAllPassingValvesChecked,
    viewOnly,
  } = useProject();

  const currentViewOnlyMode = viewOnly();

  // Override workStepViewed, because that is fetched from redux.
  // and used in a lot of exciting checks.
  if (currentViewOnlyMode) {
    workStepsViewed = true;
  }

  const { user } = useSelector<RootState, AuthState>((state) => state.auth);

  const {
    countAllWorkSteps,
    countIsolationWorkSteps,
    countDeIsolationWorkSteps,
    fetchEventsBasedOnWorkSteps,
    countDraftWorkSteps,
    workStepsLoaded,
    phases,
  } = useWorkSteps();

  const { checkPnidsUpdates, getPnidNamesFromIds } = useFiles();

  const { loadProcedure } = useProjectLoad();

  const { cancelActive } = useActiveWorkstepActions();

  const {
    saveHandler,
    submitReviewReadyHandler,
    pushToReviewHandler,
    submitExecutionHandler,
    rejectCompilationHandler,
    executionStartHandler,
    savePreWorkItems,
    completeProjectExecutionForDelete,
  } = useSave();

  const { clearConnectedFiles } = useProcedureFilesLoad();

  const dispatch = useDispatch();
  const history = useHistory();

  const [pushToReviewAllowed, setPushToReviewAllowed] = useState(false);
  const [saveAllowed, setSaveAllowed] = useState(false);
  const [savePreWorkItemsAllowed, setSavePreWorkItemsAllowed] = useState(false);

  const [shouldLayoutHeightUpdate, setShouldLayoutHeightUpdate] = useState(
    false
  );

  const [isInitialized, setIsInitialized] = useState(false);
  const [showDeleteProcedureModal, setShowDeleteProcedureModal] = useState(
    false
  );
  // eslint-disable-next-line
  const [
    pnidsToUpdateMapping,
    setPnidsToUpdateMapping,
  ] = useState<KeyValuePair>({});
  const [pnidsNamesToUpdate, setPnidsNamesToUpdate] = useState<string[]>([]);

  // Init component hook, use it if you want to
  // run something, before anything is shown
  useEffect(() => {
    if (!isInitialized) {
      clearConnectedFiles();
      setIsInitialized(true);
    }
    // eslint-disable-next-line
  }, [isInitialized]);

  useEffect(() => {
    if (!project.id && match?.params.id) {
      dispatch(fetchProject(match?.params.id));
    }
  }, [dispatch, project, match]);

  useEffect(() => {
    // covers more cases
    // 1. Project could be passed from start page, but other data is not loaded
    // 2. Project is fully loaded, on edit page or back again you don't have to fetch again
    if (project.id && status === Status.success && !isProcedureLoaded) {
      loadProcedure(project);
    }
    // eslint-disable-next-line
  }, [dispatch, project.id, status, isProcedureLoaded]);

  const executionWasStopped = !!project.executionStopped?.date;
  const noWorksteps = countAllWorkSteps() === 0;
  const passingValvesDone = areAllPassingValvesChecked();
  const hasDraftSteps = countDraftWorkSteps(true) > 0;
  const cannotSubmitToReview =
    noWorksteps ||
    !passingValvesDone ||
    (executionWasStopped && !workStepsViewed);
  const migrationInProgress = !!project.needsMigration;

  const noCertificateNumber = !project.certificateNumber;
  const preWorkItemsDone = areAllPreWorkItemsChecked();

  const cannotStartExecution =
    noCertificateNumber || !preWorkItemsDone || hasEmptyTagNumbers;

  const shouldNotCountDraftSteps = true;
  const isolationWorkstepsNumber = countIsolationWorkSteps(
    shouldNotCountDraftSteps
  );
  const deIsolationWorkstepsNumber = countDeIsolationWorkSteps(
    shouldNotCountDraftSteps
  );

  const procedureBoxHighlighted =
    ((noWorksteps || hasDraftSteps || migrationInProgress) &&
      project.status === 'compilation') ||
    (!workStepsViewed && project.status === 'review') ||
    (executionWasStopped &&
      !workStepsViewed &&
      project.status === 'compilation');

  const tagsBoxHighlighted =
    hasEmptyTagNumbers && project.status === 'executionReady';

  const executionListHighlighted =
    (project.status === 'executionReady' || project.status === 'execution') &&
    !!project.executorsList &&
    project.executorsList?.length <= 1;

  const SupervisionListHighlighted =
    (project.status === 'executionReady' || project.status === 'execution') &&
    !!project.supervisorsList &&
    project.supervisorsList?.length <= 0;

  const woDates = workorders
    .map((wo) => {
      return wo.createdDateInCdf;
    })
    .filter((woTime) => {
      if (project.lastUpdated) {
        return woTime > project.lastUpdated;
      }
      return false;
    });

  const showNewWOBadge =
    project.status === 'compilation' || project.status === 'review';

  const handleResumeExecution = (proj: Project) => {
    history.push(`/project/edit/${proj.id}`);
  };
  const showErrorMessage = useCallback(() => {
    toast.error(
      <div>
        <h3>Oops</h3> We’re sorry, we weren’t able to do that. Try again.
      </div>
    );
    // eslint-disable-next-line
  }, []);
  const onCompleteProcedure = () => {
    completeProjectExecutionForDelete().then(
      () => {
        toast.success(
          <div data-testid="execute-procedure-completed-success-msg">
            {project.name} deleted.
          </div>
        );
        history.push(`/`);
        dispatch(projectSlice.actions.clearProject());
      },
      () => showErrorMessage()
    );
    // eslint-disable-next-line
  };

  const onDeleteProcedure = () => {
    setShowDeleteProcedureModal(true);
  };

  const renderNotifications = (
    <div>
      {project.rejected?.name && project.status === 'compilation' && (
        <Notification notifType="reviewReturned" name={project.rejected.name} />
      )}
      {!project.rejected?.name &&
        executionWasStopped &&
        !workStepsViewed &&
        project.status === 'compilation' && (
          <Notification notifType="retractedCompilation" />
        )}
      {!!woDates.length && showNewWOBadge && <Notification notifType="newWo" />}
      {project.resubmitted?.name &&
        project.rejected?.date &&
        project.status === 'review' && (
          <Notification
            notifType="reviewResubmitted"
            name={project.resubmitted.name}
          />
        )}
      {!project.rejected?.date &&
        executionWasStopped &&
        project.status === 'review' && (
          <Notification notifType="retractedReview" />
        )}
      {!!pnidsNamesToUpdate.length && project.status === 'compilation' && (
        <UpdatedPnidNotification
          pnidsNamesToUpdate={pnidsNamesToUpdate}
          pnidsToUpdateMapping={pnidsToUpdateMapping}
          project={project}
          viewOnly={currentViewOnlyMode}
        />
      )}
    </div>
  );

  const renderProcedureActions = (
    <>
      {project.status === 'compilation' && (
        <SubmitProcedure
          cannotSubmitToReview={cannotSubmitToReview}
          migrationInProgress={migrationInProgress}
          handleReviewSubmit={submitReviewReadyHandler}
          hasDraftSteps={hasDraftSteps}
          viewOnly={currentViewOnlyMode}
        />
      )}

      {project.status === 'review' && (
        <ApproveRejectProcedure
          submitExecutionHandler={submitExecutionHandler}
          rejectCompilationHandler={rejectCompilationHandler}
          workStepsViewed={workStepsViewed}
          hasDraftSteps={hasDraftSteps}
          viewOnly={currentViewOnlyMode}
        />
      )}

      {project.status === 'executionReady' && (
        <StartExecution
          cannotStartExecution={cannotStartExecution}
          handleExecutionStart={executionStartHandler}
          viewOnly={currentViewOnlyMode}
          executorsList={project.executorsList || []}
          supervisorsList={project.supervisorsList || []}
        />
      )}

      {project.status === 'execution' && (
        <ResumeExecution
          executorsList={project.executorsList || []}
          supervisorsList={project.supervisorsList || []}
          handleResumeExecution={() => handleResumeExecution(project)}
          viewOnly={currentViewOnlyMode}
        />
      )}
    </>
  );

  useEffect(() => {
    if (workStepsLoaded) {
      fetchEventsBasedOnWorkSteps();
      if (project.status === 'compilation' && !project.needsMigration) {
        checkPnidsUpdates().then((response) => {
          setPnidsToUpdateMapping(response);
          const pnidIdsList = Object.keys(response);
          const pnidNames = getPnidNamesFromIds(pnidIdsList);
          setPnidsNamesToUpdate(pnidNames);
        });
      }
    }
    // eslint-disable-next-line
  }, [workStepsLoaded]);

  useEffect(() => {
    if (
      project.id &&
      status === Status.success &&
      project.status === 'reviewReady' &&
      workStepsLoaded
    ) {
      if (!currentViewOnlyMode) {
        if (!pushToReviewAllowed) {
          setPushToReviewAllowed(true);
        } else if (pushToReviewAllowed) {
          pushToReviewHandler();
        }
      }
    }
    // eslint-disable-next-line
  }, [
    project.id,
    status,
    workStepsLoaded,
    pushToReviewAllowed,
    currentViewOnlyMode,
  ]);

  useEffect(() => {
    cancelActive();
    if (
      project.status === 'compilation' ||
      project.status === 'executionReady'
    ) {
      if (!saveAllowed) {
        setSaveAllowed(true);
      } else if (saveAllowed) {
        saveHandler('save project');
      }
    }
    // if any of those are changed, trigger save
    // eslint-disable-next-line
  }, [
    project.name,
    project.method,
    project.objectives,
    project.certificateNumber,
    project.needsMigration,
  ]);

  useEffect(() => {
    if (
      project.status === 'compilation' ||
      project.status === 'executionReady'
    ) {
      if (!savePreWorkItemsAllowed) {
        setSavePreWorkItemsAllowed(true);
      } else if (savePreWorkItemsAllowed) {
        savePreWorkItems();
      }
    }
    // if pre-work items are changed, trigger save
    // eslint-disable-next-line
  }, [preWorkItems]);

  useEffect(() => {
    setShouldLayoutHeightUpdate(true);
  }, [
    project.status,
    project.name,
    project.method,
    project.objectives,
    project.certificateNumber,
    project.executorsList,
    project.supervisorsList,
    preWorkItems,
    isProcedureLoaded,
    workorders,
    passValves,
    connectedPnids,
    reviewComments,
    attachmentsList,
  ]);

  if (status === Status.failed) {
    toast.error(
      <div>
        <h3>We’re sorry</h3> We couldn’t find that procedure. Try finding it by
        searching for the asset instead.
      </div>
    );

    return <Redirect to="/" />;
  }

  if (project.status === 'deleted') {
    toast.error(
      <div>
        <h3>We’re sorry</h3> The procedure you are attempting to load has been
        deleted and is no longer available.
      </div>
    );

    return <Redirect to="/" />;
  }

  // issue: if a procedure was loaded, without the work steps loaded and a save action was triggered => all current work steps where removed.
  if (!workStepsLoaded) {
    return <Loader />;
  }

  const isDeleteUser = userToDeleteList.some((deleteUser) => {
    return deleteUser.email.toLowerCase() === user.toLowerCase();
  });

  return (
    <Page>
      <Contents>
        {currentViewOnlyMode && (
          <ProjectReadOnlyNotification data-testid="project-view-only-msg">
            <Icon
              type="EyeShow"
              size={14}
              style={{
                marginRight: '5px',
              }}
            />
            <div>You are in view only mode</div>
          </ProjectReadOnlyNotification>
        )}
        {!currentViewOnlyMode &&
          project.status !== 'execution' &&
          isDeleteUser && (
            <>
              <Button
                disabled={false}
                type="danger"
                style={{ marginBottom: '8px' }}
                data-testid="delete-procedure-button"
                onClick={onDeleteProcedure}
              >
                <Icon type="Delete" style={{ marginRight: '10px' }} />
                Delete procedure
              </Button>

              <DeleteProcedureModal
                visible={showDeleteProcedureModal}
                setVisible={setShowDeleteProcedureModal}
                // eslint-disable-next-line no-console
                okHandler={onCompleteProcedure}
                okText="Yes, delete procedure"
                okButtonType="danger"
                cancelText="No, keep going"
                title="Are you sure you want to delete procedure?"
              >
                <p data-testid="delete-procedure-msg">
                  If you delete this procedure, it will no longer appear in the
                  system.
                </p>

                <p>Delete By : {user}</p>
              </DeleteProcedureModal>
            </>
          )}

        <DescriptionArea>
          <Description
            description={project.name}
            newProject={newProject}
            editable={project.status === 'compilation' && !currentViewOnlyMode}
          />
          {renderProcedureActions}
        </DescriptionArea>

        {renderNotifications}

        <ProjectLayout
          shouldUpdate={shouldLayoutHeightUpdate}
          setShouldUpdate={setShouldLayoutHeightUpdate}
        >
          <ProjectPageComponents
            project={project}
            editMethod={editMethod}
            viewOnly={currentViewOnlyMode}
            isolationWorkstepsNumber={isolationWorkstepsNumber}
            deIsolationWorkstepsNumber={deIsolationWorkstepsNumber}
            workorders={workorders}
            workOrdersStatus={woStatus}
            passValves={passValves}
            passValvesStatus={pvStatus}
            user={user}
            comments={reviewComments}
            pnids={connectedPnids}
            procedureBoxHighlighted={procedureBoxHighlighted}
            executionListHighlighted={executionListHighlighted}
            SupervisionListHighlighted={SupervisionListHighlighted}
            tagsBoxHighlighted={tagsBoxHighlighted}
            preWorkItems={preWorkItems}
            preWorkItemsDone={preWorkItemsDone}
            preWorkItemsStatus={preWorkItemsStatus}
            passingValvesDone={passingValvesDone}
            attachmentsList={attachmentsList}
            approvalFilesList={approvalFilesList}
            attachmentStatus={attachmentStatus}
            approvalFilesStatus={approvalFilesStatus}
            pnidsNamesToUpdate={pnidsNamesToUpdate}
            phases={phases}
          />
        </ProjectLayout>
      </Contents>
    </Page>
  );
};

export default ProjectPage;
